import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { LoginResponseObj } from '../shared/models/login';
import { UnreadOrderStatusObj } from '../shared/models/orderstatus';
import { DataService } from '../shared/services/data.service';
import { HTTPStatus } from '../shared/services/httplistener.service';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.css']
})
export class SecureComponent implements OnInit {
  
  public HTTPActivity: boolean;

  public showNotifications: boolean = false;
  public lstStatus:UnreadOrderStatusObj[] =[];

  public showHelp: boolean = false;

  constructor(private router: Router, private httpStatus: HTTPStatus, private dataService: DataService, private modelService: ModelService, private spinner: NgxSpinnerService) { 

    this.router.events.pipe(filter(x=>x instanceof NavigationEnd)).subscribe((res:any)=>{
      this.loadUnreadStatuses();
    });
    
    this.httpStatus.getHttpStatus().subscribe(status => { 
      if(status)
      {
        this.spinner.show();
      }
      else
      {
        this.spinner.hide();
      }
     });
  }

  ngOnInit(): void {
  }

  isSlidingPanelOpened(){
    return this.showNotifications || this.showHelp;
  }

  closeSlider(data: any, sliderType:string)
  {
    switch(sliderType)
    {
      case 'NOTIFICATIONS':
        this.showNotifications = false;
        break;
        case 'HELP':
          this.showHelp = false;
          break;
    }
  }

  showHelpPanel()
  {
    this.showHelp = true;
  }
  showNotificationPanel()
  {
    this.showNotifications = true;
  }

  public loadUnreadStatuses()
  {
    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();
    this.dataService.getUnreadOrderStatuses(loginDetail.userType, loginDetail.userId).subscribe(res=>{
      console.log('unread status added');
      this.lstStatus = res;
    });
  }
  public getUnReadStatusCount():number
  {
    let count :number = 0;
    if(this.lstStatus && this.lstStatus.length > 0)
    {
      count = this.lstStatus.filter(x=>!x.isRead).length;
    }
    return count;
  }

}
