<div class="loginpage">
    <a routerLink="/login" class="fixfirstlogo"></a>
    <h1>Hello!</h1>
    <h2>Welcome to FixFirst</h2>
    <form [formGroup]="form" class="form-horizontal my-4" appIdentityRevealed>
        <div class="form-item">
          <label class="ctrcaption" for="username">Email</label>
          <div class="">
            <input class="ctrlitem" type="text" placeholder="Email" formControlName="emailAddress" />
          </div>
        </div>

        <div class="form-item">
          <label class="ctrcaption" for="userpassword">Password</label>
          <div class="">
            <input class="ctrlitem" type="password" placeholder="Password" formControlName="password" />
          </div>
        </div>
        <div class="form-group">

        </div>
        <div class="loginbtn">
            <button type="submit" class="btnprimary" [class.btndim]="!form.valid" [disabled]="!form.valid" (click)="login()">Login</button>
        </div>
        <div class="forgotlinkdiv">
          <a href="#" class="linkprimary">Forgot password?</a>
        </div>
        <div class="newlabeldiv">
          <h4>New to FixFirst?</h4>
        </div>
        <div>
          <!-- <button class="btnoutline">Sign up</button> -->
        </div>
    </form>
</div>