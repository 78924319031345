<div class="filterbox">
    <div class="filterboxitem">
        <button class="icobtn"><span class="icofilter"></span></button>
    </div>
    <div class="filterboxitem selectedfilter">
        <button class="roundbutton">All</button>
    </div>
    <div class="filterboxitem">
        <button class="roundbutton">New</button>
    </div>
    <div class="filterboxitem">
        <button class="roundbutton">Accepted</button>
    </div>
    <div class="filterboxitem">
        <button class="roundbutton">Reschedule</button>
    </div>
    <div class="filterboxitem">
        <button class="roundbutton">Rejected</button>
    </div>
    <div class="filterboxitem">
        <button class="icobtn"><span class="fa fa-list-ul"></span></button>
    </div>
    <div class="filterboxitem">
        <button class="icobtn"><span class="fa fa-map"></span></button>
    </div>
</div>
<table class="table listingtable" width="100%">
    <thead>
        <tr>
            <th>Request</th>
            <th>Request date</th>
            <th>Details</th>
            <th>Date</th>
            <th>FixFirst-ID</th>
            <th>Status</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of lstOrders">
            <td>
                <div class="icotoolbox">
                    <div class="icotool"></div>
                    <div class="tooltxt">
                        <h3>On-Site</h3>
                        <p class="pmain">FixFirst</p>
                    </div>
                </div>
            </td>
            <td>Mon - Nov 2, 2020 @ 10:00 am</td>
            <td>{{item.ApplianceType}}</td>
            <td>Mon - Nov 2, 2020 @ 10:00 am</td>
            <td>FF-BE1-2020-A01-X56</td>
            <td>
                <span class="bdgbox">New</span>
            </td>
            <td>
                <button class="btnround" (click)="updateOrder();">Update</button>
            </td>
        </tr>
    </tbody>
</table>