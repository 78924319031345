import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../shared/services/data.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { OrderListingObj } from '../shared/models/order';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {

  public lstOrders: OrderListingObj[] = [];
  constructor(private ngbModal: NgbModal, private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
    // this.dataService.getOrderListing().subscribe(res=>{
    //   this.lstOrders = res;
    //   console.log(res);
    // });
  }

  updateOrder(){
  }

}
