import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusConstants } from '../ccc/constants';

@Pipe({
    name: 'orderstatus'
})
export class OrderStatusPipe implements PipeTransform {

    constructor() { }

    transform(value: any) {
        let statusTxt='';
        switch(value)
        {
            case OrderStatusConstants.New:
                statusTxt='New';
                break;
            case OrderStatusConstants.Schedule:
                statusTxt='Scheduled';
                break;
            case OrderStatusConstants.Reschedule:
                statusTxt='Rescheduled';
                break;
            case OrderStatusConstants.InProgress:
                statusTxt='In Progress';
                break;
            case OrderStatusConstants.Cancel:
                statusTxt='Canceled';
                break;
            case OrderStatusConstants.Complete:
                statusTxt='Completed';
                break;
        }
        return statusTxt;
    }
}
