import { DayObj } from "./calendarobj";

export class SPListingArg
{
    public searchTerm: string;
}

export class SPListingObj{
    public serviceProviderId: number;
    public firstName: string;
    public lastName: string;
    public company: string;
    public city: string;
    public country: string;
    public website: string;
    public role: string;
}

export class ServiceProviderObj{
    public serviceProviderId: number;
    
    public userType: string;
    public role: string;

    public firstName: string;
    public lastName: string;
    public email: string;
    public password: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
    public organization: string;
    public websiteAddress: string;
    public brand: string;

    public companyAddressHouseNo: string;
    public companyAddressStreet: string;
    public companyAddressAdditionInfo: string;
    public companyAddressNotes: string;
    public companyAddressCountry: string;
    public companyAddressPostalCode: string;
    public companyAddressCity: string;

    public companyProfile: string;

    public notes: string;

    public customerCareId: number;
    public ccName: string;
}

export class SPBasicDetailObj{
    public serviceProviderId: number;
    public customerCareId: number;

    public userType: string;
    
    public role: string;

    public firstName: string;
    public lastName: string;
    public email: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
    public organization: string;
    public websiteAddress: string;
    public brand: string;

    public companyAddressHouseNo: string;
    public companyAddressStreet: string;
    public companyAddressAdditionInfo: string;
    public companyAddressNotes: string;
    public companyAddressCountry: string;
    public companyAddressPostalCode: string;
    public companyAddressCity: string;
}
export class SPCompanyProfileObj{
    public serviceProviderId: number;

    public companyProfile: string;
}
export class SPNotesInfoObj{
    public serviceProviderId: number;
    
    public notes: string;
}
export class SPSetPasswordObj{
    public serviceProviderId: number;
    public password: string;
}
export class SPAvalabilityResponseObj{
    public spId: number;
    public firstName: string;
    public lastName: string;
    public organization: string;
    public isAvailable: boolean;
}
export class SPCalendarCellVM{
    public dayObj: DayObj;
    public lstSPs: SPAvalabilityResponseObj[];
}

export class SPProfileObj{
    public serviceProviderId: number;
    
    public role: string;

    public firstName: string;
    public lastName: string;
    public email: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
}
export class SPSettingsObj{
    public serviceProviderId: number;

    public organization: string;
    public websiteAddress: string;
    public brand: string;

    public companyAddressHouseNo: string;
    public companyAddressStreet: string;
    public companyAddressAdditionInfo: string;
    public companyAddressNotes: string;
    public companyAddressCountry: string;
    public companyAddressPostalCode: string;
    public companyAddressCity: string;
}
export class SPDDLObj
{
    public serviceProviderId: number;
    public firstName: string;
    public lastName: string;
    public organization: string;
}