import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { filter, map } from 'rxjs/operators';
import { ModalConfirmationStatus } from '../shared/ccc/constants';
import { ContactNumberModalComponent } from '../shared/components/contact-number-modal/contact-number-modal.component';
import { DeleteConfirmationModalComponent } from '../shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { LoginResponseObj } from '../shared/models/login';
import { TechnicianListingArg, TechnicianListingObj } from '../shared/models/technician';
import { DataService } from '../shared/services/data.service';
import { ModelService } from '../shared/services/model-service';
import { ViewTechnicianPopupComponent } from './view-technician-popup/view-technician-popup.component';

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.css']
})
export class TechniciansComponent implements OnInit {
  
  public lstTechnicians: TechnicianListingObj[] = [];
  public currentView: string = 'LIST';

  constructor(private ngbModal: NgbModal, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager, private router: Router) { 
    this.modelService.searchTerm$.asObservable().pipe(filter(x=> x && (x.page == "technicians")), map(x=>x.searchTerm)).subscribe(searchTxt=>{
      this.loadRecords(searchTxt);
    });
  }

  ngOnInit() {
    this.loadRecords();
  }

  rowChkClicked(e:any)
  {
    e.stopPropagation();
  }

  showDetailsPopup(technicianId: number)
  {
    const modalRef = this.ngbModal.open(ViewTechnicianPopupComponent,{ backdrop: 'static', centered: true, size:'lg' });
    modalRef.componentInstance.technicianId = technicianId;
    modalRef.componentInstance.loadRecords();
  }

  showContactNumber(e:any){
    
    e.stopPropagation();

    const modalRef = this.ngbModal.open(ContactNumberModalComponent,{ backdrop: 'static', centered: true });

    // modalRef.componentInstance.heading = 'Are you sure you want to delete the selected customers?';
    // modalRef.componentInstance.btnConfirmText = 'Delete customers';
  }


  
  deleteTechnicians(){
    const modalRef = this.ngbModal.open(DeleteConfirmationModalComponent,{ backdrop: 'static', centered: true });

     modalRef.componentInstance.heading = 'Are you sure you want to delete the selected technicians?';
     modalRef.componentInstance.btnConfirmText = 'Delete technicians';
     
    
    modalRef.result.then((data: ModalConfirmationStatus) => {
      if (data == ModalConfirmationStatus.CONFIRMED) {
        this.toastr.successToastr('Selected technicians deleted successfully');
      }
    }, (reason) => {
    });
  }

  showList()
  {
    this.currentView = 'LIST';
  }
  showCalendar()
  {
    this.currentView = 'CALENDAR';
  }
  
  newTechnician()
  {
    this.router.navigate(['/home/technicians/new']);
  }

  private loadRecords(searchTerm: string = ""){
    let loginResponse:LoginResponseObj = this.modelService.getLoginDetail();

    let arg: TechnicianListingArg=new TechnicianListingArg();

    arg.spId = loginResponse.userId;
    arg.searchTerm = searchTerm;

    this.dataService.getTechnicianListing(arg).subscribe(res=>{
      this.lstTechnicians = res;
    });
  }
}
