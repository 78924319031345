
<div class="listingactionbox">
    <div class="filterbox">
        <!-- <div class="filterboxitem">
            <button class="icobtn"><span class="icofilter"></span></button>
        </div> -->
        <div class="filterboxitem" [class.selectedfilter]="ordersStatus == ''">
            <button class="roundbutton" (click)="filterStatus('')">All</button>
        </div>
        <div class="filterboxitem" [class.selectedfilter]="ordersStatus == 'new'">
            <button class="roundbutton" (click)="filterStatus('new')">New</button>
        </div>
        <div class="filterboxitem" [class.selectedfilter]="ordersStatus == 'schedule'">
            <button class="roundbutton" (click)="filterStatus('schedule')">Scheduled</button>
        </div>
        <div class="filterboxitem" [class.selectedfilter]="ordersStatus == 'reschedule'">
            <button class="roundbutton" (click)="filterStatus('reschedule')">Rescheduled</button>
        </div>
        <div class="filterboxitem" [class.selectedfilter]="ordersStatus == 'inprogress'">
            <button class="roundbutton" (click)="filterStatus('inprogress')">In Progress</button>
        </div>
        <div class="filterboxitem" [class.selectedfilter]="ordersStatus == 'complete'">
            <button class="roundbutton" (click)="filterStatus('complete')">Completed</button>
        </div>
        <div class="filterboxitem" [class.selectedfilter]="ordersStatus == 'cancel'">
            <button class="roundbutton" (click)="filterStatus('cancel')">Canceled</button>
        </div>
        <div class="filterboxitem">
            <button class="icobtn"><span class="fa fa-list-ul"></span></button>
        </div>
        <div class="filterboxitem">
            <button class="icobtn"><span class="fa fa-map"></span></button>
        </div>
    </div>
    <div class="listingactionbtn">
        <button class="actiobtn" (click)="newOrder()"><span class="fa fa-plus"></span> New Order</button>
    </div>
</div>
<table class="table listingtable" width="100%">
    <thead>
        <tr>
            <th>Request</th>
            <th>Request date</th>
            <th>Details</th>
            <th>Date</th>
            <th>FixFirst-ID</th>
            <th>Status</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of lstOrders" (click)="showDetailsPopup(item.orderId)">
            <td>
                <div class="icotoolbox">
                    <div class="icotool"></div>
                    <div class="tooltxt">
                        <h3>{{item.visitType}}</h3>
                        <p class="pmain">FixFirst</p>
                    </div>
                </div>
            </td>
            <td>
                {{item.createdDate | customdate:'ddd - DD.MM.YYYY'}}
                <br />
                {{item.createdDate | customdate:'hh:mm a'}}
            </td>
            <td>
                {{item.applianceType}}
                <br />
                {{item.customerFirstName}} {{item.customerLastName}}
                <br />
                {{item.visitAddressPostalCode}} {{item.visitAddressCity}}
            </td>
            <td>
                <div *ngIf="item.scheduleDate">
                    {{item.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}}
                    <br />
                    {{item.scheduleTime}}
                </div>
            </td>
            <td>{{item.fixFirstId}}</td>
            <td>
                <span class="bdgbox" [statusbg]="item.orderStatus">{{item.orderStatus | orderstatus}}</span>
            </td>
            <td>
                <button class="btnround" (click)="updateStatus($event, item.orderId)">Update</button>
            </td>
        </tr>
    </tbody>
</table>