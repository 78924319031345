import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { forkJoin } from 'rxjs';
import { CollectionUtil } from '../shared/ccc/collections';
import { OrderStatusConstants } from '../shared/ccc/constants';
import { Util } from '../shared/ccc/util';
import { DropDownObj, DropDownObj2 } from '../shared/models/commonobj';
import { DashboardDateRangeArg, DashboardFormulaCountArg, DashboardFormulaCountObj, DashboardTotalCountArg, GraphObj } from '../shared/models/dashboard';
import { LoginResponseObj } from '../shared/models/login';
import { DataService } from '../shared/services/data.service';
import { ModelService } from '../shared/services/model-service';
import * as moment from 'moment';
import * as FusionCharts from 'fusioncharts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public mode: string = 'month';
  public ccId: number = null;
  public spId: number = null;
  public statusType: string = OrderStatusConstants.New;
  public selectedspName: string = null;
  public selectedspId: number = null;

  readonly lstStatusTypes: DropDownObj[] = CollectionUtil.lstStatusTypes;
  public lstSP: DropDownObj2[];

  public dateRangeDS: any = null;
  public dateRangeChartType: string = 'line';

  public totalCountDS: Object = null;

  public formulaCountObj: DashboardFormulaCountObj = null;

  public formDateRange: FormGroup;

  constructor(private modelService: ModelService, private dataService: DataService, private fb: FormBuilder, private activatedRoute: ActivatedRoute, private toastr: ToastrManager) { 
    let loginObj: LoginResponseObj = this.modelService.getLoginDetail();
    if(loginObj.userType == 'CC')
    {
      this.ccId = loginObj.userId;
      this.activatedRoute.data.subscribe(res=>{
        this.lstSP = res.spddl;
      });
    }
    else
    {
      this.spId = loginObj.userId;
    }
    this.formDateRange=fb.group({
      dateFrom:['', Validators.required],
      dateTo:['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  public selectMode(mode: string):void{
    this.mode = mode;

    if(this.mode != 'range')
    {
      this.loadData();
    }
  }
  public setStatusType(status: string):void{
    this.statusType = status;
    this.loadData();
  }
  public getSelectedSP(): string{
    if(this.selectedspId && this.selectedspName)
    {
      return this.selectedspName;
    }
    else
    {
      return "All service providers";
    }
  }
  public setServiceProvider(item: DropDownObj2):void{
    if(item)
    {
      this.selectedspId = item.id;
      this.selectedspName = item.title;
    }
    else
    {
      this.selectedspId = this.selectedspName = null;
    }
    if(this.mode == "range")
    {
      this.filterDateRange();
    }
    else
    {
      this.loadData();
    }
  }

  filterDateRange():void
  {
    if(!this.formDateRange.valid)
    {
      this.toastr.errorToastr('Please select from and to date for range filter','Error!');
    }
    else
    {
      let dateFrom: string = Util.NgbDateToString(Util.ParseCustomDate(this.formDateRange.controls['dateFrom'].value));
      let dateTo: string = Util.NgbDateToString(Util.ParseCustomDate(this.formDateRange.controls['dateTo'].value));
      

      this.loadData(dateFrom, dateTo);
    }
  }

  private loadData(dateFrom: string = "", dateTo: string = ""):void{

    this.dateRangeDS = null;

    let dateRangeArg:DashboardDateRangeArg=new DashboardDateRangeArg();
    dateRangeArg.ccId = this.ccId;
    dateRangeArg.spId = this.spId;
    dateRangeArg.selectedspId = this.selectedspId;
    dateRangeArg.mode = this.mode;
    dateRangeArg.orderStatus = this.statusType;
    if(this.mode == "range" && dateFrom && dateTo)
    {
      dateRangeArg.dateFrom = dateFrom;
      dateRangeArg.dateTo = dateTo;
    }

    let totalCountArg:DashboardTotalCountArg=new DashboardTotalCountArg();
    totalCountArg.ccId = this.ccId;
    totalCountArg.spId = this.spId;
    totalCountArg.selectedspId = this.selectedspId;
    totalCountArg.mode = this.mode;
    if(this.mode == "range" && dateFrom && dateTo)
    {
      totalCountArg.dateFrom = dateFrom;
      totalCountArg.dateTo = dateTo;
    }


    let formulaCountArg:DashboardFormulaCountArg=new DashboardFormulaCountArg();
    formulaCountArg.ccId = this.ccId;
    formulaCountArg.spId = this.spId;
    formulaCountArg.selectedspId = this.selectedspId;

    
    let req1$ = this.dataService.dasboardDateRangeGraph(dateRangeArg);
    let req2$ = this.dataService.dashboardTotalCountGraph(totalCountArg);
    let req3$ = this.dataService.dashboardFormulaCount(formulaCountArg);

    forkJoin([req1$, req2$, req3$]).subscribe(([res1, res2, res3])=>{

      if(res1 && res1.length == 1)
      {
        this.dateRangeChartType = 'Column2d';
      }
      else
      {
        this.dateRangeChartType = 'timeseries';
      }

      if(res1)
      {
        let fusionTable = new FusionCharts.DataStore().createDataTable(
          res1.map(x=>{
            x.createdDate = moment(x.createdDate).format("MM/DD/YYYY");
            return x;
          }),
          [
            {
            "name": "createdDate",
            "type": "date",
            "format": "%m/%d/%Y"
          }, 
          {
            "name": "totalOrders",
            "type": "number"
          }
        ]
        );

        console.log(fusionTable);

        this.dateRangeDS = {
          data: fusionTable,
          yAxis: {
            plot: [{ value: 'totalOrders', connectnulldata: true }]
          },
          caption: {
            text: 'Orders average'
          }
        };
      }
      else
      {
        this.dateRangeDS = null;
      }


      let countGraphArr: GraphObj[] = [];
     let totalCount = res2.scheduledCount + res2.reScheduledCount + res2.newCount + res2.inProgressCount + res2.completedCount + res2.cancelledCount;
      if(totalCount > 0 )
      {
        countGraphArr.push(new GraphObj('Scheduled', Math.round((res2.scheduledCount/totalCount) * 100 * 100) / 100,'#2A4054'));
        countGraphArr.push(new GraphObj('Completed', Math.round((res2.completedCount/totalCount) * 100 * 100) / 100,'#118258;'));
        countGraphArr.push(new GraphObj('Canceled', Math.round((res2.cancelledCount/totalCount) * 100 * 100) / 100,'#C21600'));
        countGraphArr.push(new GraphObj('New', Math.round((res2.newCount/totalCount) * 100 * 100) / 100,'#0077B8;'));
        countGraphArr.push(new GraphObj('In Progress', Math.round((res2.inProgressCount/totalCount) * 100 * 100) / 100,'#BB4F00'));
        countGraphArr.push(new GraphObj('Rescheduled', Math.round((res2.reScheduledCount/totalCount) * 100 * 100) / 100,'#936A00'));
      }


      this.totalCountDS = {
        "chart": {
          "yAxisName": `Total Requests (%)`,
          "yAxisMaxValue":"100",
          "yAxisMinValue":"0",
          "numberSuffix": "",
          "theme": "fusion",
          "bgColor": "#FFFFFF",
          "bgAlpha": "50",
          "canvasbgColor": "#FFFFFF",
          "canvasbgAlpha": "50",
          "numDivLines":"6",
          "borderColor": "#666666",
          "borderThickness": "4",
          "numVisiblePlot": "3",
          "scrollheight": "10",
          "flatScrollBars": "1",
          "scrollShowButtons": "0",
          "scrollColor": "#cccccc",
          "showHoverEffect": "1"
        },
        // Chart Data
        "data": countGraphArr
      };

      this.formulaCountObj = res3;

    });
  }

  public repairPercent():string
  {
    let repairPercent = "-";
    
    let totalRepairs = this.formulaCountObj.totalCompletedOrders + this.formulaCountObj.totalRescheduledOrders;

    if(totalRepairs > 0)
    {
      repairPercent = `${((this.formulaCountObj.totalCompletedOrders / totalRepairs) * 100).toFixed(0)}%`;
    }
    return repairPercent;
  }

}
