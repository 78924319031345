import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { SPBasicDetailObj, SPCompanyProfileObj, SPNotesInfoObj, SPSetPasswordObj } from 'src/app/shared/models/serviceprovider';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';
import { SetSPPasswordPopupComponent } from '../set-sppassword-popup/set-sppassword-popup.component';

@Component({
  selector: 'app-manage-service-providers',
  templateUrl: './manage-service-providers.component.html',
  styleUrls: ['./manage-service-providers.component.css']
})
export class ManageServiceProvidersComponent implements OnInit {
  
  public serviceProviderId: number = 0;
  public isCreateNew: boolean = false;
  public pageTitle: string = "New service provider";

  public spDetailForm:FormGroup = null;
  public companyProfileForm:FormGroup = null;
  public notesForm:FormGroup = null;

  public currentStep:string = 'personal_details';

  constructor(public activatedRoute: ActivatedRoute, private ngbModal: NgbModal, private router: Router, private dataService: DataService, private modelService: ModelService, public fb:FormBuilder, private toastr: ToastrManager) { 
    this.serviceProviderId = this.activatedRoute.snapshot.params.id;

    this.spDetailForm = this.fb.group({
      organization: ['', [Validators.required, Validators.maxLength(100)]],
      websiteAddress: ['', [Validators.maxLength(100)]],
      brand: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.maxLength(150)]],
      mobileNumberExtension:[, [Validators.required]],
      mobileNumber:[, [Validators.required]],
      whatsAppContactAllowed:[],
      role:[,[Validators.required]],

      companyAddressHouseNo:[,[Validators.required, Validators.maxLength(100)]],
      companyAddressStreet:[,[Validators.required, Validators.maxLength(100)]],
      companyAddressAdditionInfo:[],
      companyAddressNotes:[],
      companyAddressCity:[,[Validators.required, Validators.maxLength(100)]],
      companyAddressPostalCode:[,[Validators.required, Validators.maxLength(100)]],
      companyAddressCountry:[,[Validators.required, Validators.maxLength(100)]]
    });

    this.companyProfileForm = this.fb.group({
      companyProfile: ['']
    });

    this.notesForm = this.fb.group({
      notes: ['']
    });
  }

  ngOnInit() {
    if(this.serviceProviderId)
    {
      this.pageTitle="Update service provider";
      this.dataService.getSPById(this.serviceProviderId).subscribe(res=>{
        this.spDetailForm.controls["organization"].setValue(res.organization);
        this.spDetailForm.controls["websiteAddress"].setValue(res.websiteAddress);
        this.spDetailForm.controls["brand"].setValue(res.brand);
        this.spDetailForm.controls["firstName"].setValue(res.firstName);
        this.spDetailForm.controls["lastName"].setValue(res.lastName);
        this.spDetailForm.controls["email"].setValue(res.email);
        this.spDetailForm.controls["mobileNumberExtension"].setValue(res.mobileNumberExtension);
        this.spDetailForm.controls["mobileNumber"].setValue(res.mobileNumber);
        this.spDetailForm.controls["whatsAppContactAllowed"].setValue(res.whatsAppContactAllowed);
        this.spDetailForm.controls["role"].setValue(res.role);

        this.spDetailForm.controls["companyAddressHouseNo"].setValue(res.companyAddressHouseNo);
        this.spDetailForm.controls["companyAddressStreet"].setValue(res.companyAddressStreet);
        this.spDetailForm.controls["companyAddressAdditionInfo"].setValue(res.companyAddressAdditionInfo);
        this.spDetailForm.controls["companyAddressNotes"].setValue(res.companyAddressNotes);
        this.spDetailForm.controls["companyAddressCity"].setValue(res.companyAddressCity);
        this.spDetailForm.controls["companyAddressPostalCode"].setValue(res.companyAddressPostalCode);
        this.spDetailForm.controls["companyAddressCountry"].setValue(res.companyAddressCountry);


        this.companyProfileForm.controls["companyProfile"].setValue(res.companyProfile);


        this.notesForm.controls["notes"].setValue(res.notes);
      });
    }
    else
    {
      this.serviceProviderId = 0;
      this.isCreateNew = true;
    }
  }

  changeStep(newStep: string)
  {
    this.currentStep = newStep;
  }

  backStep()
  {
    switch(this.currentStep)
    {
      case 'company_profile':
        this.currentStep = 'personal_details';
        break;
      case 'notes':
        this.currentStep = 'company_profile';
        break;
      case 'summary':
        this.currentStep = 'notes';
        break;
    }
  }
  nextStep()
  {
    switch(this.currentStep)
    {
      case 'personal_details':
        let loginResponseObj: LoginResponseObj= this.modelService.getLoginDetail();
        let spBasicDetailObj: SPBasicDetailObj = Object.assign({}, this.spDetailForm.value);
        spBasicDetailObj.serviceProviderId = this.serviceProviderId;
        spBasicDetailObj.customerCareId = loginResponseObj.userId;  //customer care id shall be stored with newly created service_provider.

        console.log(spBasicDetailObj);

        this.dataService.updateSPBasicDetails(spBasicDetailObj).subscribe(spId=>{
          if(!this.serviceProviderId)
          {
            this.serviceProviderId = spId;
          }
          this.toastr.successToastr('Basic details saved successfully');
          this.currentStep = 'company_profile';
        });
        break;
      case 'company_profile':
        let spCompanyProfile: SPCompanyProfileObj = Object.assign({}, this.companyProfileForm.value);
        spCompanyProfile.serviceProviderId = this.serviceProviderId;
        this.dataService.updateSPCompanyProfile(spCompanyProfile).subscribe(res=>{
          this.toastr.successToastr('Company profile saved successfully');
          this.currentStep = 'notes';
        });
        break;
      case 'notes':
        let spNotesInfoObj: SPNotesInfoObj = Object.assign({}, this.notesForm.value);
        spNotesInfoObj.serviceProviderId = this.serviceProviderId;
        this.dataService.updateSPNotesInfo(spNotesInfoObj).subscribe(res=>{
          this.toastr.successToastr('Notes saved successfully');
          this.currentStep = 'summary';
        });
        break;
    }
  }

  selStep(selectedStep: string)
  {
    this.currentStep = selectedStep;
  }

  isFormActive():boolean
  {
    let isActive: boolean = true;
    switch(this.currentStep)
    {
      case "personal_details":
        isActive = this.spDetailForm.valid;
        break;
      case "company_profile":
        isActive = this.companyProfileForm.valid;
        break;
      case "notes":
        isActive = this.notesForm.valid;
        break;
    }
    return isActive;
  }


  setPassword(){
    const modalRef = this.ngbModal.open(SetSPPasswordPopupComponent,{ backdrop: 'static', centered: true, size: 'sm' });

    modalRef.result.then((data: any) => {
      if (data.status == ModalConfirmationStatus.CONFIRMED) {
        let passwordChangeObj: SPSetPasswordObj=new SPSetPasswordObj();
        passwordChangeObj.serviceProviderId = this.serviceProviderId;
        passwordChangeObj.password = data.password;

        this.dataService.updateSPPassword(passwordChangeObj).subscribe(res=>{
          this.toastr.successToastr('Service provider successfully created', 'Service provider created');
          this.finishWizard();
        });
      }
    }, (reason) => {
    });

  }
  finishWizard(){
    this.router.navigate(['home/service-providers']);
  }
}
