import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FusionChartsModule } from "angular-fusioncharts";

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries'; // Import timeseries



import { AppComponent } from './app.component';
import { SignupLayoutComponent } from './signup/signup-layout.component';
import { LoginComponent } from './signup/login/login.component';
import { SignupStep1Component } from './signup/signup-step1/signup-step1.component';
import { SignupStep2Component } from './signup/signup-step2/signup-step2.component';
import { SignupStep3Component } from './signup/signup-step3/signup-step3.component';
import { SecureComponent } from './secure/secure.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftMenuComponent } from './secure/left-menu.component';
import { TopMenuComponent } from './secure/top-menu.component';
import { RequestsComponent } from './requests/requests.component';
import { OrdersComponent } from './orders/orders.component';
import { TechniciansComponent } from './technicians/technicians.component';
import { PartnersComponent } from './partners/partners.component';
import { CustomersComponent } from './customers/customers.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTPListener, HTTPStatus } from './shared/services/httplistener.service';
import { ManageOrderComponent } from './orders/manage-order/manage-order.component';
import { VisitTypeStepComponent } from './orders/manage-order/visit-type-step/visit-type-step.component';
import { CustomerDetailsStepComponent } from './orders/manage-order/customer-details-step/customer-details-step.component';
import { BookingDetailsStepComponent } from './orders/manage-order/booking-details-step/booking-details-step.component';
import { AttachmentsStepComponent } from './orders/manage-order/attachments-step/attachments-step.component';
import { ApplianceDataStepComponent } from './orders/manage-order/appliance-data-step/appliance-data-step.component';
import { RequestSummaryStepComponent } from './orders/manage-order/request-summary-step/request-summary-step.component';
import { ServiceProvidersComponent } from './service-providers/service-providers.component';
import { ManageServiceProvidersComponent } from './service-providers/manage-service-providers/manage-service-providers.component';
import { SPPersonalDetailsComponent } from './service-providers/manage-service-providers/sppersonal-details/sppersonal-details.component';
import { SPCompanyProfileComponent } from './service-providers/manage-service-providers/spcompany-profile/spcompany-profile.component';
import { SPNotesComponent } from './service-providers/manage-service-providers/spnotes/spnotes.component';
import { SPSummaryComponent } from './service-providers/manage-service-providers/spsummary/spsummary.component';
import { CalendarCellComponent } from './orders/manage-order/booking-details-step/calendar-cell/calendar-cell.component';
import { ControlErrorsDirective } from './shared/directives/control-errors.directive';
import { ControlErrorComponent } from './shared/components/control-error/control-error.component';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { ControlErrorContainerDirective } from './shared/directives/control-error-container.directive';
import { FormSubmitDirective } from './shared/directives/form-submit.directive';
import { SparePartsSuggestionComponent } from './orders/manage-order/attachments-step/spare-parts-suggestion/spare-parts-suggestion.component';
import { DeleteConfirmationModalComponent } from './shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { ContactNumberModalComponent } from './shared/components/contact-number-modal/contact-number-modal.component';
import { ManageCustomersComponent } from './customers/manage-customers/manage-customers.component';
import { CustomerAddressInformationComponent } from './customers/manage-customers/customer-address-information/customer-address-information.component';
import { CustomerCommentsComponent } from './customers/manage-customers/customer-comments/customer-comments.component';
import { CustomerContactInformationComponent } from './customers/manage-customers/customer-contact-information/customer-contact-information.component';
import { CustomerSummaryComponent } from './customers/manage-customers/customer-summary/customer-summary.component';
import { ViewCustomerPopupComponent } from './customers/view-customer-popup/view-customer-popup.component';
import { ViewServiceProviderPopupComponent } from './service-providers/view-service-provider-popup/view-service-provider-popup.component';
import { ViewOrderPopupComponent } from './orders/view-order-popup/view-order-popup.component';
import { UpdateStatusPopupComponent } from './orders/update-status-popup/update-status-popup.component';
import { ManageTechniciansComponent } from './technicians/manage-technicians/manage-technicians.component';
import { TechnicianPersonalDetailsComponent } from './technicians/manage-technicians/technician-personal-details/technician-personal-details.component';
import { TechnicianSkillsDetailsComponent } from './technicians/manage-technicians/technician-skills-details/technician-skills-details.component';
import { TechnicianProfileDetailsComponent } from './technicians/manage-technicians/technician-profile-details/technician-profile-details.component';
import { TechnicianNotesDetailsComponent } from './technicians/manage-technicians/technician-notes-details/technician-notes-details.component';
import { TechnicianSummaryReportComponent } from './technicians/manage-technicians/technician-summary-report/technician-summary-report.component';
import { ViewTechnicianPopupComponent } from './technicians/view-technician-popup/view-technician-popup.component';
import { SetSPPasswordPopupComponent } from './service-providers/set-sppassword-popup/set-sppassword-popup.component';
import { SetTechnicianPasswordPopupComponent } from './technicians/set-technician-password-popup/set-technician-password-popup.component';
import { TechnicianCalendarComponent } from './technicians/technician-calendar/technician-calendar.component';
import { TechnicianCalendarCellComponent } from './technicians/technician-calendar/technician-calendar-cell/technician-calendar-cell.component';
import { TechnicianCellComponent } from './technicians/technician-calendar/technician-cell/technician-cell.component';
import { MatExpansionModule, MatSidenavModule, MatSlideToggleModule } from '@angular/material';
import { AvailableTSComponent } from './orders/manage-order/booking-details-step/available-ts/available-ts.component';
import { NotificationsListComponent } from './secure/notifications-list/notifications-list.component';
import { HelpContentComponent } from './secure/help-content/help-content.component';
import { StatusBgDirective } from './shared/directives/status-bg.directive';
import { CustomDatePipe } from './shared/pipes/custom-date.pipe';
import { OrderStatusHistoryPopupComponent } from './orders/view-order-popup/order-status-history-popup/order-status-history-popup.component';
import { OrderStatusPipe } from './shared/pipes/order-status.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StatusIcoDirective } from './shared/directives/status-ico.directive';
import { ProfileComponent } from './secure/profile/profile.component';
import { SettingsComponent } from './secure/settings/settings.component';
import { ResetPasswordPopupComponent } from './secure/settings/reset-password-popup/reset-password-popup.component';
import { WorkReportPopupComponent } from './orders/view-order-popup/work-report-popup/work-report-popup.component';
import { LoginChangePasswordComponent } from './signup/login-change-password/login-change-password.component';
import { CustomDateAdapter, CustomDateParserFormatter } from './shared/services/custom-date.adapter';

FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme, TimeSeries);


const view_component=[ConfirmationModalComponent, DeleteConfirmationModalComponent, ContactNumberModalComponent,ViewCustomerPopupComponent, ViewTechnicianPopupComponent, SetTechnicianPasswordPopupComponent, ViewOrderPopupComponent, WorkReportPopupComponent, OrderStatusHistoryPopupComponent, UpdateStatusPopupComponent, ViewServiceProviderPopupComponent, SetSPPasswordPopupComponent, ResetPasswordPopupComponent, AvailableTSComponent];

@NgModule({
  declarations: [
    AppComponent,
    
    NotificationsListComponent,
    HelpContentComponent,
    ProfileComponent,
    SettingsComponent,

    SignupLayoutComponent,
    LoginComponent,
    SignupStep1Component,
    SignupStep2Component,
    SignupStep3Component,
    LoginChangePasswordComponent,

    SecureComponent,
    LeftMenuComponent,
    TopMenuComponent,
    DashboardComponent,

    RequestsComponent,

    OrdersComponent,
    ManageOrderComponent,
    VisitTypeStepComponent,
    CustomerDetailsStepComponent,
    ApplianceDataStepComponent,
    AttachmentsStepComponent,
    SparePartsSuggestionComponent,
    BookingDetailsStepComponent,
    CalendarCellComponent,
    RequestSummaryStepComponent,

    CustomersComponent,
    ManageCustomersComponent,
    CustomerAddressInformationComponent,
    CustomerContactInformationComponent,
    CustomerCommentsComponent,
    CustomerSummaryComponent,

    PartnersComponent,

    TechniciansComponent,
    ManageTechniciansComponent,
    TechnicianPersonalDetailsComponent,
    TechnicianSkillsDetailsComponent,
    TechnicianProfileDetailsComponent,
    TechnicianNotesDetailsComponent,
    TechnicianSummaryReportComponent,
    TechnicianCalendarComponent,
    TechnicianCalendarCellComponent,
    TechnicianCellComponent,

    ServiceProvidersComponent,
    ManageServiceProvidersComponent,
    SPPersonalDetailsComponent,
    SPCompanyProfileComponent,
    SPNotesComponent,
    SPSummaryComponent,


    ControlErrorsDirective, ControlErrorComponent, ControlErrorContainerDirective, FormSubmitDirective,

    view_component,

    StatusBgDirective, StatusIcoDirective, CustomDatePipe, OrderStatusPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AppRoutingModule,
    NgSelectModule,
    ToastrModule.forRoot(),

    FusionChartsModule,

    MatSidenavModule,
    MatSlideToggleModule,
    MatExpansionModule,

    NgxSpinnerModule 
  ],
  providers:[
    { provide: APP_BASE_HREF, useValue: '/' }, { provide: LocationStrategy, useClass: HashLocationStrategy }, HTTPListener, HTTPStatus,
    { provide: HTTP_INTERCEPTORS, useClass: HTTPListener, multi: true }, { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter}, {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
  entryComponents:[ControlErrorComponent,  view_component],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function getBaseUrl() {
  return 'https://2xfiolj702.execute-api.eu-central-1.amazonaws.com/dev';
  // return ' http://localhost:3000/dev';
}
