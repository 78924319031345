<div class="wizardpage">
    <div class="wizardhead">
        <h2>{{pageTitle}}</h2>
    </div>
    <div class="wizardsteps">
        <a class="first" [class.selected]="currentStep == 'personal_details'" (click)="selStep('personal_details')">Technician details</a>
        <a [class.selected]="currentStep == 'skills_details'" (click)="selStep('skills_details')">Skills</a>
        <a [class.selected]="currentStep == 'profile_details'" (click)="selStep('profile_details')">Personal profile</a>
        <a [class.selected]="currentStep == 'notes_details'" (click)="selStep('notes_details')">Notes</a>
        <a class="last" [class.selected]="currentStep == 'summary'" (click)="selStep('summary')">Summary</a>
    </div>
    <div class="wizardbody customscrollbar">
        <div *ngIf="currentStep == 'personal_details'">
            <technician-personal-details [form]="personalDetailForm"></technician-personal-details>
        </div>
        <div *ngIf="currentStep == 'skills_details'">
            <technician-skills-details [form]="skillsDetailForm"></technician-skills-details>
        </div>
        <div *ngIf="currentStep == 'profile_details'">
            <technician-profile-details [form]="profileDetailForm"></technician-profile-details>
        </div>
        <div *ngIf="currentStep == 'notes_details'">
            <technician-notes-details [form]="noteDetailForm"></technician-notes-details>
        </div>
        <div *ngIf="currentStep == 'summary'">
            <technician-summary-report [technicianId]="technicianId"></technician-summary-report>
        </div>
    </div>
    <div class="wizardfooter">
        <div>
            <button class="btnfooter" *ngIf="currentStep != 'personal_details'" (click)="backStep()"><span class="fa fa-angle-left"></span> Back</button>
        </div>
        <div>
            <button class="btnfooter" *ngIf="currentStep != 'summary'" [disabled]="!isFormActive()" (click)="nextStep()">Next <span class="fa fa-angle-right"></span></button>
            <button class="btnfooter" (click)="setPassword()" *ngIf="currentStep == 'summary' && isCreateNew">Set Password</button>
            <button class="btnfooter" (click)="finishWizard()" *ngIf="currentStep == 'summary' && !isCreateNew">Complete</button>
        </div>
    </div>
</div>