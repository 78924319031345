export class CurrentOrderStatusObj{
    public orderId: number;
    public scheduleDate: any;
    public scheduleTime: string;
    public visitAddressPostalCode: string;
    public visitAddressCity: string;
    public status: string;
    public statusReason: string;
}
export class NewOrderStatusArg{
    public orderId: number;
    public status: string;
    public statusReason: string;
    public statusBy: string;
    public userId: number;
}
export class UnreadOrderStatusObj
{
    public orderStatusId: number;
    public orderId: number;
    public status: string;
    public statusReason: string;
    public scheduleDate: any;
    public scheduleTime: string;
    public fixFirstID: string;
    public fixFirstOrderID: string;
    public customerHouseNo: string;
    public customerCity: string;
    public isRead: boolean;
}
export class OrderStatusHistoryObj
{
    public status: string;
    public statusReason: string;
    public statusDate: any;
    public statusBy: string;
    public isCurrent: boolean;
    public isRead: boolean;
    public readDate: any;
    public serviceProviderName: string;
    public technicianName: string;
}