import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CollectionUtil } from 'src/app/shared/ccc/collections';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { SPSettingsObj } from 'src/app/shared/models/serviceprovider';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';
import { ResetPasswordPopupComponent } from './reset-password-popup/reset-password-popup.component';
import { DropDownObj } from 'src/app/shared/models/commonobj';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  readonly lstCountries: string[] = CollectionUtil.lstCountries;
  readonly lstBrands: DropDownObj[] = CollectionUtil.lstBrands;

  public spDetailForm:FormGroup = null;

  constructor(public fb:FormBuilder, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager, private router: Router, private ngbModal: NgbModal, private location: Location) { 
    this.spDetailForm = this.fb.group({
      organization: ['', [Validators.required, Validators.maxLength(100)]],
      websiteAddress: ['', [Validators.required, Validators.maxLength(100)]],
      brand: ['', [Validators.required]],

      companyAddressHouseNo:[],
      companyAddressStreet:[],
      companyAddressAdditionInfo:[],
      companyAddressNotes:[],
      companyAddressCity:[],
      companyAddressPostalCode:[],
      companyAddressCountry:[]
    });
  }

  ngOnInit() {
    let loginResponse: LoginResponseObj = this.modelService.getLoginDetail();

    this.dataService.getSPById(loginResponse.userId).subscribe(res=>{
      this.spDetailForm.controls["organization"].setValue(res.organization);
      this.spDetailForm.controls["websiteAddress"].setValue(res.websiteAddress);
      this.spDetailForm.controls["brand"].setValue(res.brand);

      this.spDetailForm.controls["companyAddressHouseNo"].setValue(res.companyAddressHouseNo);
      this.spDetailForm.controls["companyAddressStreet"].setValue(res.companyAddressStreet);
      this.spDetailForm.controls["companyAddressAdditionInfo"].setValue(res.companyAddressAdditionInfo);
      this.spDetailForm.controls["companyAddressNotes"].setValue(res.companyAddressNotes);
      this.spDetailForm.controls["companyAddressCity"].setValue(res.companyAddressCity);
      this.spDetailForm.controls["companyAddressPostalCode"].setValue(res.companyAddressPostalCode);
      this.spDetailForm.controls["companyAddressCountry"].setValue(res.companyAddressCountry);
    });
  }
  updateSPDetail()
  {
    let loginResponseObj: LoginResponseObj= this.modelService.getLoginDetail();
    let spSettingsObj: SPSettingsObj = Object.assign({}, this.spDetailForm.value);
    spSettingsObj.serviceProviderId = loginResponseObj.userId;

    this.dataService.updateSPSettings(spSettingsObj).subscribe(spId=>{
     
      let loginDetail= this.modelService.getLoginDetail();
     loginDetail.country  = spSettingsObj.companyAddressCountry;
     loginDetail.brand = spSettingsObj.brand;
     this.modelService.setLoginDetail(loginDetail);

      this.toastr.successToastr('Settings successfully updated');
    });
  }

  public resetPassword()
  {
    const modalRef = this.ngbModal.open(ResetPasswordPopupComponent,{ backdrop: 'static', centered: true});

    modalRef.result.then((data: ModalConfirmationStatus) => {
      if (data == ModalConfirmationStatus.CONFIRMED) {
      }
    }, (reason) => {
    });
  }

  goBack()
  {
    this.location.back();
  }

}
