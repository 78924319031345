<div class="wizardpage">
    <div class="wizardhead">
        <h2>{{pageTitle}}</h2>
    </div>
    <div class="wizardsteps">
        <a class="first" [class.selected]="currentStep == 'personal_details'" (click)="selStep('personal_details')">Personal details</a>
        <a [class.selected]="currentStep == 'company_profile'" (click)="selStep('company_profile')">Company profile</a>
        <a [class.selected]="currentStep == 'notes'" (click)="selStep('notes')">Notes</a>
        <a class="last" [class.selected]="currentStep == 'summary'" (click)="selStep('summary')">Summary</a>
    </div>
    <div class="wizardbody customscrollbar">
        <div *ngIf="currentStep == 'personal_details'">
            <sp-personal-details [serviceProviderId]="serviceProviderId" [form]="spDetailForm"></sp-personal-details>
        </div>
        <div *ngIf="currentStep == 'company_profile'">
            <sp-company-profile [serviceProviderId]="serviceProviderId" [form]="companyProfileForm"></sp-company-profile>
        </div>
        <div *ngIf="currentStep == 'notes'">
            <sp-notes [serviceProviderId]="serviceProviderId" [form]="notesForm"></sp-notes>
        </div>
        <div *ngIf="currentStep == 'summary'">
            <sp-summary [serviceProviderId]="serviceProviderId"></sp-summary>
        </div>
    </div>
    <div class="wizardfooter">
        <div>
            <button class="btnfooter" *ngIf="currentStep != 'personal_details'" (click)="backStep()"><span class="fa fa-angle-left"></span> Back</button>
        </div>
        <div>
            <button class="btnfooter" *ngIf="currentStep != 'summary'" [disabled]="!isFormActive()" (click)="nextStep()">Next <span class="fa fa-angle-right"></span></button>
            <button class="btnfooter" (click)="setPassword()" *ngIf="currentStep == 'summary' && isCreateNew">Set Password</button>
            <button class="btnfooter" (click)="finishWizard()" *ngIf="currentStep == 'summary' && !isCreateNew">Finish</button>
        </div>
    </div>
</div>