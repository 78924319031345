<div class="wizardpage">
    <div class="wizardhead">
        <h2>Work report</h2>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="wizardbodypopup customscrollbar" *ngIf="wrObj">
        <div class="wizardinfobox" >
            <div class="row0">
                <div class="leftcol">
                    <div class="icotoolbox">
                        <div class="icotool"></div>
                        <div class="tooltxt">
                            <h3>{{wrObj.visitType}}</h3>
                            <p class="pmain">FixFirst</p>
                        </div>
                    </div>
                    <div class="leftinfocellsmall">{{wrObj.applianceType}}</div>
                    <div class="leftinfocell"><span class="fa fa-calendar"></span>{{wrObj.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} @ {{wrObj.scheduleTime}}</div>
                    <div class="leftinfocell"><span class="fa fa-map-marker"></span>{{wrObj.visitAddressPostalCode}} {{wrObj.visitAddressCity}}</div>
                </div>
                <div>
                    <div class="rightinfocell"><span class="caption">Request date:</span> {{wrObj.requestDate | customdate:'DD.MM.YYYY hh:mm a'}}</div>
                    <div class="rightinfocell"><span class="caption">Service technician:</span> <span>{{wrObj.technicianFirstName}} {{wrObj.technicianLastName}}</span></div>
                    <div class="rightinfocell">
                        <span class="bdgbox" [statusbg]="wrObj.orderStatus">{{wrObj.orderStatus | orderstatus}}</span>
                        <div class="clearfix"></div>
                    </div>
                    <div class="rightinfocell statusdesc" *ngIf="wrObj.orderStatusReason">
                        <span class="bdgbox" [statusbg]="wrObj.orderStatus">{{wrObj.orderStatusReason}}</span>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardinfobox">
            <h3>Order information</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">FixFirst-ID:</label>
                        <label class="content">{{wrObj.fixFirstId}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Customer:</label>
                        <label class="content">{{wrObj.customerFirstName}} {{wrObj.customerLastName}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Service Provider:</label>
                        <label class="content">{{wrObj.spFirstName}} {{wrObj.spLastName}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Order-ID:</label>
                        <label class="content">{{wrObj.fixFirstOrderId}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Technician:</label>
                        <label class="content">{{wrObj.technicianFirstName}} {{wrObj.technicianLastName}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardinfobox">
            <h3>Tasks performed & time details</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Driving:</label>
                        <label class="content">{{wrObj.drivingMinutes}} minutes</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Inspection:</label>
                        <label class="content">{{wrObj.inspectionMinutes}} minutes</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Repairing:</label>
                        <label class="content">{{wrObj.repairMinutes}} minutes</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Consultation:</label>
                        <label class="content">{{wrObj.consultationMinutes}} minutes</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Other tasks:</label>
                        <label class="content">{{wrObj.otherTaskMinutes}} minutes</label>
                    </div>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
        </div>
        <div class="wizardinfobox">
            <h3>Problem & Solution</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Problem:</label>
                        <label class="content">{{wrObj.problemTxt}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Solution:</label>
                        <label class="content">{{wrObj.solutionTxt}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
        </div>
        <div class="wizardinfobox">
            <h3>Spare parts & materials used</h3>
            <table class="table table-bordered table-sm table-striped">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Status</th>
                        <th>Qty</th>
                        <th>Price (€)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of wrObj.lstSpareParts">
                        <td>{{item.itemName}}</td>
                        <td>{{item.itemStatus}}</td>
                        <td>{{item.itemQuantity}}</td>
                        <td>{{item.itemPrice}}</td>
                    </tr>
                    <tr>
                        <td><strong>Subtotal</strong></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{wrObj.sparePartsTotal.toFixed(2)}}</strong></td>
                    </tr>
                    <tr>
                        <td><strong>Tax 21%</strong></td>
                        <td></td>
                        <td></td>
                        <td><strong>{{wrObj.sparePartsTax.toFixed(2)}}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="wizardinfobox">
            <h3>Hourly Rate</h3>
            <table class="table table-bordered table-sm table-striped">
                <thead>
                    <tr>
                        <th>Concept</th>
                        <th>Qty</th>
                        <th>Price (€)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Labour rate
                            <br />
                            @ {{wrObj.hourlyRate}} / hour
                        </td>
                        <td>{{wrObj.labourHours}},{{wrObj.labourMinutes}}</td>
                        <td>{{wrObj.labourTotal.toFixed(2)}}</td>
                    </tr>
                    <tr>
                        <td>Driving Lump sum</td>
                        <td></td>
                        <td>{{wrObj.drivingLumpSum}}</td>
                    </tr>
                    <tr>
                        <td><strong>Subtotal</strong></td>
                        <td></td>
                        <td><strong>{{(wrObj.labourTotal + wrObj.drivingLumpSum).toFixed(2)}}</strong></td>
                    </tr>
                    <tr>
                        <td><strong>Tax 21%</strong></td>
                        <td></td>
                        <td><strong>{{wrObj.labourTax.toFixed(2)}}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="wizardinfobox">
            <h3>TOTAL (€)</h3>
            <table class="table table-bordered table-sm table-striped">
                <tbody>
                    <tr>
                        <td>Materials & spare parts</td>
                        <td>{{(wrObj.sparePartsTotal + wrObj.sparePartsTax).toFixed(2)}}</td>
                    </tr>
                    <tr>
                        <td>Driving + Labour Total</td>
                        <td>{{(wrObj.drivingLumpSum + wrObj.labourTotal + wrObj.labourTax).toFixed(2)}}</td>
                    </tr>
                    <tr>
                        <td><strong>Grand total</strong></td>
                        <td><strong>{{wrObj.grandTotal.toFixed(2)}}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="wizardbodypopup" *ngIf="wrObj && !isWRComplete">
        <h4 style="color:red;">Work report not completed by service technician.</h4>
    </div>
    <div class="wizardfooter">
        <div></div>
        <div>
        </div>
    </div>
</div>