import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'attachments-step',
  templateUrl: './attachments-step.component.html',
  styleUrls: ['./attachments-step.component.css']
})
export class AttachmentsStepComponent implements OnInit {

  @Input()
  public orderId: number = 0;
  
  @Input()
  public form:FormGroup = null;

  constructor(public fb:FormBuilder) { }

  ngOnInit() {
  }

  get lstSpareParts():FormArray {
    return this.form.get('lstSpareParts') as FormArray;
  };
  addSparePart(){
    this.lstSpareParts.push(this.fb.group({
      itemName: ['', [Validators.required]],
      itemCode: ['', [Validators.required]],
      itemQuantity: ['', [Validators.required]]
    }));
  }
  removeSparePart(index: number)
  {
    this.lstSpareParts.removeAt(index);
  }

}
