import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginResponseObj } from '../models/login';
@Injectable({
  providedIn: 'root'
})
export class ModelService {

  public searchTerm$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

  setLoginDetail(loginDetail: LoginResponseObj) {
    localStorage.setItem("login", JSON.stringify(loginDetail));
  }
  getLoginDetail():LoginResponseObj {
    return JSON.parse(localStorage.getItem("login"));
  }
  clearLocalStorage()
  {
    localStorage.clear();
    
  }
}
