import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoginResponseObj } from '../shared/models/login';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  
  public form:FormGroup = null;

  public pageUrl: string = "";

  @Input()
  statusCount: number = 0;

  @Output()
  public onShowNotifications:EventEmitter<any> = new EventEmitter();

  @Output()
  public onShowHelp:EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, public fb:FormBuilder, private modelService: ModelService) { 
    this.router.events.pipe(filter(x=>x instanceof NavigationEnd)).subscribe((res:any)=>{
      this.pageUrl = res.url;
      this.form.reset();
    });
    
    this.form = this.fb.group({
      searchTerm: ['']
    });
  }

  ngOnInit(): void {
  }

  getUserTypeTxt()
  {
    let userType='';
    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();
    if(loginDetail)
    {
      if(loginDetail.userType == 'SP')
      {
        return 'Service provider';
      }
      else
      {
        return 'Customer care';
      }
    }
    return userType;
  }

  showHelp()
  {
    this.onShowHelp.emit();
  }
  showNotifications()
  {
    this.onShowNotifications.emit();
  }

  public showSearchBar():boolean
  {
    let show:boolean = false;
    switch(this.pageUrl)
    {
      case "/home/orders":
        show=true;
        break;
      case "/home/customers":
        show=true;
        break;
      case "/home/service-providers":
        show=true;
        break;
      case "/home/technicians":
        show=true;
        break;
    }
    return show;
  }

  public searchPlaceHolderTxt():string
  {
    let txt:string = "";
    switch(this.pageUrl)
    {
      case "/home/orders":
        txt = "search orders by first-name, last-name, fix-first-id";
        break;
      case "/home/customers":
        txt = "search customers by first-name, last-name";
        break;
      case "/home/service-providers":
        txt = "search service-providers by first-name, last-name";
        break;
      case "/home/technicians":
        txt = "search technicians by first-name, last-name";
        break;
    }
    return txt;
  }

  public search()
  {
    let searchTerm: string = this.form.value.searchTerm;
      let page : string = "";
      switch(this.pageUrl)
      {
        case "/home/orders":
          page = "orders";
          break;
        case "/home/customers":
          page = "customers";
          break;
        case "/home/service-providers":
          page = "service_providers";
          break;
        case "/home/technicians":
          page = "technicians";
          break;
      }
    this.modelService.searchTerm$.next({page:page, searchTerm:searchTerm});
  }

}
