import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { OrderStatusConstants } from '../ccc/constants';

@Directive({
  selector: '[statusico]'
})
export class StatusIcoDirective {

  constructor(private el: ElementRef) { 
  }

  @HostBinding('class.fa-clock-o')
  public get isNewStatus(): boolean {
    return this.statusico == OrderStatusConstants.New;
  }

  @HostBinding('class.fa-calendar')
  public get isScheduledStatus(): boolean {
    return this.statusico == OrderStatusConstants.Schedule;
  }

  @HostBinding('class.svgico')
  @HostBinding('class.icoreschedule')
  public get isRescheduledStatus(): boolean {
    return this.statusico == OrderStatusConstants.Reschedule;
  }

  @HostBinding('class.fa-times')
  public get isCancelledStatus(): boolean {
    return this.statusico == OrderStatusConstants.Cancel;
  }

  @HostBinding('class.fa-repeat')
  public get isInProgressStatus(): boolean {
    return this.statusico == OrderStatusConstants.InProgress;
  }

  @HostBinding('class.fa-check')
  public get isCompletedStatus(): boolean {
    return this.statusico == OrderStatusConstants.Complete;
  }


  @Input('statusico') statusico: string;
}