export class OrderListingArg
{
    public role: string;
    public userId: number;
    public status: string;
    public searchTerm: string;
}
export class OrderListingObj{
    public orderId: number;
    public customerId: number;
    public visitType: string;
    public fixFirstId: string;
    public applianceType: string;
    public visitAddressPostalCode: string;
    public visitAddressCity: string;
    public orderStatus: string;
    public scheduleDate: any;
    public scheduleTime: string;
    public spFirstName: string;
    public spLastName: string;
    public createdDate: any;
}

export class OrderObj{
    public orderId: number;

    public orderStatus: string;
    public orderStatusReason: string;

    public customerCareId: number;
    
    public serviceProviderId: number;
    public serviceProviderName: string;
    public serviceProviderOrganization: string;

    public visitType: string;

    public customerId: number;

    public fixtFirstID: string;
    public fixFirstOrderID: string;

    public noOfAppliances: number;
    public applianceType: string;
    public applianceBrand: string;
    public applianceModelNumber: string;
    public applianceSerialNumber: string;
    public applianceIndexNumber: string;
    public applianceAge: number;
    public applianceContractNumber: string;
    
    public applianceIsWarranty: boolean;
    public applianceWarrantyContractNo: string;
    public applianceWarrantyDetails: string;
    public applianceWarrantyNotes: string;

    public applianceInstallationDetail: string;
    public applianceLevelOfUsage: string;
    public appliancePurchaseDate: any;
    public applianceNewPrice: number;
    public errorTitle: string;
    public errorCode: string;
    public errorDetails: string;
    public errorNotes: string;
    public insightRecallInfo: string;
    public insightOther: string;
    
    public recommendationRepair: string;
    public recommendationOther: string;

    public scheduleDate: any;
    public scheduleTime: string;

    public technicianId: number;
    public technicianName: string;

    public createdDate: any;

    public customerFirstName: string;
    public customerLastName: string;
    public customerEmail: string;
    public customerMobileNumberExtension: string;
    public customerMobileNumber: string;
    public customerWhatsAppContactAllowed: boolean;
    public customerLanguages: string;
    public customerAdditionalNotes: string;
    public customerVisitAddressHouseNo: string;
    public customerVisitAddressStreetNo: string;
    public customerVisitAddressAdditionInfo: string;
    public customerVisitAddressNotes: string;
    public customerVisitAddressCity: string;
    public customerVisitAddressPostalCode: string;
    public customerVisitAddressCountry: string;
    public customerVisitAddressIsElevator: boolean;
    public customerIsBillingAddressSame: boolean;
    public customerBillingAddressHouseNo: string;
    public customerBillingAddressStreetNo: string;
    public customerBillingAddressAdditionInfo: string;
    public customerBillingAddressNotes: string;
    public customerBillingAddressCity: string;
    public customerBillingAddressPostalCode: string;
    public customerBillingAddressCountry: string;
}
export class OrderCommentObj{
    public commentText: string;
    public commentDate: any;
    public commentBy: string;
    public spName: string;
    public customerName: string;
    public technicianName: string;
}


export class OrderVisitTypeObj{
    public orderId: number;

    public visitType: string;
}
export class OrderCustomerDetailObj{
    public orderId: number;

    public customerId: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
    public languages: string;
    public notes: string;

    public visitAddressHouseNo: string;
    public visitAddressStreetNo: string;
    public visitAddressAdditionInfo: string;
    public visitAddressNotes: string;
    public visitAddressCity: string;
    public visitAddressPostalCode: string;
    public visitAddressCountry: string;
    public visitAddressIsElevator: boolean;

    public isBillingAddressSame: boolean;
    public billingAddressHouseNo: string;
    public billingAddressStreetNo: string;
    public billingAddressAdditionInfo: string;
    public billingAddressNotes: string;
    public billingAddressCity: string;
    public billingAddressPostalCode: string;
    public billingAddressCountry: string;
}
export class OrderApplianceDataObj{
    public orderId: number;

    public fixFirstOrderID: string;

    public noOfAppliances: number;

    public applianceType: string;
    public applianceBrand: string;
    public applianceModelNumber: string;
    public applianceSerialNumber: string;
    public applianceIndexNumber: string;
    public applianceAge: number;
    public applianceContractNumber: string;
    
    public applianceIsWarranty: boolean;
    public applianceWarrantyContractNo: string;
    public applianceWarrantyDetails: string;
    public applianceWarrantyNotes: string;

    public applianceInstallationDetail: string;
    public applianceLevelOfUsage: string;
    public appliancePurchaseDate: any;
    public applianceNewPrice: number;

    public errorTitle: string;
    public errorCode: string;
    public errorDetails: string;
    public errorNotes: string;

    public insightRecallInfo: string;
    public insightOther: string;
}
export class OrderAttachmentObj{
    public orderId: number;
    
    public recommendationRepair: string;
    public recommendationOther: string;
    
    public lstSpareParts: OrderSparePartSuggestionObj[]=[];

    public commentById: number;
    public orderComments: string;
}
export class OrderSparePartSuggestionObj{
    public itemName: string;
    public itemCode: string;
    public itemQuantity: number;
}
export class OrderScheduleSPObj{
    public orderId: number;
    public spId:number;
    public scheduleDate: string;
    public scheduleTime: string;
}
export class OrderScheduleTechnicianObj{
    public orderId: number;
    public technicianId:number;
}