<div class="wizardinfosection" *ngIf="serviceProviderObj">
    <div class="wizardinfobox">
        <h3>Service provider information</h3>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Organization:</label>
                    <label class="content">{{serviceProviderObj.organization}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Brand:</label>
                    <label class="content">{{serviceProviderObj.brand}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Website:</label>
                    <label class="content">{{serviceProviderObj.websiteAddress}}</label>
                </div>
            </div>
        </div>
        <h4>Contact information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Name:</label>
                    <label class="content">{{serviceProviderObj.firstName}} {{serviceProviderObj.lastName}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Mobile:</label>
                    <label class="content">{{serviceProviderObj.mobileNumberExtension}} {{serviceProviderObj.mobileNumber}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Whatsapp:</label>
                    <label class="content">{{serviceProviderObj.whatsAppContactAllowed}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Role:</label>
                    <label class="content">{{serviceProviderObj.role}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Email:</label>
                    <label class="content">{{serviceProviderObj.email}}</label>
                </div>
            </div>
        </div>
        <h4>Company address</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Street:</label>
                    <label class="content">{{serviceProviderObj.companyAddressStreet}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{serviceProviderObj.companyAddressNotes}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">City:</label>
                    <label class="content">{{serviceProviderObj.companyAddressCity}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Country:</label>
                    <label class="content">{{serviceProviderObj.companyAddressCountry}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Number:</label>
                    <label class="content">{{serviceProviderObj.companyAddressHouseNo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Addition:</label>
                    <label class="content">{{serviceProviderObj.companyAddressAdditionInfo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Postal code:</label>
                    <label class="content">{{serviceProviderObj.companyAddressPostalCode}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardinfobox">
        <h3>Company profile</h3>
        <p [innerHTML]="serviceProviderObj.companyProfile">

        </p>
    </div>
    <div class="wizardinfobox">
        <h3>Notes</h3>
        <p [innerHTML]="serviceProviderObj.notes"></p>
    </div>
</div>