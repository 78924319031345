import { Component, Input, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CustomerObj } from 'src/app/shared/models/customer';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'customer-summary',
  templateUrl: './customer-summary.component.html',
  styleUrls: ['./customer-summary.component.css']
})
export class CustomerSummaryComponent implements OnInit {

  @Input()
  public customerId: number=0;

  public customerObj: CustomerObj = null;
  
  constructor(private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
    this.dataService.getCustomerById(this.customerId).subscribe(customer=>{
      this.customerObj = customer;
    });
  }

}
