import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { forkJoin } from 'rxjs';
import { Util } from 'src/app/shared/ccc/util';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { OrderApplianceDataObj, OrderAttachmentObj, OrderCustomerDetailObj, OrderObj, OrderSparePartSuggestionObj, OrderVisitTypeObj } from 'src/app/shared/models/order';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-manage-order',
  templateUrl: './manage-order.component.html',
  styleUrls: ['./manage-order.component.css']
})
export class ManageOrderComponent implements OnInit {

  public orderId: number = 0;
  public customerId: number = 0;
  public pageTitle: string = "Create new order";
  public currentStep:string = 'visit_type';

  public visitTypeForm:FormGroup = null;
  public customerDetailForm:FormGroup = null;
  public applianceDetailForm:FormGroup = null;
  public attachmentsForm: FormGroup = null;


  constructor(public activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, public fb:FormBuilder, private toastr: ToastrManager, private modelService: ModelService) { 
    
    let loginResponseObj:LoginResponseObj = this.modelService.getLoginDetail();

    this.orderId = this.activatedRoute.snapshot.params.id;

    this.visitTypeForm = this.fb.group({
      visitType: ['On-Site']
    });

    this.customerDetailForm = this.fb.group({
      firstName: [, [Validators.required, Validators.maxLength(100)]],
      lastName: [, [Validators.required, Validators.maxLength(100)]],
      email: [, [Validators.required, Validators.maxLength(150)]],
      mobileNumberExtension:[, [Validators.required, Validators.maxLength(10)]],
      mobileNumber:[, [Validators.required, Validators.maxLength(50)]],
      whatsAppContactAllowed:[],
      languages:[, [Validators.required, Validators.maxLength(200)]],
      notes:[, [Validators.maxLength(500)]],

      visitAddressHouseNo:[,[Validators.required, Validators.maxLength(50)]],
      visitAddressStreetNo:[,[Validators.required, Validators.maxLength(100)]],
      visitAddressAdditionInfo:[,[Validators.maxLength(500)]],
      visitAddressNotes:[,[Validators.maxLength(500)]],
      visitAddressCity:[,[Validators.required, Validators.maxLength(100)]],
      visitAddressPostalCode:[,[Validators.required, Validators.maxLength(100)]],
      visitAddressCountry:[loginResponseObj.country,[Validators.required, Validators.maxLength(100)]],
      visitAddressIsElevator:[],

      isBillingAddressSame:[],
      
      billingAddressHouseNo:[,[Validators.required, Validators.maxLength(50)]],
      billingAddressStreetNo:[,[Validators.required, Validators.maxLength(100)]],
      billingAddressAdditionInfo:[,[Validators.maxLength(500)]],
      billingAddressNotes:[,[Validators.maxLength(500)]],
      billingAddressCity:[,[Validators.required, Validators.maxLength(100)]],
      billingAddressPostalCode:[,[Validators.required, Validators.maxLength(100)]],
      billingAddressCountry:[loginResponseObj.country,[Validators.required, Validators.maxLength(100)]]
    });

    this.applianceDetailForm = this.fb.group({
      fixFirstOrderID: [, [Validators.required]],
      noOfAppliances: [1, [Validators.required, Validators.min(1)]],
      
      applianceType: [, [Validators.required]],
      applianceBrand: [loginResponseObj.brand, [Validators.required]],
      applianceModelNumber: [, []],
      applianceSerialNumber: [, []],
      applianceIndexNumber: [, []],
      applianceAge: [, []],
      applianceContractNumber: [, []],
      
      applianceIsWarranty: [false, []],
      applianceWarrantyContractNo: [, []],
      applianceWarrantyDetails: [, []],
      applianceWarrantyNotes: [, []],

      applianceInstallationDetail: [, []],
      applianceLevelOfUsage: [, []],
      appliancePurchaseDate: [],
      applianceNewPrice: [, []],

      errorTitle: [, [Validators.required]],
      errorCode: [, []],
      errorDetails: [, [Validators.required]],
      errorNotes: [, []],

      insightRecallInfo: [, []],
      insightOther: [, []]
    });

    this.attachmentsForm = this.fb.group({
      recommendationRepair: [, [Validators.maxLength(100)]],
      recommendationOther: [, [Validators.maxLength(100)]],
      orderComments: [, [Validators.maxLength(100)]],
      lstSpareParts: this.fb.array([])
    });
    
  }

  ngOnInit() {
    if(this.orderId)
    {
      this.pageTitle="Update order";
      let req1$ = this.dataService.getOrderById(this.orderId);
      let req2$ = this.dataService.getOrderSpareParts(this.orderId);
      forkJoin([req1$,req2$]).subscribe(([order, lstSparePartsArr])=>{

        this.visitTypeForm.controls["visitType"].setValue(order.visitType);

        if(order.customerId)
        {
          this.customerId = order.customerId;
          this.dataService.getCustomerById(order.customerId).subscribe(customer=>{
          this.customerDetailForm.controls["firstName"].setValue(customer.firstName);
          this.customerDetailForm.controls["lastName"].setValue(customer.lastName);
          this.customerDetailForm.controls["email"].setValue(customer.email);
          this.customerDetailForm.controls["mobileNumberExtension"].setValue(customer.mobileNumberExtension);
          this.customerDetailForm.controls["mobileNumber"].setValue(customer.mobileNumber);
          this.customerDetailForm.controls["whatsAppContactAllowed"].setValue(customer.whatsAppContactAllowed);
          this.customerDetailForm.controls["languages"].setValue(Util.ToStringArr(customer.languages));
          this.customerDetailForm.controls["notes"].setValue(customer.notes);
          this.customerDetailForm.controls["visitAddressHouseNo"].setValue(customer.visitAddressHouseNo);
          this.customerDetailForm.controls["visitAddressStreetNo"].setValue(customer.visitAddressStreetNo);
          this.customerDetailForm.controls["visitAddressAdditionInfo"].setValue(customer.visitAddressAdditionInfo);
          this.customerDetailForm.controls["visitAddressNotes"].setValue(customer.visitAddressNotes);
          this.customerDetailForm.controls["visitAddressCity"].setValue(customer.visitAddressCity);
          this.customerDetailForm.controls["visitAddressPostalCode"].setValue(customer.visitAddressPostalCode);
          if(customer.visitAddressCountry)
          {
            this.customerDetailForm.controls["visitAddressCountry"].setValue(customer.visitAddressCountry);
          }
          this.customerDetailForm.controls["visitAddressIsElevator"].setValue(customer.visitAddressIsElevator);
          this.customerDetailForm.controls["isBillingAddressSame"].setValue(customer.isBillingAddressSame);
          this.customerDetailForm.controls["billingAddressHouseNo"].setValue(customer.billingAddressHouseNo);
          this.customerDetailForm.controls["billingAddressStreetNo"].setValue(customer.billingAddressStreetNo);
          this.customerDetailForm.controls["billingAddressAdditionInfo"].setValue(customer.billingAddressAdditionInfo);
          this.customerDetailForm.controls["billingAddressNotes"].setValue(customer.billingAddressNotes);
          this.customerDetailForm.controls["billingAddressCity"].setValue(customer.billingAddressCity);
          this.customerDetailForm.controls["billingAddressPostalCode"].setValue(customer.billingAddressPostalCode);
          if(customer.billingAddressCountry)
          {
            this.customerDetailForm.controls["billingAddressCountry"].setValue(customer.billingAddressCountry);
          }
          });
        }


        if(order.noOfAppliances)
        {
          this.applianceDetailForm.controls["noOfAppliances"].setValue(order.noOfAppliances);
        }
        this.applianceDetailForm.controls["fixFirstOrderID"].setValue(order.fixFirstOrderID);

        this.applianceDetailForm.controls["applianceType"].setValue(order.applianceType);
        if(order.applianceBrand)
        {
          this.applianceDetailForm.controls["applianceBrand"].setValue(order.applianceBrand);
        }
        this.applianceDetailForm.controls["applianceModelNumber"].setValue(order.applianceModelNumber);
        this.applianceDetailForm.controls["applianceSerialNumber"].setValue(order.applianceSerialNumber);
        this.applianceDetailForm.controls["applianceIndexNumber"].setValue(order.applianceSerialNumber);
        this.applianceDetailForm.controls["applianceAge"].setValue(order.applianceAge);
        this.applianceDetailForm.controls["applianceContractNumber"].setValue(order.applianceContractNumber);
        
        this.applianceDetailForm.controls["applianceIsWarranty"].setValue(order.applianceIsWarranty);
        this.applianceDetailForm.controls["applianceWarrantyContractNo"].setValue(order.applianceWarrantyContractNo);
        this.applianceDetailForm.controls["applianceWarrantyDetails"].setValue(order.applianceWarrantyDetails);
        this.applianceDetailForm.controls["applianceWarrantyNotes"].setValue(order.applianceWarrantyNotes);

        this.applianceDetailForm.controls["applianceInstallationDetail"].setValue(order.applianceInstallationDetail);
        this.applianceDetailForm.controls["applianceLevelOfUsage"].setValue(order.applianceLevelOfUsage);
        if(order.appliancePurchaseDate)
        {
          this.applianceDetailForm.controls["appliancePurchaseDate"].setValue(Util.StringToNgbDate(order.appliancePurchaseDate));
        }
        this.applianceDetailForm.controls["applianceNewPrice"].setValue(order.applianceNewPrice);
        this.applianceDetailForm.controls["errorTitle"].setValue(order.errorTitle);
        this.applianceDetailForm.controls["errorCode"].setValue(order.errorCode);
        this.applianceDetailForm.controls["errorDetails"].setValue(order.errorDetails);
        this.applianceDetailForm.controls["errorNotes"].setValue(order.errorNotes);
        this.applianceDetailForm.controls["insightRecallInfo"].setValue(order.insightRecallInfo);
        this.applianceDetailForm.controls["insightOther"].setValue(order.insightOther);


        this.attachmentsForm.controls["recommendationRepair"].setValue(order.recommendationRepair);
        this.attachmentsForm.controls["recommendationOther"].setValue(order.recommendationOther);
        
        let lstSparePartsForm= this.attachmentsForm.controls["lstSpareParts"] as FormArray;
          if(lstSparePartsArr)
          {
            lstSparePartsArr.forEach(x => {
              lstSparePartsForm.push(this.fb.group({
                itemName: [x.itemName, [Validators.required]],
                itemCode: [x.itemCode, [Validators.required]],
                itemQuantity: [x.itemQuantity, [Validators.required]]
              }));
            });
          }

        this.attachmentsForm.controls["orderComments"].setValue('');
      });
    }
    else
    {
    }
  }

  changeStep(newStep: string)
  {
    this.currentStep = newStep;
  }

  backStep()
  {
    switch(this.currentStep)
    {
      case 'customer_details':
        this.currentStep = 'visit_type';
        break;
      case 'appliance_data':
        this.currentStep = 'customer_details';
        break;
      case 'attachments':
        this.currentStep = 'appliance_data';
        break;
      case 'booking_details':
        this.currentStep = 'attachments';
        break;
      case 'request_summary':
        this.currentStep = 'booking_details';
        break;
    }
  }
  nextStep()
  {
    switch(this.currentStep)
    {
      case 'visit_type':
        let visitTypeObj: OrderVisitTypeObj = Object.assign({}, this.visitTypeForm.value);

        if(!this.orderId)
        {
          //this id shall be replaced by the logged-in user id (serviceprovider / customer-care)
          this.dataService.createEmptyOrder(1).subscribe(res=>{
            this.orderId = res;

            visitTypeObj.orderId = this.orderId;
            this.dataService.updateOrderVisitType(visitTypeObj).subscribe(res=>{
              this.toastr.successToastr('Visit type saved successfully');
              this.currentStep = 'customer_details';
            });

          });
        }
        else
        {
          visitTypeObj.orderId = this.orderId;
          this.dataService.updateOrderVisitType(visitTypeObj).subscribe(res=>{
            this.toastr.successToastr('Visit type saved successfully');
            this.currentStep = 'customer_details';
          });
        }
        break;
      case 'customer_details':
        let customerDetailObj: OrderCustomerDetailObj = Object.assign({}, this.customerDetailForm.getRawValue());
        
        customerDetailObj.languages= Util.ToText(this.customerDetailForm.controls['languages'].value);

        customerDetailObj.orderId = this.orderId;
        customerDetailObj.customerId = this.customerId;
        this.dataService.updateOrderCustomerDetail(customerDetailObj).subscribe(res=>{
          this.toastr.successToastr('Customer details saved successfully');
          this.currentStep = 'appliance_data';
        });
        break;
      case 'appliance_data':
        let applianceDataObj: OrderApplianceDataObj = {...this.applianceDetailForm.value};

        //applianceDataObj.appliancePurchaseDate = '2021-01-21';
        console.log(this.applianceDetailForm.value["appliancePurchaseDate"]);
        if(this.applianceDetailForm.value["appliancePurchaseDate"])
        {
          //applianceDataObj.appliancePurchaseDate =  Util.NgbDateToString(Util.ParseCustomDate(this.applianceDetailForm.value["appliancePurchaseDate"]));
        }
        else
        {
          applianceDataObj.appliancePurchaseDate = "";
        }
        applianceDataObj.orderId = this.orderId;
        console.log(applianceDataObj);
        this.dataService.updateOrderApplianceData(applianceDataObj).subscribe(res=>{
          this.toastr.successToastr('Appliance details saved successfully');
          this.currentStep = 'attachments';
        });
        break;
      case 'attachments':
        let attachmentDataObj: OrderAttachmentObj = Object.assign({}, this.attachmentsForm.value);
        attachmentDataObj.orderId = this.orderId;
        attachmentDataObj.commentById = 1; //this id shall be replaced by the logged-in user id (serviceprovider / customer-care)
        this.dataService.updateOrderAttachment(attachmentDataObj).subscribe(res=>{
          this.toastr.successToastr('Recommendations saved successfully');
          this.currentStep = 'booking_details';
        });
        break;
      case 'booking_details':
        this.currentStep = 'request_summary';
        break;
    }
  }

  selStep(selectedStep: string)
  {
    this.currentStep = selectedStep;
  }

  isFormActive():boolean
  {
    let isActive: boolean = true;
    switch(this.currentStep)
    {
      case "visit_type":
        isActive = this.visitTypeForm.valid;
        break;
      case "customer_details":
        isActive = this.customerDetailForm.valid;
        break;
      case "appliance_data":
        isActive = this.applianceDetailForm.valid;
        break;
      case "attachments":
        isActive = this.attachmentsForm.valid;
        break;
    }
    return isActive;
  }

  finishWizard(){
    this.router.navigate(['home/orders']);
  }

}
