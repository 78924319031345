import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationStatus } from '../../ccc/constants';

@Component({
  selector: 'delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.css']
})
export class DeleteConfirmationModalComponent implements OnInit {

  @Input()
  heading: string = "Are you sure you want to delete the selected customers?";
  @Input()
  body: string = "Please note that the information cannot be recovered.";

  @Input()
  btnCloseText: string="Undo";

  @Input()
  btnConfirmText: string="Delete customers";
  

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  close(){
    this.activeModal.close(ModalConfirmationStatus.CLOSED);
  }
  confirm(){
    this.activeModal.close(ModalConfirmationStatus.CONFIRMED);
  }
}
