<h3>Customer data</h3>
<div class="wizardformsection" [formGroup]="form">
<div class="wizardformpartbox">
    <h4>Contact Information</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>First name<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="1" formControlName="firstName" />
            </div>
            <div class="form-group">
                <label>Email<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="3" formControlName="email" />
            </div>
            <div class="form-group">
                <label>Languages<span class="required">*</span></label>
                <ng-select [items]="lstLanguages" [multiple]="true" groupBy="gender" tabindex="6" [selectableGroup]="true" [closeOnSelect]="false" formControlName="languages">
                </ng-select>
            </div>
            <div class="form-group">
                <label>Note</label>
                <input type="text" class="form-control" tabindex="8" formControlName="notes"/>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Last name<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="2" formControlName="lastName"/>
            </div>
            <div class="form-group">
                <div class="multicontrolbox">
                    <div>
                        <label>Extension<span class="required">*</span></label>
                        <input type="text" class="form-control" tabindex="4" formControlName="mobileNumberExtension"/>
                    </div>
                    <div>
                        <label>Phone number<span class="required">*</span></label>
                        <input type="text" class="form-control" tabindex="5" formControlName="mobileNumber"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" tabindex="7" formControlName="whatsAppContactAllowed">
                    <label class="form-check-label" for="exampleCheck1">Allow contact via Whatsapp</label>
                  </div>
            </div>
        </div>
    </div>
</div>
<div class="wizardformpartbox">
    <h4>Visit Address</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Address<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="9" formControlName="visitAddressStreetNo"/>
            </div>
            <div class="form-group">
                <label>Addition</label>
                <input type="text" class="form-control" tabindex="11" formControlName="visitAddressAdditionInfo"/>
            </div>
            <div class="form-group">
                <label>Country<span class="required">*</span></label>
                <select class="form-control" formControlName="visitAddressCountry" tabindex="13">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of lstCountries" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>City<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="15" formControlName="visitAddressCity"/>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Number<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="10" formControlName="visitAddressHouseNo"/>
            </div>
            <div class="form-group">
                <label>Notes</label>
                <input type="text" class="form-control" tabindex="12" formControlName="visitAddressNotes"/>
            </div>
            <div class="form-group">
                <label>Postal code<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="14" formControlName="visitAddressPostalCode"/>
            </div>
            <div class="form-group">
                <label>Elevator</label>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="chkVisitAddressElevatorAvailable" tabindex="16" formControlName="visitAddressIsElevator">
                    <label class="form-check-label" for="chkVisitAddressElevatorAvailable">Available</label>
                  </div>
            </div>
        </div>
    </div>
</div>

<div class="wizardformpartbox">
    <h4>Billing Address</h4>
    <div class="form-group">
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="chkIsBillingAddressSame" tabindex="17" formControlName="isBillingAddressSame">
            <label class="form-check-label" for="chkIsBillingAddressSame">Billing address is the same as the Visit address</label>
          </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Address<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="18" formControlName="billingAddressStreetNo" />
            </div>
            <div class="form-group">
                <label>Addition</label>
                <input type="text" class="form-control" tabindex="20" formControlName="billingAddressAdditionInfo" />
            </div>
            <div class="form-group">
                <label>Country<span class="required">*</span></label>
                <select class="form-control" tabindex="22" formControlName="billingAddressCountry">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of lstCountries" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>City<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="24" formControlName="billingAddressCity" />
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Number<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="19" formControlName="billingAddressHouseNo" />
            </div>
            <div class="form-group">
                <label>Notes</label>
                <input type="text" class="form-control" tabindex="21" formControlName="billingAddressNotes" />
            </div>
            <div class="form-group">
                <label>Postal code<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="23" formControlName="billingAddressPostalCode" />
            </div>
        </div>
    </div>
</div>

</div>