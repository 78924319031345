<div class="wizardinfosection" *ngIf="technicianObj">
    <div class="wizardinfobox">
        <h3>Technician information</h3>
        <h4>Contact information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Name:</label>
                    <label class="content">{{technicianObj.firstName}} {{technicianObj.lastName}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Phone:</label>
                    <label class="content">{{technicianObj.mobileNumberExtension}} {{technicianObj.mobileNumber}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Whatsapp:</label>
                    <label class="content">{{technicianObj.whatsAppContactAllowed}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Organization:</label>
                    <label class="content">{{technicianObj.organization}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">E-mail:</label>
                    <label class="content">{{technicianObj.email}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Role:</label>
                    <label class="content">{{technicianObj.role}}</label>
                </div>
            </div>
        </div>
        <h4>Address information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Address:</label>
                    <label class="content">{{technicianObj.addressStreet}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{technicianObj.addressNotes}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">City:</label>
                    <label class="content">{{technicianObj.addressCity}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Country:</label>
                    <label class="content">{{technicianObj.addressCountry}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Number:</label>
                    <label class="content">{{technicianObj.addressHouseNo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Addition:</label>
                    <label class="content">{{technicianObj.addressAdditionInfo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Postal cpde:</label>
                    <label class="content">{{technicianObj.addressPostalCode}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardinfobox">
        <h3>Skills</h3>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Languages:</label>
                    <label class="content">{{technicianObj.skillLanguages}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Brand expertise:</label>
                    <label class="content">{{technicianObj.skillBrands}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Physical resilience:</label>
                    <label class="content">{{technicianObj.skillPhysicalResilience}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Certifications:</label>
                    <label class="content">{{technicianObj.skillCertifications}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Appliances expertise:</label>
                    <label class="content">{{technicianObj.skillAppliances}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Rating:</label>
                    <label class="content">4.3 / 5.0</label>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardinfobox">
        <h3>Personal profile</h3>
        <p>{{technicianObj.personalProfile}}</p>
    </div>
    <div class="wizardinfobox">
        <h3>Notes</h3>
        <p>{{technicianObj.notes}}</p>
    </div>
</div>