<div class="dashboardbox">
    <div class="row">
        <div class="col-sm-12">
            <div class="dbfiltersbox">
                <div class="dbfilteritem">
                    <!-- <button class="icobtn"><span class="icofilter"></span></button> -->
                </div>
                <div class="dbfilteritem" [class.selected]="mode == 'daily'" (click)="selectMode('daily')">
                    <a>Daily</a>
                </div>
                <div class="dbfilteritem" [class.selected]="mode == 'week'" (click)="selectMode('week')">
                    <a>Weekly</a>
                </div>
                <div class="dbfilteritem" [class.selected]="mode == 'month'" (click)="selectMode('month')">
                    <a>Monthly</a>
                </div>
                <div class="dbfilteritem" [class.selected]="mode == 'year'" (click)="selectMode('year')">
                    <a>Yearly</a>
                </div>
                <div class="dbfilteritem" [class.selected]="mode == 'range'" (click)="selectMode('range')">
                    <a>Range</a>
                </div>
                <div class="dbfiltertbcontainer" *ngIf="mode == 'range'" [formGroup]="formDateRange">
                    <input type="text" ngbDatepicker class="form-control tbmin" #dateFrom="ngbDatepicker" (click)="dateFrom.toggle()" formControlName="dateFrom" />
                    <input type="text" ngbDatepicker class="form-control tbmin" #dateTo="ngbDatepicker" (click)="dateTo.toggle()" formControlName="dateTo"/>
                    <button class="btnprimary btnsearch" [disabled]="!formDateRange.valid" (click)="filterDateRange()">Filter</button>
                </div>
                <div class="dbspdllbox" *ngIf="ccId">
                    <span class="ddlcaption">Service provider:</span>
                    <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                        <button ngbDropdownToggle class="ddlbtn">{{getSelectedSP()}}</button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <button ngbDropdownItem (click)="setServiceProvider(null)">All service providers</button>
                          <button ngbDropdownItem *ngFor="let item of lstSP" (click)="setServiceProvider(item)">{{item.title}}</button>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="margin:0;">
        <div class="col-sm-8">
            <div class="graphbox">
                <div class="headingcontainer">
                    <h3>Orders average</h3>
                    <div>
                        <span class="filtercaption">Order type:</span>
                        <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                            <button ngbDropdownToggle class="filterdropdown">{{statusType | orderstatus}}</button>
                            <div class="dropdown-menu" ngbDropdownMenu>
                              <button ngbDropdownItem *ngFor="let item of lstStatusTypes" (click)="setStatusType(item.value)">{{item.text | orderstatus}}</button>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="graphbody">
                    <fusioncharts width="100%" height="250" [type]="dateRangeChartType" [dataSource]="dateRangeDS"></fusioncharts>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="graphbox">
                <h3>Order status</h3>
                <div class="graphbody">
                    <fusioncharts width="100%" height="250" type="bar2d" [dataSource]="totalCountDS"></fusioncharts>
                </div>
            </div>
        </div>
    </div>
    <div class="row dashboardrow" *ngIf="formulaCountObj">
        <div class="col-sm-3">
            <div class="dataminibox">
                <div class="icobox">
                    <span class="icoclock"></span>
                </div>
                <div class="captionbox">
                    <h1>{{formulaCountObj.reactionTime ? formulaCountObj.reactionTime.toFixed(1) : "-"}} <span>minutes</span></h1>
                    <div class="subtxt">Reaction time <span class="fa fa fa-info-circle"></span></div>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="dataminibox">
                <div class="icobox">
                    <span class="icocalendar"></span>
                </div>
                <div class="captionbox">
                    <h1>{{formulaCountObj.fixTime ? formulaCountObj.fixTime.toFixed(1) : "-"}} <span>days</span></h1>
                    <p class="subtxt">Fix time <span class="fa fa fa-info-circle"></span></p>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="dataminibox">
                <div class="icobox">
                    <span class="icotools"></span>
                </div>
                <div class="captionbox">
                    <h1>{{repairPercent()}} <span>repairs</span></h1>
                    <p class="subtxt">First time fix rate <span class="fa fa fa-info-circle"></span></p>
                </div>
            </div>
        </div>
        <div class="col-sm-3">
            <div class="dataminibox">
                <div class="icobox">
                    <span class="icosmiley"></span>
                </div>
                <div class="captionbox">
                    <ng-template #t let-fill="fill">
                        <span class="star" [class.full]="fill === 100">
                          <span class="half" [style.width.%]="fill">★</span>★
                        </span>
                      </ng-template>
                    <ngb-rating [(rate)]="formulaCountObj.avgFeedbackCount" [readonly]="true"  [starTemplate]="t" [max]="5"></ngb-rating>
                    <p class="subtxt">Customers statisfaction <span class="fa fa fa-info-circle"></span></p>
                </div>
            </div>
        </div>        
    </div>
    <!-- <div class="row dashboardrow">
        <div class="col-sm-12">
            <div class="graphbox">
                <h3>Recent activity</h3>
                <div class="recentactivityrow">
                    <p><span class="fa fa-list"></span>On-site order rescheduled (ID: FF-BE1-2020-A01-X56)</p>
                    <p class="dt">11:00 am</p>
                </div>
                <div class="recentactivityrow">
                    <p><span class="fa fa-list"></span>Work report available (ID: FF-BE1-2020-A01-X56) </p>
                    <p class="dt">10:30 pm</p>
                </div>
                <div class="recentactivityrow">
                    <p><span class="fa fa-list"></span>On-site order rescheduled (ID: FF-BE1-2020-A01-X56)</p>
                    <p class="dt">11:00 am</p>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="graphbox">
                <h3>Service provider status</h3>
                <div class="sprow">
                    <div class="spicocell">
                        <div class="icobox">
                            <img src="./../../assets/images/icoprofile.png" />
                        </div>
                        <div class="icotxt">
                            <h4>Miele Mariman</h4>
                            <div class="icotxtsubinfo"><span class="fa fa-map-marker"></span>Berlin</div>
                        </div>
                    </div>
                    <div class="spbadgecell">
                        <div class="bdgbox cancelbdg">ST Unavailable</div>
                    </div>
                </div>
                <div class="sprow">
                    <div class="spicocell">
                        <div class="icobox">
                            <img src="./../../assets/images/icoprofile.png" />
                        </div>
                        <div class="icotxt">
                            <h4>Miele Mariman</h4>
                            <div class="icotxtsubinfo"><span class="fa fa-map-marker"></span>Berlin</div>
                        </div>
                    </div>
                    <div class="spbadgecell">
                        <div class="bdgbox cancelbdg">ST Unavailable</div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>