import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CollectionUtil } from 'src/app/shared/ccc/collections';
import { Util } from 'src/app/shared/ccc/util';
import { DropDownObj } from 'src/app/shared/models/commonobj';

@Component({
  selector: 'appliance-data-step',
  templateUrl: './appliance-data-step.component.html',
  styleUrls: ['./appliance-data-step.component.css']
})
export class ApplianceDataStepComponent implements OnInit {

  @Input()
  public orderId: number = 0;
  

  @Input()
  public form:FormGroup = null;

  public frmPurchaseDate: FormControl=new FormControl();

  public isWarranty:boolean = false;

  readonly lstAppliances: DropDownObj[] = CollectionUtil.lstAppliances;
  readonly lstBrands: DropDownObj[] = CollectionUtil.lstBrands;
  readonly lstUsageLevels: DropDownObj[] = CollectionUtil.lstUsageLevel;
  readonly lstErrorCodes: DropDownObj[] = CollectionUtil.lstErrorCodes;

  constructor(public fb:FormBuilder) { 
  }

  ngOnInit() {
    this.isWarranty = this.form.controls['applianceIsWarranty'].value;
    this.form.controls['applianceIsWarranty'].valueChanges.subscribe(isWarranty => {
      this.isWarranty = isWarranty;
    });

    this.frmPurchaseDate.valueChanges.subscribe(res=>{
      this.form.controls["appliancePurchaseDate"].setValue(Util.NgbDateToString(res));
    });
  }

}
