<div class="loginpage">
    <h1>Sign up</h1>
    <h2>Welcome to FixFirst</h2>
    <form [formGroup]="form" class="form-horizontal my-4" appIdentityRevealed>
        <div class="form-item">
          <label class="ctrcaption" for="username">Create password</label>
          <div class="">
            <input class="ctrlitem" type="password" formControlName="password" />
          </div>
        </div>
        <div class="loginbtn">
            <button type="submit" class="btnprimary btndim" (click)="signup()">Sign up</button>
        </div>
    </form>
</div>