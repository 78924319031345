import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DayObj, SlotObj } from 'src/app/shared/models/calendarobj';
import * as moment from 'moment';
import { ModelService } from 'src/app/shared/services/model-service';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { DataService } from 'src/app/shared/services/data.service';
import { SPCalendarCellVM } from 'src/app/shared/models/serviceprovider';
import { TechnicianPlainCalendarCellVM } from 'src/app/shared/models/technician';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';

@Component({
  selector: 'app-available-ts',
  templateUrl: './available-ts.component.html',
  styleUrls: ['./available-ts.component.css']
})
export class AvailableTSComponent implements OnInit {

  @Input()
  public dayObj: DayObj = null;

  public selectedTS: SlotObj= null;
  
  constructor(public activeModal: NgbActiveModal, private modelService: ModelService, private dataService: DataService) { }

  ngOnInit() {
  }

  closePopup(){
    this.activeModal.close(
      {
        status: ModalConfirmationStatus.CLOSED
      }
    );
  }
  selectTS(tsObj: SlotObj)
  {
    this.selectedTS = tsObj;
  }

  fetchDetails()
  {
      let dayTitle = moment(this.dayObj.dayTitle).format('YYYY-MM-DD');
      let loginResponseObj: LoginResponseObj = this.modelService.getLoginDetail();

     switch(loginResponseObj.userType)
     {
       case 'CC':
        let ccId: number = loginResponseObj.userId;
        this.dataService.getSPsAvailability(ccId, dayTitle, this.selectedTS.tsFrom, this.selectedTS.tsTo).subscribe(res=>{
          
          this.activeModal.close({
            mode: ModalConfirmationStatus.CONFIRMED,
            tsObj: this.selectedTS,
            dataArr: res
          });

          //this.onLstSPs.emit(calendarCellVM);
        });
         break;
        case 'SP':
          let spId: number = loginResponseObj.userId;
          this.dataService.getAllAvailableTechnicians(spId, dayTitle, this.selectedTS.tsFrom, this.selectedTS.tsTo).subscribe(res=>{

            this.activeModal.close({
              mode: ModalConfirmationStatus.CONFIRMED,
              tsObj: this.selectedTS,
              dataArr: res
            });
     
            //this.onLstTechnicians.emit(calendarCellVM);
          });
          break;
     }
  }

}
