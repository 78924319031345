export class DashboardDateRangeArg
{
    public ccId: number;
    public spId: number;
    public selectedspId: number;
    public mode: string;
    public orderStatus: string;
    public dateFrom: string = "";
    public dateTo: string = "";
}
export class DasboardDateRangeObj
{
    public createdDate: any;
    public totalOrders: any;
}
export class DashboardTotalCountArg
{
    public ccId: number;
    public spId: number;
    public selectedspId: number;
    public mode: string;
    public dateFrom: string = "";
    public dateTo: string = "";
}
export class DashboardFormulaCountArg
{
    public ccId: number;
    public spId: number;
    public selectedspId: number;
}
export class DashboardTotalCountObj
{
    public newCount: number;
    public scheduledCount: number;
    public reScheduledCount: number;
    public inProgressCount: number;
    public completedCount: number;
    public cancelledCount: number;
}
export class DashboardFormulaCountObj
{
    public reactionTime: number;
    public fixTime: number;
    public totalCompletedOrders: number;
    public totalRescheduledOrders: number;
    public avgFeedbackCount: number;
}

export class GraphObj{
    constructor(label: string, value: number, color: string){
      this.label = label;
      this.value = value;
      this.color = color;
    }
    public label: string;
    public value: number;
    public color: string;
  }