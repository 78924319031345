export enum ModalConfirmationStatus { CLOSED, CONFIRMED };

export class OrderStatusConstants{
    public static readonly New:string="new";
    public static readonly Schedule:string="schedule";
    public static readonly Reschedule:string="reschedule";
    public static readonly InProgress:string="inprogress";
    public static readonly Complete:string="complete";
    public static readonly Cancel:string="cancel";
}
export class WholeDayConstant{
    public static readonly TSFrom: string = "12:00 am";
    public static readonly TSTo: string = "11:59 pm";
}