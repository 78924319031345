import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewOrderPopupComponent } from 'src/app/orders/view-order-popup/view-order-popup.component';
import { OrderStatusConstants } from 'src/app/shared/ccc/constants';
import { UnreadOrderStatusObj } from 'src/app/shared/models/orderstatus';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.css']
})
export class NotificationsListComponent implements OnInit {

  @Input()
  public lstStatus: UnreadOrderStatusObj[] = [];
  
  @Output() sliderClosed: EventEmitter<any> = new EventEmitter();

  constructor(private dataService: DataService, private ngbModal: NgbModal) { 

  }

  ngOnInit() {
  }

  getBoxColorClass(item: UnreadOrderStatusObj):string
  {
    let colorClass='';
    switch(item.status)
    {
      case OrderStatusConstants.New:
        colorClass = 'neworder';
        break;
      case OrderStatusConstants.Schedule:
        colorClass = 'scheduledorder';
        break;
      case OrderStatusConstants.Reschedule:
        colorClass = 'rescheduledorder';
        break;
      case OrderStatusConstants.Complete:
        colorClass = 'completedorder';
        break;
      case OrderStatusConstants.InProgress:
        colorClass = 'inprogressorder';
        break;
      case OrderStatusConstants.Cancel:
        colorClass = 'cancelledorder';
        break;
    }
    return colorClass;
  }
  getOrderTitle(status: string):string
  {
    let title:string = '';
    switch(status)
    {
      case OrderStatusConstants.New:
        title = 'New order';
        break;
      case OrderStatusConstants.Schedule:
        title = 'Order scheduled';
        break;
      case OrderStatusConstants.Reschedule:
        title = 'Order rescheduled';
        break;
      case OrderStatusConstants.Complete:
        title = 'Order completed';
        break;
      case OrderStatusConstants.InProgress:
        title = 'Order in progress';
        break;
      case OrderStatusConstants.Cancel:
        title = 'Order canceled';
        break;
    }
    return title;
  }
  closeNotification(e: any, item: UnreadOrderStatusObj)
  {
    e.stopPropagation();
    this.dataService.readOrderStatus(item.orderStatusId).subscribe(res=>{
      item.isRead = true;
    });
  }
  readNotification(item: UnreadOrderStatusObj)
  {
    console.log(item);
    this.dataService.readOrderStatus(item.orderStatusId).subscribe(res=>{
      item.isRead = true;
      const modalRef = this.ngbModal.open(ViewOrderPopupComponent,{ backdrop: 'static', centered: true, size:'lg' });
      modalRef.componentInstance.orderId = item.orderId;
      modalRef.componentInstance.loadRecords();
    });
  }

  close()
  {
    this.sliderClosed.emit(null);
  }

}
