import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'visit-type-step',
  templateUrl: './visit-type-step.component.html',
  styleUrls: ['./visit-type-step.component.css']
})
export class VisitTypeStepComponent implements OnInit {

  @Input()
  public orderId: number = 0;
  
  @Input()
  public form:FormGroup = null;

  constructor(private toastr: ToastrManager) {
  }

  ngOnInit() {
  }

  notImplementedMsg():void
  {
    this.toastr.infoToastr('This functionality is not implemented yet', 'Not implemented');
  }

}
