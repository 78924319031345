import { Injectable } from '@angular/core';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {map, mergeMap, toArray} from 'rxjs/operators';

import { Observable } from 'rxjs';
import { DropDownObj2 } from '../models/commonobj';
import { DataService } from '../services/data.service';
import { ModelService } from '../services/model-service';

@Injectable({
  providedIn: 'root'
})
export class SPDDLResolver implements Resolve<DropDownObj2[]> {

  constructor(private dataService: DataService, private modelService: ModelService) { }
  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot):Observable<DropDownObj2[]>{

    let loginResponseObj = this.modelService.getLoginDetail();
    if(loginResponseObj.userType == 'CC')
    {
        return this.dataService.getSPDDL(loginResponseObj.userId).pipe(mergeMap(x=>x), map(x=>{
            let ddObj:DropDownObj2 = new DropDownObj2();
            ddObj.id = x.serviceProviderId;
            ddObj.title = `${x.organization}`;
            return ddObj;
        }), toArray());
    }
  }
}
