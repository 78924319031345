
<div class="listingactionbox">
    <div class="filterbox">
        <div class="filterboxitem">
            <!-- <button class="icobtn"><span class="icofilter"></span></button> -->
        </div>
        <div class="filterboxitem">
            <button class="icobtn" (click)="deleteSPs()"><span class="fa fa-trash"></span></button>
        </div>
    </div>
    <div class="listingactionbtn">
        <button class="actiobtn" (click)="newSP()"><span class="fa fa-plus"></span> New service provider</button>
    </div>
</div>
<table class="table listingtable" width="100%">
    <thead>
        <tr>
            <th>
                <input type="checkbox" />
            </th>
            <th>Company</th>
            <th>City</th>
            <th>Country</th>
            <th>Contact person</th>
            <th>Role</th>
            <th>Website</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of lstSPs" (click)="showDetailsPopup(item.serviceProviderId)">
            <td><input type="checkbox" (click)="rowChkClicked($event)"/></td>
            <td>{{item.company}}</td>
            <td>{{item.city}}</td>
            <td>{{item.country}}</td>
            <td>{{item.firstName}} {{item.lastName}}</td>
            <td>{{item.role}}</td>
            <td>{{item.website}}</td>
            <td>
                <button class="icobtn" (click)="showContactNumber($event)"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
    </tbody>
</table>