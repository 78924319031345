<div class="wizardpage">
    <div class="wizardhead">
        <h2>Order summary</h2>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="wizardbodypopup customscrollbar" *ngIf="orderObj">
        <div class="wizardinfobox" >
            <div class="row0">
                <div class="leftcol">
                    <div class="icotoolbox">
                        <div class="icotool"></div>
                        <div class="tooltxt">
                            <h3>{{orderObj.visitType}} <span class="fa fa-info-circle infocol" (click)="showHistoryPopup()"></span></h3>
                            <p class="pmain">FixFirst</p>
                        </div>
                    </div>
                    <div class="leftinfocellsmall">{{orderObj.applianceType}}</div>
                    <div class="leftinfocell"><span class="fa fa-calendar"></span>{{orderObj.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} @ {{orderObj.scheduleTime}}</div>
                    <div class="leftinfocell"><span class="fa fa-map-marker"></span>{{orderObj.customerVisitAddressPostalCode}} {{orderObj.customerVisitAddressCountry}}</div>
                </div>
                <div>
                    <div class="rightinfocell"><span class="caption">Request date:</span> {{orderObj.createdDate | customdate:'DD.MM.YYYY hh:mm a'}}</div>
                    <div class="rightinfocell"><span class="caption">Service technician:</span> <span [class.pending]="!orderObj.technicianId">{{getTechnicianName()}}</span></div>
                    <div class="rightinfocell">
                        <span class="bdgbox" [statusbg]="orderObj.orderStatus">{{orderObj.orderStatus | orderstatus}}</span>
                        <div class="clearfix"></div>
                    </div>
                    <div class="rightinfocell statusdesc" *ngIf="orderObj.orderStatusReason">
                        <span class="bdgbox" [statusbg]="orderObj.orderStatus">{{orderObj.orderStatusReason}}</span>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardinfobox">
            <h3>Order information</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">FixFirst-ID:</label>
                        <label class="content">{{orderObj.fixtFirstID}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Service Provider:</label>
                        <label class="content">{{orderObj.serviceProviderOrganization}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Date:</label>
                        <label class="content">{{orderObj.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} ({{orderObj.scheduleTime}})</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Order-ID:</label>
                        <label class="content">{{orderObj.fixFirstOrderID}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Technician:</label>
                        <label class="content" [class.pending]="!orderObj.technicianId">
                            <span>{{getTechnicianName()}}</span>
                        </label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Visit type:</label>
                        <label class="content">{{orderObj.visitType}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardinfobox">
            <h3>Customer information</h3>
            <h4>Contact information</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Customer:</label>
                        <label class="content">{{orderObj.customerFirstName}} {{orderObj.customerLastName}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Email:</label>
                        <label class="content">{{orderObj.customerEmail}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Languages:</label>
                        <label class="content">{{orderObj.customerLanguages}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{orderObj.customerAdditionalNotes}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Mobile:</label>
                        <label class="content">{{orderObj.customerMobileNumberExtension}} {{orderObj.customerMobileNumber}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Whatsapp:</label>
                        <label class="content">{{orderObj.customerWhatsAppContactAllowed}}</label>
                    </div>
                </div>
            </div>
            <h4>Visit address</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Address:</label>
                        <label class="content">{{orderObj.customerVisitAddressHouseNo}},{{orderObj.customerVisitAddressStreetNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{orderObj.customerVisitAddressNotes}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">City:</label>
                        <label class="content">{{orderObj.customerVisitAddressCity}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Country:</label>
                        <label class="content">{{orderObj.customerVisitAddressCountry}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Addition:</label>
                        <label class="content">{{orderObj.customerVisitAddressAdditionInfo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Elevator:</label>
                        <label class="content">{{orderObj.customerVisitAddressIsElevator}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Postal code:</label>
                        <label class="content">{{orderObj.customerVisitAddressPostalCode}}</label>
                    </div>
                </div>
            </div>
            <h4>Billing address</h4>
            <p *ngIf="orderObj.customerIsBillingAddressSame">Billing address is the same as the Visit address </p>
            <div class="row" *ngIf="!orderObj.customerIsBillingAddressSame">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Address:</label>
                        <label class="content">{{orderObj.customerBillingAddressHouseNo}},{{orderObj.customerBillingAddressStreetNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{orderObj.customerBillingAddressNotes}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">City:</label>
                        <label class="content">{{orderObj.customerBillingAddressCity}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Addition:</label>
                        <label class="content">{{orderObj.customerBillingAddressAdditionInfo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Postal code:</label>
                        <label class="content">{{orderObj.customerBillingAddressPostalCode}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Country:</label>
                        <label class="content">{{orderObj.customerBillingAddressCountry}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardinfobox">
            <h3>Appliance &amp; error data</h3>
            <h4>Appliance data</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Appliance:</label>
                        <label class="content">{{orderObj.applianceType}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Model-Nr:</label>
                        <label class="content">{{orderObj.applianceModelNumber}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Index-Nr:</label>
                        <label class="content">{{orderObj.applianceIndexNumber}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Contract-Nr:</label>
                        <label class="content">{{orderObj.applianceContractNumber}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Brand:</label>
                        <label class="content">{{orderObj.applianceBrand}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Serial-Nr:</label>
                        <label class="content">{{orderObj.applianceSerialNumber}}/01</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Age (Years):</label>
                        <label class="content">{{orderObj.applianceAge}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Warranty:</label>
                        <label class="content">{{orderObj.applianceIsWarranty}}</label>
                    </div>
                </div>
            </div>
            <h4 *ngIf="orderObj && orderObj.applianceIsWarranty">Warranty information</h4>
            <div class="row" *ngIf="orderObj && orderObj.applianceIsWarranty">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Warranty contract number:</label>
                        <label class="content">{{orderObj.applianceWarrantyContractNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{orderObj.applianceWarrantyNotes}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Details:</label>
                        <label class="content">{{orderObj.applianceWarrantyDetails}}</label>
                    </div>
                </div>
            </div>
            <h4>Additional details</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Installation:</label>
                        <label class="content">{{orderObj.applianceInstallationDetail}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Purchase date:</label>
                        <label class="content" *ngIf="orderObj.appliancePurchaseDate">{{orderObj.appliancePurchaseDate | customdate:'DD.MM.YYYY'}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Level of usage:</label>
                        <label class="content">{{orderObj.applianceLevelOfUsage}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Price new (€):</label>
                        <label class="content">{{orderObj.applianceNewPrice}}</label>
                    </div>
                </div>
            </div>
            <h4>Error data</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Problem:</label>
                        <label class="content">{{orderObj.errorTitle}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Details:</label>
                        <label class="content">{{orderObj.errorDetails}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Error code:</label>
                        <label class="content">{{orderObj.errorCode}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Note:</label>
                        <label class="content">{{orderObj.errorNotes}}</label>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="wizardinfobox">
            <h3>Attachments</h3>
        </div> -->
        <div class="wizardinfobox">
            <h3>Recommendations</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Repair:</label>
                        <label class="content">{{orderObj.recommendationRepair}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Other:</label>
                        <label class="content">{{orderObj.recommendationOther}}</label>
                    </div>
                </div>
            </div>
            <h4>Spare parts and materials suggested</h4>
            <table class="table table-border table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th>Item Name</th>
                        <th>Code</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of lstSpareParts; let i=index">
                        <td>{{i + 1}}</td>
                        <td>{{item.itemName}}</td>
                        <td>{{item.itemCode}}</td>
                        <td>{{item.itemQuantity}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="wizardinfobox">
            <h3>Comments</h3>
            <div class="wizardchatbox">
                <div class="wizardchatitem" *ngFor="let item of lstComments">
                    <div class="icobox">
                        <img src="../../../../assets/images/icoprofile.png" />
                    </div>
                    <div class="chatcontentbox">
                        <div class="chatcontent">
                            <h3>{{getCommentItemName(item)}}</h3>
                            <p>{{item.commentText}}</p>
                        </div>
                        <div class="chatdate">{{item.commentDate | customdate:'DD.MM.YYYY hh:mm a'}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardfooter">
        <div></div>
        <div>
            <button class="btnfooter" (click)="editOrder()" *ngIf="orderObj && orderObj.orderStatus != 'complete'"><span class="fa fa-pencil"></span>Edit</button>
            <button class="btnfooter" (click)="showWorkReport()" *ngIf="orderObj && orderObj.orderStatus == 'complete'"><span class="fa fa-pencil"></span>Work report</button>
        </div>
    </div>
</div>