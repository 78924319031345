<div class="loginpage">
    <a routerLink="/login" class="fixfirstlogo"></a>
    <h1>Change Password</h1>
    <!-- <h2>Please change password</h2> -->
    <form [formGroup]="form" class="form-horizontal my-4" appIdentityRevealed>
      <div class="form-item">
        <label class="ctrcaption" for="username">Email</label>
        <div class="">
          <input class="ctrlitem" type="text" placeholder="Email" disabled="true" formControlName="email" />
        </div>
      </div>
        <div class="form-item">
          <label class="ctrcaption">Old password</label>
          <div class="">
            <input class="ctrlitem" type="password" formControlName="oldPassword" />
          </div>
        </div>
        <div class="form-item">
            <label class="ctrcaption">New password</label>
            <div class="">
              <input class="ctrlitem" type="password" formControlName="newPassword" />
            </div>
          </div>
        <div class="form-item">
          <label class="ctrcaption">Confirm password</label>
          <div class="">
            <input class="ctrlitem" type="password" formControlName="confirmNewPassword" />
          </div>
        </div>
        <div class="loginbtn">
            <button type="submit" class="btnprimary" [disabled]="!form.valid" (click)="changePassword()">Change password</button>
        </div>
    </form>
</div>