import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';

@Component({
  selector: 'app-set-technician-password-popup',
  templateUrl: './set-technician-password-popup.component.html',
  styleUrls: ['./set-technician-password-popup.component.css']
})
export class SetTechnicianPasswordPopupComponent implements OnInit {

  public frmCtrlPassword: FormControl = new FormControl('',Validators.required);
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  closePopup(){
    this.activeModal.close(
      {
        status: ModalConfirmationStatus.CLOSED
      }
    );
  }

  savePassword()
  {
    console.log(this.frmCtrlPassword.value);
    this.activeModal.close({
      status: ModalConfirmationStatus.CONFIRMED,
      password: this.frmCtrlPassword.value
    });
  }

}
