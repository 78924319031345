import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { filter, map } from 'rxjs/operators';
import { ModalConfirmationStatus } from '../shared/ccc/constants';
import { LoginResponseObj } from '../shared/models/login';
import { OrderListingArg, OrderListingObj } from '../shared/models/order';
import { DataService } from '../shared/services/data.service';
import { ModelService } from '../shared/services/model-service';
import { UpdateStatusPopupComponent } from './update-status-popup/update-status-popup.component';
import { ViewOrderPopupComponent } from './view-order-popup/view-order-popup.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  
  public ordersStatus: string = '';

  public lstOrders: OrderListingObj[] = [];

  constructor(private ngbModal: NgbModal, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager, private router: Router) { 
    this.modelService.searchTerm$.asObservable().pipe(filter(x=> x && (x.page == "orders")), map(x=>x.searchTerm)).subscribe(searchTxt=>{
      this.loadRecords(searchTxt);
    });
  }

  ngOnInit() {
    this.loadRecords();
  }

  updateStatus(e: any, orderId: number){
    e.stopPropagation();
    const modalRef = this.ngbModal.open(UpdateStatusPopupComponent,{ backdrop: 'static', centered: true});
    modalRef.componentInstance.orderId = orderId;
    modalRef.componentInstance.loadRecords();

    modalRef.result.then((data: ModalConfirmationStatus) => {
      if (data == ModalConfirmationStatus.CONFIRMED) {
        this.loadRecords();
      }
    }, (reason) => {
    });
  }
  newOrder(){
    this.router.navigate(['/home/orders/new']);
  }
  
  showDetailsPopup(orderId: number)
  {
    const modalRef = this.ngbModal.open(ViewOrderPopupComponent,{ backdrop: 'static', centered: true, size:'lg' });
    modalRef.componentInstance.orderId = orderId;
    modalRef.componentInstance.loadRecords();
  }

  filterStatus(status: string){

    this.ordersStatus = status;
    this.loadRecords();
  }

  public loadRecords(searchTerm: string = ""){
    let loginResponse:LoginResponseObj = this.modelService.getLoginDetail();
    
    let arg: OrderListingArg=new OrderListingArg();
    arg.role = loginResponse.userType;
    arg.userId = loginResponse.userId;
    arg.status = this.ordersStatus;
    arg.searchTerm = searchTerm;

    this.dataService.getOrderListing(arg).subscribe(res=>{
      this.lstOrders = res;
    });
  }

}
