<h3>Booking details</h3>
<div class="row">
    <div class="col-sm-8">
      <div class="databox">
        <div class="row">
          <div class="col-sm-12">
            <div class="calendarbox">
              <div class="calendarheader">
                <div class="calendarheaderrow1">
                  <div class="monthTitle">{{getMonthTitle()}}</div>
                  <div class="calendarnav">
                    <a class="fa fa-chevron-left" (click)="prevMonth()"></a>
                    <a class="fa fa fa-chevron-right" (click)="nextMonth()"></a>
                  </div>
                </div>
                <div class="calendarheaderrow2">
                  <div class="calendarheadcell">Su</div>
                  <div class="calendarheadcell">Mo</div>
                  <div class="calendarheadcell">Tu</div>
                  <div class="calendarheadcell">We</div>
                  <div class="calendarheadcell">Th</div>
                  <div class="calendarheadcell">Fr</div>
                  <div class="calendarheadcell">Sa</div>
                </div>
              </div>
              <div class="calendarbody">
                <div class="calendarrow">
                  <calendar-cell (onDaySelected)="selectDay($event)" *ngFor="let number of [0,1,2,3,4,5,6]" [dayObj]="days[number]"></calendar-cell>
                </div>
                <div class="calendarrow">
                  <calendar-cell (onDaySelected)="selectDay($event)" *ngFor="let number of [7,8,9,10,11,12,13]" [dayObj]="days[number]"></calendar-cell>
                </div>
                <div class="calendarrow">
                  <calendar-cell (onDaySelected)="selectDay($event)" *ngFor="let number of [14,15,16,17,18,19,20]" [dayObj]="days[number]"></calendar-cell>
                </div>
                <div class="calendarrow">
                  <calendar-cell (onDaySelected)="selectDay($event)" *ngFor="let number of [21,22,23,24,25,26,27]" [dayObj]="days[number]"></calendar-cell>
                </div>
                <div class="calendarrow">
                  <calendar-cell (onDaySelected)="selectDay($event)" *ngFor="let number of [28,29,30,31,32,33,34]" [dayObj]="days[number]"></calendar-cell>
                </div>
                <div class="calendarrow">
                  <calendar-cell (onDaySelected)="selectDay($event)" *ngFor="let number of [35,36,37,38,39,40,41]" [dayObj]="days[number]"></calendar-cell>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="calendarrightselectionpanel" *ngIf="selectedDay">
        <div *ngIf="mode == 'CC'">
          <div class="headingbox">
            <div class="datecont">
              <div class="datebox">{{selectedDay.day | customdate: 'DD.MM.YYYY'}}</div>
              <div class="tsbox" *ngIf="selectedTS">({{selectedTS.tsFrom}} - {{selectedTS.tsTo}})</div>
            </div>
            <h3>Available service providers</h3>
          </div>
          <h3>
          </h3>
          <div class="form-group" *ngFor="let item of lstSPs; let i = index;">
            <div class="form-check borderedrbbox" [class.selected]="lstSPs[i].isAvailable">
                <label class="form-check-label" [for]="i">{{item.organization}}</label>
                <input [id]="i" name="rdSPs" type="radio" ng-model="item.isAvailable" (click)="selectSP(item)" />
            </div>
          </div>
          <div class="btnbox">
            <button class="btnprimary" (click)="assignServiceProvider()">Assign service provider</button>
          </div>
        </div>
        <div *ngIf="mode == 'SP'">
          <h3>
            <div>Available technicians</div>
            <div class="selecteddaycol">{{selectedDay.day | customdate: 'DD.MM.YYYY'}}</div>
          </h3>
          <div class="form-group" *ngFor="let item of lstTechnicians; let i = index;">
            <div class="form-check borderedrbbox" [class.selected]="lstTechnicians[i].isAvailable">
                <label class="form-check-label" [for]="i">{{item.firstName}} {{item.lastName}}</label>
                <input [id]="i" name="rdTechnicians" type="radio" ng-model="item.isAvailable" (click)="selectTechnician(item)" />
            </div>
          </div>
          <div class="btnbox">
            <button class="btnprimary" (click)="assignTechnician();">Assign technician</button>
          </div>
        </div>
      </div>
    </div>
</div>