import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { CustomerObj } from 'src/app/shared/models/customer';
import { OrderCommentObj, OrderObj, OrderSparePartSuggestionObj } from 'src/app/shared/models/order';
import { DataService } from 'src/app/shared/services/data.service';
import { OrderStatusHistoryPopupComponent } from './order-status-history-popup/order-status-history-popup.component';
import { WorkReportPopupComponent } from './work-report-popup/work-report-popup.component';

@Component({
  selector: 'app-view-order-popup',
  templateUrl: './view-order-popup.component.html',
  styleUrls: ['./view-order-popup.component.css']
})
export class ViewOrderPopupComponent implements OnInit {

  @Input()
  public orderId: number = 0;
  
  public orderObj: OrderObj = null;
  public customerObj: CustomerObj = null;
  public lstSpareParts: OrderSparePartSuggestionObj[]=[];
  public lstComments: OrderCommentObj[] = [];

  constructor(public router: Router, public activeModal: NgbActiveModal, private dataService: DataService, private ngbModal: NgbModal) { }

  ngOnInit() {
  }
  closePopup(){
    this.activeModal.close();
  }

  editOrder(){
    this.closePopup();
    this.router.navigate(['/home/orders/edit/',this.orderId]);
  }
  showWorkReport()
  {
    const modalRef = this.ngbModal.open(WorkReportPopupComponent,{ backdrop: 'static', centered: true, size:'lg' });
    modalRef.componentInstance.orderId = this.orderId;
    modalRef.componentInstance.loadRecords();
  }

  getTechnicianName():string
  {
    let technicianName: string='';
    if(this.orderObj.technicianId)
    {
      technicianName=this.orderObj.technicianName;
    }
    else
    {
      technicianName='[pending]';
    }
    return technicianName;
  }

  getCommentItemName(item: OrderCommentObj):string
  {
    let name='';
    switch(item.commentBy)
    {
      case 'SP':
        name = item.spName;
        break;
      case 'TECHNICIAN':
        name = item.technicianName;
        break;
      case 'CUSTOMER':
        name = item.customerName;
        break;
    }
    return name;
  }
  
  showHistoryPopup()
  {
    const modalRef = this.ngbModal.open(OrderStatusHistoryPopupComponent,{ backdrop: 'static', centered: true, size:'sm' });
    modalRef.componentInstance.orderId = this.orderObj.orderId;
    modalRef.componentInstance.loadRecords();
  }

  public loadRecords(){
    let req1$ = this.dataService.getOrderById(this.orderId);
    let req2$ = this.dataService.getOrderSpareParts(this.orderId);
    let req3$ = this.dataService.getOrderComments(this.orderId);

    forkJoin([req1$,req2$, req3$]).subscribe(([order, lstSparePartsArr, lstComments])=>{

      this.orderObj = order;
      this.lstSpareParts = lstSparePartsArr;
      this.lstComments = lstComments;

      if(order.customerId)
      {
        this.dataService.getCustomerById(order.customerId).subscribe(customer=>{
          this.customerObj = customer;
        });
      }
    });
  }

}
