<div class="filterbox">
    <div class="filterboxitem">
        <button class="icobtn"><span class="icofilter"></span></button>
    </div>
    <div class="filterboxitem">
        <button class="icobtn"><span class="fa fa-trash"></span></button>
    </div>
</div>
<table class="table listingtable" width="100%">
    <thead>
        <tr>
            <th>Company</th>
            <th>City</th>
            <th>Country</th>
            <th>Contact person</th>
            <th>Role</th>
            <th style="width:150px;">Website</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Miele Mariman</td>
            <td>Sint-Niklaas</td>
            <td>Belgium</td>
            <td>Mr. Mariman</td>
            <td>Owner</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
        <tr>
            <td>Miele Mariman</td>
            <td>Sint-Niklaas</td>
            <td>Belgium</td>
            <td>Mr. Mariman</td>
            <td>Owner</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
        <tr>
            <td>Miele Mariman</td>
            <td>Sint-Niklaas</td>
            <td>Belgium</td>
            <td>Mr. Mariman</td>
            <td>Owner</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
        <tr>
            <td>Miele Mariman</td>
            <td>Sint-Niklaas</td>
            <td>Belgium</td>
            <td>Mr. Mariman</td>
            <td>Owner</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
        <tr>
            <td>Miele Mariman</td>
            <td>Sint-Niklaas</td>
            <td>Belgium</td>
            <td>Mr. Mariman</td>
            <td>Owner</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
        <tr>
            <td>Miele Mariman</td>
            <td>Sint-Niklaas</td>
            <td>Belgium</td>
            <td>Mr. Mariman</td>
            <td>Owner</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
        <tr>
            <td>ServiLux Belgium</td>
            <td>Hasselt</td>
            <td>Belgium</td>
            <td>Mr. Dumon</td>
            <td>CEO</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
        <tr>
            <td>Miele Mariman</td>
            <td>Sint-Niklaas</td>
            <td>Belgium</td>
            <td>Mr. Mariman</td>
            <td>Owner</td>
            <td><a routerLink=""><span class="fa fa-link"></span></a></td>
            <td>
                <button class="icobtn"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>

    </tbody>
</table>