<div class="sidenavcontent">
    <div class="headbox">
        <h3><span class="fa fa-bell"></span> Notifications</h3>
        <div class="closeico" (click)="close()">
            <span class="fa fa-times"></span>
        </div>
    </div>
    <div class="notificationscontent">
        <div *ngFor = "let item of lstStatus">
            <div class="notificationsbox" (click)="readNotification(item)" [ngClass]="getBoxColorClass(item)" *ngIf = "!item.isRead">
                <div class="headingbox">
                    <h3>{{getOrderTitle(item.status)}}</h3>
                    <div class="closico" (click)="closeNotification($event, item)">
                        <span class="fa fa-times"></span>
                    </div>
                </div>
                <div class="contentbox">
                    <div class="contentrow"><span>Order ID:</span>{{item.fixFirstID}}</div>
                    <div class="contentrow"><span class="fa fa-calendar"></span>{{item.scheduleDate}}@{{item.scheduleTime}}</div>
                    <div class="contentrow"><span class="fa  fa-map-marker"></span> {{item.customerHouseNo}} {{item.customerCity}}</div>
                </div>
            </div>
        </div>
    </div>
</div>