import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'spare-parts-suggestion',
  templateUrl: './spare-parts-suggestion.component.html',
  styleUrls: ['./spare-parts-suggestion.component.css']
})
export class SparePartsSuggestionComponent implements OnInit {

  @Input()
  public serialNo:number;

  @Input()
  public form:FormGroup = null;

  @Output()
  public removeRequest: EventEmitter<number> = new EventEmitter();
  
  constructor() { }

  ngOnInit() {
  }

  removeSparePart(){
    this.removeRequest.emit(this.serialNo - 1);
  }

}
