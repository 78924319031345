import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { OrderStatusConstants } from '../ccc/constants';

@Directive({
  selector: '[statusbg]'
})
export class StatusBgDirective {

  constructor(private el: ElementRef) { 
  }

  @HostBinding('class.newbdg')
  public get isNewStatus(): boolean {
    return this.statusbg == OrderStatusConstants.New;
  }
  @HostBinding('class.schedulebdg')
  public get isScheduledStatus(): boolean {
    return this.statusbg == OrderStatusConstants.Schedule;
  }
  @HostBinding('class.reschedulebdg')
  public get isRescheduledStatus(): boolean {
    return this.statusbg == OrderStatusConstants.Reschedule;
  }
  @HostBinding('class.cancelbdg')
  public get isCancelledStatus(): boolean {
    return this.statusbg == OrderStatusConstants.Cancel;
  }
  @HostBinding('class.inprogressbdg')
  public get isInProgressStatus(): boolean {
    return this.statusbg == OrderStatusConstants.InProgress;
  }
  @HostBinding('class.completedbdg')
  public get isCompletedStatus(): boolean {
    return this.statusbg == OrderStatusConstants.Complete;
  }


  @Input('statusbg') statusbg: string;
}