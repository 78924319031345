<div class="wizardpage">
    <div class="wizardhead">
        <h2>Update status</h2>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="wizardbodypopup customscrollbar">
        <div class="updatestatusbox" *ngIf="currentStatusObj">
        <div class="statustopinfobox">
            <div class="topinfoitem"><span class="fa fa-calendar"></span>{{currentStatusObj.scheduleDate | customdate: 'ddd - MMM DD, YYYY'}}</div>
            <div class="topinfoitem"><span class="fa fa-clock-o"></span>{{currentStatusObj.scheduleTime}}</div>
            <div class="topinfoitem"><span class="fa fa-map-marker"></span>{{currentStatusObj.visitAddressPostalCode}} {{currentStatusObj.visitAddressCity}}</div>
        </div>
        <div class="statusitemsbox">
            <div class="statusitem" [class.selected]="newStatus == 'schedule'" (click)="selectStatus('schedule')"><span class="fa fa-calendar"></span> Scheduled</div>
            <div class="statusitem" [class.selected]="newStatus == 'reschedule'" (click)="selectStatus('reschedule')"><span class="svgico icoreschedule"></span> Rescheduled</div>
            <div class="statusitem" [class.selected]="newStatus == 'inprogress'" (click)="selectStatus('inprogress')"><span class="fa fa-repeat"></span> In Progress</div>
            <div class="statusitem" [class.selected]="newStatus == 'complete'" (click)="selectStatus('complete')"><span class="fa fa-check"></span> Completed</div>
            <div class="statusitem" [class.selected]="newStatus == 'cancel'" (click)="selectStatus('cancel')"><span class="fa fa-times"></span> Canceled</div>
        </div>
        <div class="statusitemsbox" *ngIf="newStatus == 'cancel'">
            <h3>Reason for cancelling</h3>
            <div class="statusitem" [class.selected]="newStatusReason == 'Illness'" (click)="selectStatusReason('Illness')">Illness</div>
            <div class="statusitem" [class.selected]="newStatusReason == 'Client found cheaper alternative'" (click)="selectStatusReason('Client found cheaper alternative')">Client found cheaper alternative</div>
            <div class="statusitem" [class.selected]="newStatusReason == 'Client found faster alternative'" (click)="selectStatusReason('Client found faster alternative')">Client found faster alternative</div>
            <div class="statusitem" [class.selected]="newStatusReason == 'Client did the repair on their own'" (click)="selectStatusReason('Client did the repair on their own')">Client did the repair on their own</div>
        </div>
        <div class="statusitemsbox" *ngIf="newStatus == 'complete'">
            <h3>Additional details</h3>
            <div class="statusitem" [class.selected]="newStatusReason == 'Diagnosis & Repair done'" (click)="selectStatusReason('Diagnosis & Repair done')">Diagnosis & Repair done</div>
            <div class="statusitem" [class.selected]="newStatusReason == 'Diagnosis done'" (click)="selectStatusReason('Diagnosis done')">Diagnosis done</div>
        </div>
        <div class="statusitemsbox" *ngIf="newStatus == 'reschedule'">
            <h3>Additional details</h3>
            <div class="statusitem" [class.selected]="newStatusReason == 'Customer unavailable'" (click)="selectStatusReason('Customer unavailable')">Customer unavailable</div>
            <div class="statusitem" [class.selected]="newStatusReason == 'Illness'" (click)="selectStatusReason('Illness')">Illness</div>
            <div class="statusitem" [class.selected]="newStatusReason == 'Technicians unavailable'" (click)="selectStatusReason('Technicians unavailable')">Technicians unavailable</div>
            <div class="statusitem" [class.selected]="newStatusReason == 'Spare parts or materials unavailable'" (click)="selectStatusReason('Spare parts or materials unavailable')">Spare parts or materials unavailable</div>
        </div>
        <div class="btnbox">
            <button class="btnprimary" (click)="createNewOrderStatus()">Update status</button>
        </div>
    </div>
    </div>
</div>