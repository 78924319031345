<div class="statushistorypopup">
    <div class="headbox">
        <h4>Order status</h4>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="historypopupcontent customscrollbar">
        <div class="statusitem" *ngFor="let item of statusHistoryArr">
            <span class="statusico fa" [statusico]="item.status" ></span><span>{{item.statusDate | customdate:'DD.MM.YYYY hh:mm a'}}</span>, <span style="text-transform: lowercase">{{item.status | orderstatus}}</span> by {{getName(item)}}
        </div>
    </div>
</div>