import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CollectionUtil } from 'src/app/shared/ccc/collections';
import { DropDownObj } from 'src/app/shared/models/commonobj';

@Component({
  selector: 'technician-personal-details',
  templateUrl: './technician-personal-details.component.html',
  styleUrls: ['./technician-personal-details.component.css']
})
export class TechnicianPersonalDetailsComponent implements OnInit {

  @Input()
  public form:FormGroup = null;

  readonly lstCountries: string[] = CollectionUtil.lstCountries;
  readonly lstRoles: DropDownObj[] = CollectionUtil.lstTechnicianRoles;

  constructor() { }

  ngOnInit() {
  }

}
