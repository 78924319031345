import { Component, Input, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TechnicianObj } from 'src/app/shared/models/technician';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'technician-summary-report',
  templateUrl: './technician-summary-report.component.html',
  styleUrls: ['./technician-summary-report.component.css']
})
export class TechnicianSummaryReportComponent implements OnInit {

  @Input()
  public technicianId: number = 0;

  public technicianObj: TechnicianObj = null;

  constructor(private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
    this.dataService.getTechnicianById(this.technicianId).subscribe(technician=>{
      this.technicianObj = technician;
    });
  }

}
