<div class="listingactionbox">
    <div class="filterbox">
        <div class="filterboxitem">
            <!-- <button class="icobtn"><span class="icofilter"></span></button> -->
        </div>
        <div class="filterboxitem">
            <button class="icobtn active" (click)="deleteTechnicians()"><span class="fa fa-trash"></span></button>
        </div>
        <div class="filterboxitem">
            <button class="icobtn navigationItem" [class.selectedItem]="currentView == 'LIST'" (click)="showList()"><span class="fa fa-list-ul"></span></button>
        </div>
        <div class="filterboxitem">
            <button class="icobtn navigationItem" [class.selectedItem]="currentView == 'CALENDAR'" (click)="showCalendar()"><span class="fa fa-calendar"></span></button>
        </div>
    </div>
    <div class="listingactionbtn">
        <button class="actiobtn" (click)="newTechnician()"><span class="fa fa-plus"></span> New Technician</button>
    </div>
</div>
<div *ngIf="currentView == 'LIST'">
    <table class="table listingtable" width="100%">
        <thead>
            <tr>
                <th><input type="checkbox" /></th>
                <th>Name</th>
                <th>Role</th>
                <th>Organization</th>
                <th>Rating</th>
                <th>City</th>
                <th style="width:150px;">Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of lstTechnicians" (click)="showDetailsPopup(item.technicianId)">
                <td><input type="checkbox" (click)="rowChkClicked($event)" /></td>
                <td>{{item.firstName}} {{item.lastName}}</td>
                <td>{{item.role}}</td>
                <td>{{item.organization}}</td>
                <td>4.3/5.0</td>
                <td>{{item.addressCity}}</td>
                <td><span class="bdgbox bdgaccepted">Available</span></td>
                <td>
                    <button class="icobtn" (click)="showContactNumber($event)"><span class="fa fa-phone"></span></button>&nbsp;
                    <button class="icobtn"><span class="fa fa-envelope"></span></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="currentView == 'CALENDAR'">
    <technician-calendar></technician-calendar>
</div>