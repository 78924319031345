<h3>Service provider details</h3>
<div class="wizardformsection" [formGroup]="form">
<div class="wizardformpartbox">
    <h4>Service provider information</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Organization name<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="1" formControlName="organization"/>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Website</label>
                <input type="text" class="form-control" tabindex="2" formControlName="websiteAddress"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Brand<span class="required">*</span></label>
                <select class="form-control" tabindex="4" formControlName="brand">
                    <option value="">Select brand</option>
                    <option *ngFor="let item of lstBrands" [value]="item.value">{{item.text}}</option>
                  </select>
            </div>
        </div>
    </div>
</div>
<div class="wizardformpartbox">
    <h4>Contact information</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>First name<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="3" formControlName="firstName"/>
            </div>
            <div class="form-group">
                <label>Email<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="5" formControlName="email"/>
            </div>
            <div class="form-group">
                <label>Role<span class="required">*</span></label>
                <select class="form-control" tabindex="8" formControlName="role">
                    <option value="">Select Role</option>
                    <option *ngFor="let item of lstSPRoles" [value]="item.value">{{item.text}}</option>
                </select>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Last name<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="4" formControlName="lastName"/>
            </div>
            <div class="form-group">
                <div class="multicontrolbox">
                    <div>
                        <label>Extension<span class="required">*</span></label>
                        <input type="text" class="form-control" tabindex="6" formControlName="mobileNumberExtension"/>
                    </div>
                    <div>
                        <label>Phone number<span class="required">*</span></label>
                        <input type="text" class="form-control" tabindex="7" formControlName="mobileNumber"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" tabindex="9" id="chkAllowWhasAppContact" formControlName="whatsAppContactAllowed">
                    <label class="form-check-label" for="chkAllowWhasAppContact">Allow contact via Whatsapp</label>
                  </div>
            </div>
        </div>
    </div>
</div>

<div class="wizardformpartbox">
    <h4>Company address</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Address<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="10" formControlName="companyAddressStreet"/>
            </div>
            <div class="form-group">
                <label>Addition</label>
                <input type="text" class="form-control" tabindex="12" formControlName="companyAddressAdditionInfo"/>
            </div>
            <div class="form-group">
                <label>Country<span class="required">*</span></label>
                <select class="form-control" tabindex="14" formControlName="companyAddressCountry">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of lstCountries" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>City<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="16" formControlName="companyAddressCity"/>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Number<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="11" formControlName="companyAddressHouseNo"/>
            </div>
            <div class="form-group">
                <label>Notes</label>
                <input type="text" class="form-control" tabindex="13" formControlName="companyAddressNotes"/>
            </div>
            <div class="form-group">
                <label>Postal code<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="15" formControlName="companyAddressPostalCode"/>
            </div>
        </div>
    </div>
</div>

</div>