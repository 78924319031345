import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DayObj } from 'src/app/shared/models/calendarobj';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';
import * as moment from 'moment';
import { TechnicianAvalabilityResponseObj, TechnicianCalendarCellVM } from 'src/app/shared/models/technician';

@Component({
  selector: 'technician-calendar-cell',
  templateUrl: './technician-calendar-cell.component.html',
  styleUrls: ['./technician-calendar-cell.component.css']
})
export class TechnicianCalendarCellComponent implements OnInit {

  @Input()
  public dayObj:DayObj;

  @Output()
  public onLstTechnicians: EventEmitter<TechnicianCalendarCellVM>=new EventEmitter();
  @Output()
  public onDaySelected: EventEmitter<DayObj> =new EventEmitter();
  
  constructor(private dataService: DataService, private modelService: ModelService) { }

  ngOnInit(): void {
    if(this.dayObj && this.dayObj.isToday)
    {
      this.selecteDay();
    }
  }

  public selecteDay(){
    
    this.onDaySelected.emit(this.dayObj);

    let dayTitle = moment(this.dayObj.dayTitle).format('YYYY-MM-DD');
     let loginResponseObj: LoginResponseObj = this.modelService.getLoginDetail();

     let spId: number = loginResponseObj.userId;

     this.dataService.getAllTechniciansInDate(spId, dayTitle).subscribe(res=>{
       let calendarCellVM: TechnicianCalendarCellVM=new TechnicianCalendarCellVM();
       calendarCellVM.dayObj= this.dayObj;
       calendarCellVM.lstTechnicians = res;

       this.onLstTechnicians.emit(calendarCellVM);
     });
  }


}
