<div class="wizardpage">
    <div class="wizardhead">
        <h2>Service provider Details</h2>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="wizardbodypopup customscrollbar">
        <div class="wizardtopinfobox" *ngIf="spObj">
            <div class="topinfocol1">
                <h3>{{spObj.firstName}} {{spObj.lastName}}</h3>
                <p>{{spObj.companyAddressPostalCode}}, {{spObj.companyAddressCity}}</p>
            </div>
            <div class="topinfocol2">
                <p class="psmall">Service provider since: 02.10.2020</p>
                <p class="psmall">Website: <a target="_blank" [href]="spObj.websiteAddress">{{spObj.websiteAddress}}</a></p>
            </div>
        </div>
        <div class="wizardinfobox" *ngIf="spObj">
            <h3>Service provider information</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Organization:</label>
                        <label class="content">{{spObj.organization}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Brand:</label>
                        <label class="content">{{spObj.brand}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Website:</label>
                        <label class="content">{{spObj.websiteAddress}}</label>
                    </div>
                </div>
            </div>
            <h4>Contact information</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Name:</label>
                        <label class="content">{{spObj.firstName}} {{spObj.lastName}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Phone:</label>
                        <label class="content">{{spObj.mobileNumberExtension}} {{spObj.mobileNumber}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Whatsapp:</label>
                        <label class="content">{{spObj.whatsAppContactAllowed}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Role:</label>
                        <label class="content">{{spObj.role}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">E-mail:</label>
                        <label class="content">{{spObj.email}}</label>
                    </div>
                </div>
            </div>
            <h4>Company address</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Address:</label>
                        <label class="content">{{spObj.companyAddressStreet}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{spObj.companyAddressNotes}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">City:</label>
                        <label class="content">{{spObj.companyAddressCity}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Country:</label>
                        <label class="content">{{spObj.companyAddressCountry}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Number:</label>
                        <label class="content">{{spObj.companyAddressHouseNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Addition:</label>
                        <label class="content">{{spObj.companyAddressAdditionInfo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Postal code:</label>
                        <label class="content">{{spObj.companyAddressPostalCode}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardinfobox" *ngIf="spObj">
            <h3>Company profile</h3>
            <p>{{spObj.companyProfile}}</p>
        </div>
        <div class="wizardinfobox" *ngIf="spObj">
            <h3>Technicians</h3>
            <p>-</p>
        </div>
        <div class="wizardinfobox" *ngIf="spObj">
            <h3>Notes</h3>
            <p>{{spObj.notes}}</p>
        </div>
    </div>
    <div class="wizardfooter">
        <div></div>
        <div>
            <button class="btnfooter" (click)="changePassword()"><span class="fa fa-pencil"></span>Change Password</button>
            &nbsp;
            <button class="btnfooter" (click)="editSP()"><span class="fa fa-pencil"></span>Edit</button>
        </div>
    </div>
</div>