import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CollectionUtil } from 'src/app/shared/ccc/collections';

@Component({
  selector: 'customer-contact-information',
  templateUrl: './customer-contact-information.component.html',
  styleUrls: ['./customer-contact-information.component.css']
})
export class CustomerContactInformationComponent implements OnInit {

  @Input()
  public form:FormGroup = null;

  readonly lstLanguages: string[] = CollectionUtil.lstLanguages;

  constructor() { }

  ngOnInit() {
  }

}
