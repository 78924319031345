<div class="wizardpage">
    <div class="wizardhead">
        <h2>Credentials</h2>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="changepasswordpopup">
        <p>Set the login credentials that the service provider will use</p>
        <div>
            <input type="password" class="form-control" [formControl]="frmCtrlPassword"/>
        </div>
        <div class="btnbox">
            <button class="btnprimary" (click)="savePassword()">Save credentials</button>
        </div>
    </div>
</div>