import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CollectionUtil } from 'src/app/shared/ccc/collections';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { DeleteConfirmationModalComponent } from 'src/app/shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { DropDownObj } from 'src/app/shared/models/commonobj';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { SPBasicDetailObj, SPProfileObj } from 'src/app/shared/models/serviceprovider';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  public profileForm:FormGroup = null;

  readonly lstSPRoles: DropDownObj[] = CollectionUtil.lstSPRoles;

  constructor(public fb:FormBuilder, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager, private router: Router, private ngbModal: NgbModal, private location: Location) { 
    this.profileForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.maxLength(150)]],
      mobileNumberExtension:[, [Validators.required]],
      mobileNumber:[, [Validators.required]],
      whatsAppContactAllowed:[],
      role:[,[Validators.required]]
    });
  }

  ngOnInit() {
    let loginResponse: LoginResponseObj = this.modelService.getLoginDetail();

    this.dataService.getSPById(loginResponse.userId).subscribe(res=>{
      this.profileForm.controls["firstName"].setValue(res.firstName);
      this.profileForm.controls["lastName"].setValue(res.lastName);
      this.profileForm.controls["email"].setValue(res.email);
      this.profileForm.controls["mobileNumberExtension"].setValue(res.mobileNumberExtension);
      this.profileForm.controls["mobileNumber"].setValue(res.mobileNumber);
      this.profileForm.controls["whatsAppContactAllowed"].setValue(res.whatsAppContactAllowed);
      this.profileForm.controls["role"].setValue(res.role);
    });
  }

  updateProfileInformation()
  {
    let loginResponseObj: LoginResponseObj= this.modelService.getLoginDetail();
    let spProfileObj: SPProfileObj = Object.assign({}, this.profileForm.value);
    spProfileObj.serviceProviderId = loginResponseObj.userId;

    this.dataService.updateSPProfile(spProfileObj).subscribe(spId=>{
      this.toastr.successToastr('Profile successfully updated');
    });
  }

  logout()
  {
      const modalRef = this.ngbModal.open(DeleteConfirmationModalComponent,{ backdrop: 'static', centered: true });
  
      modalRef.componentInstance.heading = 'Log out?';
       modalRef.componentInstance.body = 'Are you sure you want to log out of the system?';
       modalRef.componentInstance.btnConfirmText = 'Log out';
       
      
      modalRef.result.then((data: ModalConfirmationStatus) => {
        if (data == ModalConfirmationStatus.CONFIRMED) {
          this.router.navigate(['/']);
          this.toastr.successToastr('Logged out successfully');
        }
      }, (reason) => {
      });
  }

  goBack()
  {
    this.location.back();
  }

}
