export class DropDownObj {
    value: string;
    text: string;
  }

  export class DropDownObj2{
    public id: number;
    public title: string;
  }
  export class MultiSelectObj{
    public id: number;
    public title: string;
    public isSelected: boolean;
  }