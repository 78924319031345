import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginArg, LoginResponseObj } from 'src/app/shared/models/login';
import { DataService } from 'src/app/shared/services/data.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public form:FormGroup = null;
  
  constructor(private router: Router, public fb:FormBuilder, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager) { 
    this.form = this.fb.group({
      emailAddress: [, [Validators.required, Validators.maxLength(100)]],
      password: [, [Validators.required, Validators.maxLength(100)]]
    });
  }

  ngOnInit(): void {
  }
  
  login(){
    let arg:LoginArg=new LoginArg();
    arg.email = this.form.value.emailAddress;
    arg.password = this.form.value.password;

    this.dataService.login(arg).subscribe((res:LoginResponseObj)=>{
      console.log('login details are ', res);
      if(res.responseCode=="00")
      { 
        if(!res.isPasswordChanged)
        {
          this.toastr.infoToastr('Please set a new password before login', 'Set new password!')
          this.router.navigate(['change-password',arg.email]);
        }
        else
        {
          this.modelService.setLoginDetail(res);
          this.router.navigate(['home/dashboard']);
        }
      }
      else
      {
        this.toastr.errorToastr("Invalid Login / Password","Login Failed");
      }
    });
  }
}
