import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-step2',
  templateUrl: './signup-step2.component.html',
  styleUrls: ['./signup-step2.component.css']
})
export class SignupStep2Component implements OnInit {
  public form:FormGroup = null;
  constructor(private router: Router, public fb:FormBuilder) { 
    this.form = this.fb.group({
      organizationName: ['', [Validators.required, Validators.maxLength(100)]],
      role: ['', [Validators.required, Validators.maxLength(100)]],
      city:[],
      country:[]
    });
  }


  ngOnInit(): void {
  }

  nextStep(){
    
  }

}
