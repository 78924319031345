import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-step3',
  templateUrl: './signup-step3.component.html',
  styleUrls: ['./signup-step3.component.css']
})
export class SignupStep3Component implements OnInit {
  public form:FormGroup = null;
  constructor(private router: Router, public fb:FormBuilder) { 
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  ngOnInit(): void {
  }

  signup()
  {

  }

}
