import { Component, Input, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DataService } from 'src/app/shared/services/data.service';
import { forkJoin } from 'rxjs';
import { OrderCommentObj, OrderObj, OrderSparePartSuggestionObj } from 'src/app/shared/models/order';
import { CustomerObj } from 'src/app/shared/models/customer';

@Component({
  selector: 'request-summary-step',
  templateUrl: './request-summary-step.component.html',
  styleUrls: ['./request-summary-step.component.css']
})
export class RequestSummaryStepComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  public orderObj: OrderObj = null;
  public customerObj: CustomerObj = null;
  public lstSpareParts: OrderSparePartSuggestionObj[]=[];
  public lstComments: OrderCommentObj[] = [];

  constructor(private dataService: DataService, private toastr: ToastrManager,) { }

  ngOnInit() {
    let req1$ = this.dataService.getOrderById(this.orderId);
    let req2$ = this.dataService.getOrderSpareParts(this.orderId);
    let req3$ = this.dataService.getOrderComments(this.orderId);

    forkJoin([req1$,req2$, req3$]).subscribe(([order, lstSparePartsArr, lstComments])=>{

      this.orderObj = order;
      this.lstSpareParts = lstSparePartsArr;
      this.lstComments = lstComments;

      if(order.customerId)
      {
        this.dataService.getCustomerById(order.customerId).subscribe(customer=>{
          this.customerObj = customer;
        });
      }
    });
  }

  getCommentItemName(item: OrderCommentObj):string
  {
    let name='';
    switch(item.commentBy)
    {
      case 'SP':
        name = item.spName;
        break;
      case 'TECHNICIAN':
        name = item.technicianName;
        break;
      case 'CUSTOMER':
        name = item.customerName;
        break;
    }
    return name;
  }

}
