<h3>Customer data</h3>
<div class="wizardformsection" [formGroup]="form">
<div class="wizardformpartbox">
    <h4>Contact information</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>First name<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="1" formControlName="firstName" />
            </div>
            <div class="form-group">
                <label>Email<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="3" formControlName="email" />
            </div>
            <div class="form-group">
                <label>Languages<span class="required">*</span></label>
                <ng-select [items]="lstLanguages" [multiple]="true" tabindex="6" groupBy="gender" [selectableGroup]="true" [closeOnSelect]="false" formControlName="languages">
                </ng-select>
            </div>
            <div class="form-group">
                <label>Note</label>
                <input type="text" class="form-control" tabindex="8" formControlName="notes"/>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Last name<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="2" formControlName="lastName"/>
            </div>
            <div class="form-group">
                <div class="multicontrolbox">
                    <div>
                        <label>Extension<span class="required">*</span></label>
                        <input type="text" class="form-control" tabindex="4" formControlName="mobileNumberExtension"/>
                    </div>
                    <div>
                        <label>Phone number<span class="required">*</span></label>
                        <input type="text" class="form-control" tabindex="5" formControlName="mobileNumber"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" tabindex="7" id="exampleCheck1" formControlName="whatsAppContactAllowed">
                    <label class="form-check-label" for="exampleCheck1">Allow contact via Whatsapp</label>
                  </div>
            </div>
        </div>
    </div>
</div>

</div>