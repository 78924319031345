<div class="wizardinfosection" *ngIf="customerObj">
    <div class="wizardinfobox">
        <h3>Customer information</h3>
        <h4>Contact information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Customer:</label>
                    <label class="content">{{customerObj.firstName}} {{customerObj.lastName}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Email:</label>
                    <label class="content">{{customerObj.email}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Languages:</label>
                    <label class="content">{{customerObj.languages}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{customerObj.notes}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Mobile:</label>
                    <label class="content">{{customerObj.mobileNumberExtension}} {{customerObj.mobileNumber}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Whatsapp:</label>
                    <label class="content">{{customerObj.whatsAppContactAllowed}}</label>
                </div>
            </div>
        </div>
        <h4>Visit address</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Address:</label>
                    <label class="content">{{customerObj.visitAddressHouseNo}},{{customerObj.visitAddressStreetNo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{customerObj.visitAddressNotes}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">City:</label>
                    <label class="content">{{customerObj.visitAddressCity}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Country:</label>
                    <label class="content">{{customerObj.visitAddressCountry}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Addition:</label>
                    <label class="content">{{customerObj.visitAddressAdditionInfo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Elevator:</label>
                    <label class="content">{{customerObj.visitAddressIsElevator}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Postal code:</label>
                    <label class="content">{{customerObj.visitAddressPostalCode}}</label>
                </div>
            </div>
        </div>
        <h4>Billing address</h4>
        <p *ngIf="customerObj.isBillingAddressSame">Billing address is the same as the Visit address </p>
        <div class="row" *ngIf="!customerObj.isBillingAddressSame">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Address:</label>
                    <label class="content">{{customerObj.billingAddressHouseNo}},{{customerObj.billingAddressStreetNo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{customerObj.billingAddressNotes}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">City:</label>
                    <label class="content">{{customerObj.billingAddressCity}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Addition:</label>
                    <label class="content">{{customerObj.billingAddressAdditionInfo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Postal code:</label>
                    <label class="content">{{customerObj.billingAddressPostalCode}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Country:</label>
                    <label class="content">{{customerObj.billingAddressCountry}}</label>
                </div>
            </div>
        </div>
    </div>
</div>