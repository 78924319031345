import {Component, Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter,NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateAdapter {
  fromModel(value: string): NgbDateStruct
  {
     if (!value)
      return null
     let parts=value.split('.');
     return {year:+parts[2],month:+parts[1],day:+parts[0]} as NgbDateStruct
  }

  toModel(date: NgbDateStruct): string // from internal model -> your mode
  {
    console.log(date);
    return date?('0' + date.day).slice(-2) + "." + ('0'+date.month).slice(-2) + "." + (date.year):null
  }

}
@Injectable()
export class CustomDateParserFormatter {
  parse(value: string): NgbDateStruct
  {
    if (!value)
      return null
     let parts=value.split('.');
     return {year:+parts[2],month:+parts[1],day:+parts[0]} as NgbDateStruct

  }
  format(date: NgbDateStruct): string
  {
    return date?('0' + date.day).slice(-2) + "." + ('0'+date.month).slice(-2) + "." + (date.year):null
  }
}