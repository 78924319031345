<div class="wizardinfosection" *ngIf="orderObj">
    <div class="wizardinfobox">
        <h3>Request information</h3>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">FixFirst-ID:</label>
                    <label class="content">{{orderObj.fixtFirstID}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Service Provider:</label>
                    <label class="content">{{orderObj.serviceProviderOrganization}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Date:</label>
                    <label class="content">
                        {{orderObj.scheduleDate | customdate:'ddd - DD.MM.YYYY'}} @{{orderObj.scheduleTime}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Order-ID:</label>
                    <label class="content">{{orderObj.fixFirstOrderID}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Technician:</label>
                    <label class="content" [class.pending]="!orderObj.technicianId">
                        <span *ngIf="orderObj.technicianId">{{orderObj.technicianName}}</span>
                        <span *ngIf="!orderObj.technicianId">[pending]</span>
                    </label>
                </div>
                <div class="form-group">
                    <label class="caption">Visit type:</label>
                    <label class="content">{{orderObj.visitType}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardinfobox" *ngIf="customerObj">
        <h3>Customer information</h3>
        <h4>Contact information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Customer:</label>
                    <label class="content">{{customerObj.firstName}} {{customerObj.lastName}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Email:</label>
                    <label class="content">{{customerObj.email}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Languages:</label>
                    <label class="content">{{customerObj.languages}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{customerObj.notes}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Mobile:</label>
                    <label class="content">{{customerObj.mobileNumberExtension}} {{customerObj.mobileNumber}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Whatsapp:</label>
                    <label class="content">{{customerObj.whatsAppContactAllowed}}</label>
                </div>
            </div>
        </div>
        <h4>Visit address</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Address:</label>
                    <label class="content">{{customerObj.visitAddressHouseNo}},{{customerObj.visitAddressStreetNo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{customerObj.visitAddressNotes}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">City:</label>
                    <label class="content">{{customerObj.visitAddressCity}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Country:</label>
                    <label class="content">{{customerObj.visitAddressCountry}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Addition:</label>
                    <label class="content">{{customerObj.visitAddressAdditionInfo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Elevator:</label>
                    <label class="content">{{customerObj.visitAddressIsElevator}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Postal code:</label>
                    <label class="content">{{customerObj.visitAddressPostalCode}}</label>
                </div>
            </div>
        </div>
        <h4>Billing address</h4>
        <p *ngIf="customerObj.isBillingAddressSame">Billing address is the same as the Visit address </p>
        <div class="row" *ngIf="!customerObj.isBillingAddressSame">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Address:</label>
                    <label class="content">{{customerObj.billingAddressHouseNo}},{{customerObj.billingAddressStreetNo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{customerObj.billingAddressNotes}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">City:</label>
                    <label class="content">{{customerObj.billingAddressCity}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Addition:</label>
                    <label class="content">{{customerObj.billingAddressAdditionInfo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Postal code:</label>
                    <label class="content">{{customerObj.billingAddressPostalCode}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Country:</label>
                    <label class="content">{{customerObj.billingAddressCountry}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardinfobox">
        <h3>Appliance &amp; error data</h3>
        <h4>Appliance data</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Appliance:</label>
                    <label class="content">{{orderObj.applianceType}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Model-Nr:</label>
                    <label class="content">{{orderObj.applianceModelNumber}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Index-Nr:</label>
                    <label class="content">{{orderObj.applianceIndexNumber}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Contract-Nr:</label>
                    <label class="content">{{orderObj.applianceContractNumber}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Brand:</label>
                    <label class="content">{{orderObj.applianceBrand}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Serial-Nr:</label>
                    <label class="content">{{orderObj.applianceSerialNumber}}/01</label>
                </div>
                <div class="form-group">
                    <label class="caption">Age (Years):</label>
                    <label class="content">{{orderObj.applianceAge}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Warranty:</label>
                    <label class="content">{{orderObj.applianceIsWarranty}}</label>
                </div>
            </div>
        </div>
        <h4 *ngIf="orderObj.applianceIsWarranty">Warranty information</h4>
        <div class="row" *ngIf="orderObj.applianceIsWarranty">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Warranty contract number:</label>
                    <label class="content">{{orderObj.applianceWarrantyContractNo}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Notes:</label>
                    <label class="content">{{orderObj.applianceWarrantyNotes}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Details:</label>
                    <label class="content">{{orderObj.applianceWarrantyDetails}}</label>
                </div>
            </div>
        </div>
        <h4>Additional details</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Installation:</label>
                    <label class="content">{{orderObj.applianceInstallationDetail}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Purchase date:</label>
                    <label class="content">{{orderObj.appliancePurchaseDate}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Level of usage:</label>
                    <label class="content">{{orderObj.applianceLevelOfUsage}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Price new (€):</label>
                    <label class="content">{{orderObj.applianceNewPrice}}</label>
                </div>
            </div>
        </div>
        <h4>Error data</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Problem:</label>
                    <label class="content">{{orderObj.errorTitle}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Details:</label>
                    <label class="content">{{orderObj.errorDetails}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Error code:</label>
                    <label class="content">{{orderObj.errorCode}}</label>
                </div>
                <div class="form-group">
                    <label class="caption">Note:</label>
                    <label class="content">{{orderObj.errorNotes}}</label>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="wizardinfobox">
        <h3>Attachments</h3>
    </div> -->
    <div class="wizardinfobox">
        <h3>Recommendations</h3>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Repair:</label>
                    <label class="content">{{orderObj.recommendationRepair}}</label>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="caption">Other:</label>
                    <label class="content">{{orderObj.recommendationOther}}</label>
                </div>
            </div>
        </div>
        <h4>Spare parts and materials suggested</h4>
        <table class="table table-border table-striped">
            <thead>
                <tr>
                    <th></th>
                    <th>Item Name</th>
                    <th>Code</th>
                    <th>Quantity</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of lstSpareParts; let i=index">
                    <td>{{i + 1}}</td>
                    <td>{{item.itemName}}</td>
                    <td>{{item.itemCode}}</td>
                    <td>{{item.itemQuantity}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="wizardinfobox">
        <h3>Comments</h3>
        <div class="wizardchatbox">
            <div class="wizardchatitem" *ngFor="let item of lstComments">
                <div class="icobox">
                    <img src="../../../../assets/images/icoprofile.png" />
                </div>
                <div class="chatcontentbox">
                    <div class="chatcontent">
                        <h3>{{getCommentItemName(item)}}</h3>
                        <p>{{item.commentText}}</p>
                    </div>
                    <div class="chatdate">{{item.commentDate | customdate:'DD.MM.YYYY hh:mm a'}}</div>
                </div>
            </div>
        </div>
    </div>
</div>