import { Component, OnInit } from '@angular/core';
import { ModelService } from '../shared/services/model-service';

@Component({
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css']
})
export class LeftMenuComponent implements OnInit {

  public isSPLogin: boolean = false;
  public isCCLogin: boolean = false;

  constructor(private modelService: ModelService) { 
   let loginDetail= this.modelService.getLoginDetail();
   switch(loginDetail.userType)
   {
     case 'CC':
       this.isCCLogin = true;
       break;
      case 'SP':
        this.isSPLogin = true;
        break;
   }
  }

  ngOnInit(): void {
  }

}
