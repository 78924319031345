import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { CurrentOrderStatusObj, NewOrderStatusArg } from 'src/app/shared/models/orderstatus';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-update-status-popup',
  templateUrl: './update-status-popup.component.html',
  styleUrls: ['./update-status-popup.component.css']
})
export class UpdateStatusPopupComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  public currentStatusObj:CurrentOrderStatusObj = null;

  public newStatus: string='';
  public newStatusReason: string = '';


  constructor(public activeModal: NgbActiveModal, public modelService: ModelService, private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
  }

  closePopup(){
    this.activeModal.close(ModalConfirmationStatus.CLOSED);
  }
  selectStatus(status: string)
  {
    this.newStatus = status;
  }
  selectStatusReason(statusReason: string)
  {
    this.newStatusReason = statusReason;
  }
  createNewOrderStatus(){
    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();

    let newStatusArg: NewOrderStatusArg=new NewOrderStatusArg();
    newStatusArg.orderId = this.orderId;
    newStatusArg.status = this.newStatus;
    newStatusArg.statusReason = this.newStatusReason;
    newStatusArg.statusBy = loginDetail.userType;
    newStatusArg.userId = loginDetail.userId;

    this.dataService.createNewOrderStatus(newStatusArg).subscribe(res=>{
      switch(res.responseCode)
      {
        case "00":
          this.toastr.successToastr('Status updated successfully', 'Status Updated!');
          this.activeModal.close(ModalConfirmationStatus.CONFIRMED);
          break;
        case "01":
          this.toastr.errorToastr('Status could not be updated, because this status is already assigned to this order.', 'Error!');
          break;
      }
    });


  }
  public loadRecords():void{
    this.dataService.getOrderCurrentStatusObj(this.orderId).subscribe(res=>{
      this.currentStatusObj = res;
    });
  }

}
