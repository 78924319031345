<div class="availabletspopup" *ngIf="dayObj">
    <div class="headbox">
        <h4>Visit timeslot</h4>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
        <div class="datebox">{{dayObj.dayTitle}}</div>
    </div>
    <div class="tsboxcont customscrollbar">
        <div class="tsselectionbox" [class.selected]="selectedTS == item" *ngFor="let item of dayObj.slotsArr" (click)="selectTS(item)">
            {{item.tsFrom}} - {{item.tsTo}}
        </div>
        <div class="btnbox">
            <button class="btnprimary" (click)="fetchDetails()">Select</button>
        </div>
    </div>
</div>