import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { Util } from 'src/app/shared/ccc/util';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { TechnicianNotesDetailObj, TechnicianPartialUpdateObj, TechnicianPersonalDetailObj, TechnicianProfileDetailObj, TechnicianSetPasswordObj, TechnicianSkillsDetailObj } from 'src/app/shared/models/technician';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';
import { SetTechnicianPasswordPopupComponent } from '../set-technician-password-popup/set-technician-password-popup.component';

@Component({
  selector: 'app-manage-technicians',
  templateUrl: './manage-technicians.component.html',
  styleUrls: ['./manage-technicians.component.css']
})
export class ManageTechniciansComponent implements OnInit {
  
  public technicianId: number = 0;
  public isCreateNew: boolean = false;
  public pageTitle: string = "New Technician";

  public personalDetailForm:FormGroup = null;
  public skillsDetailForm:FormGroup = null;
  public profileDetailForm:FormGroup = null;
  public noteDetailForm:FormGroup = null;

  public currentStep:string = 'personal_details';

  constructor(public activatedRoute: ActivatedRoute, private ngbModal: NgbModal, private router: Router, private dataService: DataService, private modelService: ModelService, public fb:FormBuilder, private toastr: ToastrManager) 
  { 
    let loginResponseObj:LoginResponseObj = this.modelService.getLoginDetail();

    console.log(loginResponseObj);

    this.technicianId = this.activatedRoute.snapshot.params.id;  

    this.personalDetailForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.maxLength(150)]],
      mobileNumberExtension:[, [Validators.required]],
      mobileNumber:[, [Validators.required]],
      whatsAppContactAllowed:[],
      organization:['', [Validators.maxLength(100)]],
      role:['', [Validators.maxLength(500)]],

      addressHouseNo:[,[Validators.required, Validators.maxLength(100)]],
      addressStreet:[,[Validators.required, Validators.maxLength(100)]],
      addressAdditionInfo:[],
      addressNotes:[],
      addressCountry:[loginResponseObj.country,[Validators.required, Validators.maxLength(100)]],
      addressPostalCode:[,[Validators.required, Validators.maxLength(100)]],
      addressCity:[,[Validators.required, Validators.maxLength(100)]]
    });

    this.skillsDetailForm = this.fb.group({
      skillLanguages: [],
      skillCertifications: [],
      skillBrands: [],
      skillAppliances: [],
      skillPhysicalResilience: []
    });

    this.profileDetailForm = this.fb.group({
      personalProfile: []
    });

    this.noteDetailForm = this.fb.group({
      notes: []
    });

  }

  ngOnInit() {
    if(this.technicianId)
    {
      this.pageTitle="Update Technician";
      this.dataService.getTechnicianById(this.technicianId).subscribe(customer=>{

        this.personalDetailForm.controls["firstName"].setValue(customer.firstName);
        this.personalDetailForm.controls["lastName"].setValue(customer.lastName);
        this.personalDetailForm.controls["email"].setValue(customer.email);
        this.personalDetailForm.controls["mobileNumberExtension"].setValue(customer.mobileNumberExtension);
        this.personalDetailForm.controls["mobileNumber"].setValue(customer.mobileNumber);
        this.personalDetailForm.controls["whatsAppContactAllowed"].setValue(customer.whatsAppContactAllowed);
        this.personalDetailForm.controls["organization"].setValue(customer.organization);
        this.personalDetailForm.controls["role"].setValue(customer.role);
        this.personalDetailForm.controls["addressHouseNo"].setValue(customer.addressHouseNo);
        this.personalDetailForm.controls["addressStreet"].setValue(customer.addressStreet);
        this.personalDetailForm.controls["addressAdditionInfo"].setValue(customer.addressAdditionInfo);
        this.personalDetailForm.controls["addressNotes"].setValue(customer.addressNotes);
        
        if(customer.addressCountry)
        {
          this.personalDetailForm.controls["addressCountry"].setValue(customer.addressCountry);
        }

        this.personalDetailForm.controls["addressPostalCode"].setValue(customer.addressPostalCode);
        this.personalDetailForm.controls["addressCity"].setValue(customer.addressCity);



        this.skillsDetailForm.controls["skillLanguages"].setValue(Util.ToStringArr(customer.skillLanguages));
        this.skillsDetailForm.controls["skillCertifications"].setValue(Util.ToStringArr(customer.skillCertifications));
        this.skillsDetailForm.controls["skillBrands"].setValue(Util.ToStringArr(customer.skillBrands));
        this.skillsDetailForm.controls["skillAppliances"].setValue(Util.ToStringArr(customer.skillAppliances));
        this.skillsDetailForm.controls["skillPhysicalResilience"].setValue(customer.skillPhysicalResilience);


        this.profileDetailForm.controls["personalProfile"].setValue(customer.personalProfile);

        this.noteDetailForm.controls["notes"].setValue(customer.notes);
      });
    }
    else
    {
      this.technicianId = 0;
      this.isCreateNew = true;
    }
  }

  changeStep(newStep: string)
  {
    this.currentStep = newStep;
  }

  backStep()
  {
    switch(this.currentStep)
    {
      case 'skills_details':
        this.currentStep = 'personal_details';
        break;
      case 'profile_details':
        this.currentStep = 'skills_details';
        break;
        case 'notes_details':
          this.currentStep = 'profile_details';
          break;
      case 'summary':
        this.currentStep = 'notes_details';
        break;
    }
  }
  nextStep()
  {
    let partialUpdateObj:TechnicianPartialUpdateObj = new TechnicianPartialUpdateObj();
    partialUpdateObj.technicianId = this.technicianId;
    switch(this.currentStep)
    {
      case 'personal_details':
        let personalDetailObj: TechnicianPersonalDetailObj = Object.assign({}, this.personalDetailForm.value);
        let loginResponseObj: LoginResponseObj = this.modelService.getLoginDetail();
        personalDetailObj.serviceProviderId = loginResponseObj.userId; // service provider id shall be saved at this point from the login detail.
        partialUpdateObj.personalDetailObj = personalDetailObj;
        partialUpdateObj.stepType="PERSONAL_DETAILS";
        this.dataService.partialUpdateTechncian(partialUpdateObj).subscribe(technicianId=>{
          if(technicianId) //incase of insert technicianid is returned.
          {
            this.technicianId = technicianId;
          }
          this.toastr.successToastr('Technician details saved successfully');
          this.currentStep = 'skills_details';
        });
        break;
      case 'skills_details':
        let skillDetailsObj: TechnicianSkillsDetailObj = Object.assign({}, this.skillsDetailForm.value);

        skillDetailsObj.skillLanguages= Util.ToText(this.skillsDetailForm.controls['skillLanguages'].value);
        skillDetailsObj.skillCertifications= Util.ToText(this.skillsDetailForm.controls['skillCertifications'].value);
        skillDetailsObj.skillBrands= Util.ToText(this.skillsDetailForm.controls['skillBrands'].value);
        skillDetailsObj.skillAppliances= Util.ToText(this.skillsDetailForm.controls['skillAppliances'].value);

        partialUpdateObj.skillDetailObj = skillDetailsObj;
        partialUpdateObj.stepType="SKILL_DETAILS";
        this.dataService.partialUpdateTechncian(partialUpdateObj).subscribe(res=>{
          this.toastr.successToastr('Skills details saved successfully');
          this.currentStep = 'profile_details';
        });
        break;
        case 'profile_details':
          let profileDetailObj: TechnicianProfileDetailObj = Object.assign({}, this.profileDetailForm.value);
          partialUpdateObj.profileDetailObj = profileDetailObj;
          partialUpdateObj.stepType="PROFILE_DETAILS";
          this.dataService.partialUpdateTechncian(partialUpdateObj).subscribe(res=>{
            this.toastr.successToastr('Profile details saved successfully');
            this.currentStep = 'notes_details';
          });
          break;
          case 'notes_details':
            let notesDetailObj: TechnicianNotesDetailObj = Object.assign({}, this.noteDetailForm.value);
            partialUpdateObj.notesDetailObj = notesDetailObj;
            partialUpdateObj.stepType="NOTES_DETAILS";
            this.dataService.partialUpdateTechncian(partialUpdateObj).subscribe(res=>{
              this.toastr.successToastr('Notes details saved successfully');
              this.currentStep = 'summary';
            });
            break;
    }
  }

  selStep(selectedStep: string)
  {
    this.currentStep = selectedStep;
  }

  isFormActive():boolean
  {
    let isActive: boolean = true;
    switch(this.currentStep)
    {
      case "personal_details":
        isActive = this.personalDetailForm.valid;
        break;
      case "skills_details":
        isActive = this.skillsDetailForm.valid;
        break;
      case "profile_details":
        isActive = this.profileDetailForm.valid;
        break;
        case "notes_details":
          isActive = this.noteDetailForm.valid;
          break;
    }
    return isActive;
  }

  setPassword(){
    const modalRef = this.ngbModal.open(SetTechnicianPasswordPopupComponent,{ backdrop: 'static', centered: true, size: 'sm' });

    modalRef.result.then((data: any) => {
      if (data.status == ModalConfirmationStatus.CONFIRMED) {
        let passwordChangeObj: TechnicianSetPasswordObj=new TechnicianSetPasswordObj();
        passwordChangeObj.technicianId = this.technicianId;
        passwordChangeObj.password = data.password;

        this.dataService.updateTechnicianPassword(passwordChangeObj).subscribe(res=>{
          this.toastr.successToastr('Technician successfully created', 'Technician created');
          this.finishWizard();
        });
      }
    }, (reason) => {
    });

  }

  finishWizard(){
    this.router.navigate(['home/technicians']);
  }

}
