import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModalConfirmationStatus, OrderStatusConstants } from 'src/app/shared/ccc/constants';
import { DayObj, SlotObj } from 'src/app/shared/models/calendarobj';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { OrderScheduleSPObj, OrderScheduleTechnicianObj } from 'src/app/shared/models/order';
import { CurrentOrderStatusObj, NewOrderStatusArg } from 'src/app/shared/models/orderstatus';
import { SPAvalabilityResponseObj, SPCalendarCellVM } from 'src/app/shared/models/serviceprovider';
import { GenericAvailabilityResponseObj, TechnicianAvailabilityPlainObj, TechnicianAvalabilityResponseObj, TechnicianCalendarCellVM, TechnicianPlainCalendarCellVM } from 'src/app/shared/models/technician';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';
import { AvailableTSComponent } from './available-ts/available-ts.component';

@Component({
  selector: 'booking-details-step',
  templateUrl: './booking-details-step.component.html',
  styleUrls: ['./booking-details-step.component.css']
})
export class BookingDetailsStepComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  public currentMonth:any;

  public days:DayObj[]=[];

  public mode: string = null;

  public selectedDay: DayObj= null;
  public selectedTS: SlotObj = null;

  public lstTechnicians: TechnicianAvailabilityPlainObj[] = [];
  public lstSPs: SPAvalabilityResponseObj[] = [];
  public orderStatusObj: CurrentOrderStatusObj = null;


  constructor(private ngbModal: NgbModal, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager) { 
    this.currentMonth=moment().startOf('month');
    
    let loginResponse:LoginResponseObj = this.modelService.getLoginDetail();
    this.mode = loginResponse.userType;

    this.populateCalendarWithTS();
  }

  ngOnInit(): void {
  }

  public getMonthTitle(){
    return `${this.currentMonth.format('MMM-YYYY')}`;
  }
  public nextMonth(){
    this.currentMonth=moment(this.currentMonth).add(1,'month');
    console.log(this.currentMonth);
    this.populateCalendarWithTS();
  }
  public prevMonth(){
    this.currentMonth=moment(this.currentMonth).add(-1,'month');
    this.populateCalendarWithTS();
  }

  public selectDay(arg: DayObj)
  {
    const modalRef = this.ngbModal.open(AvailableTSComponent,{ backdrop: 'static', centered: true, size:'sm' });
    modalRef.componentInstance.dayObj = arg;

    modalRef.result.then((data: any) => {
      if(data.mode == ModalConfirmationStatus.CONFIRMED)
      {
        this.selectedDay = arg;
        this.selectedTS = data.tsObj;
        if(this.mode == 'SP')
        {
          this.lstTechnicians = data.dataArr;
        }
        else
        {
          this.lstSPs = data.dataArr;
        }
      }
    }, (reason) => {
    });
    

    this.days.forEach(x=>{
      if(x.dayTitle == arg.dayTitle)
      {
        x.isSelected=true;
      }
      else
      {
        x.isSelected=false;
      }
    });
  }

  populateTechniciansList(arg: TechnicianPlainCalendarCellVM)
  {
    this.lstTechnicians = arg.lstTechnicians;
  }

  populateSPsList(arg: SPCalendarCellVM)
  {
    this.lstSPs = arg.lstSPs;

    this.selectedDay = arg.dayObj;
  }
  selectSP(arg: SPAvalabilityResponseObj)
  {
    this.lstSPs.forEach(x=>x.isAvailable=false);
    arg.isAvailable=!arg.isAvailable;
  }

  selectTechnician(arg: TechnicianAvalabilityResponseObj)
  {
    this.lstTechnicians.forEach(x=>x.isAvailable=false);
    arg.isAvailable=!arg.isAvailable;
  }

  assignServiceProvider()
  {
    let spAvailabilityObj : SPAvalabilityResponseObj= this.lstSPs.find(x=>x.isAvailable);
    if(spAvailabilityObj)
    {
    let spScheduleObj: OrderScheduleSPObj=new OrderScheduleSPObj();
    spScheduleObj.orderId = this.orderId;
    spScheduleObj.scheduleDate=this.selectedDay.day.format('YYYY-MM-DD');
    spScheduleObj.scheduleTime=`${this.selectedTS.tsFrom} - ${this.selectedTS.tsTo}`;
    spScheduleObj.spId = spAvailabilityObj.spId;

    this.dataService.updateOrderScheduleSP(spScheduleObj).subscribe(res=>{
      this.toastr.successToastr('Order successfully booked', 'Order Booked');
    });
    }
    else
    {
      this.toastr.errorToastr('Please select a service provider');
    }
  }

  assignTechnician()
  {
    let technicianAvailabilityObj : TechnicianAvailabilityPlainObj= this.lstTechnicians.find(x=>x.isAvailable);
    if(technicianAvailabilityObj)
    {
    let technicianScheduleObj: OrderScheduleTechnicianObj=new OrderScheduleTechnicianObj();
    technicianScheduleObj.orderId = this.orderId;
    technicianScheduleObj.technicianId = technicianAvailabilityObj.technicianId;

    this.dataService.updateOrderScheduleTechnician(technicianScheduleObj).subscribe(res=>{
      this.toastr.successToastr('Technician successfully assigned to order.', 'Technician Assigned');

      let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();
      
      let newStatusArg: NewOrderStatusArg=new NewOrderStatusArg();
      newStatusArg.orderId = this.orderId;
      newStatusArg.status = OrderStatusConstants.Schedule;
      newStatusArg.statusReason = "";
      newStatusArg.statusBy = loginDetail.userType;
      newStatusArg.userId = loginDetail.userId;

      this.dataService.createNewOrderStatus(newStatusArg).subscribe(res=>{
        switch(res.responseCode)
        {
          case "00":
            this.toastr.successToastr('Order scheduled successfully', 'Status Updated!');
            break;
          case "01":
            this.toastr.errorToastr('Status could not be updated, because this status is already assigned to this order.', 'Error!');
            break;
        }
      });

    });
    }
    else
    {
      this.toastr.errorToastr('Please select a technician');
    }
  }

  private populateCalendarWithTS()
  {
    let monthStart = this.currentMonth.format('YYYY-MM-DD');
    let monthEnd = moment(monthStart).endOf('month').format('YYYY-MM-DD');
    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();
    let ccId: number = null;
    let spId: number = null;

    if(this.mode == 'SP')
    {
      spId = loginDetail.userId;
    }
    else
    {
      ccId = loginDetail.userId;
    }
    
      this.dataService.getTechniciansAvailabilityInDateRange(ccId, spId,monthStart, monthEnd).subscribe(res=>{
        if(this.mode=='SP')
        {
          this.dataService.getOrderCurrentStatusObj(this.orderId).subscribe(curStatusObj=>{
            this.orderStatusObj = curStatusObj;
            this.populateCalendar(res);
          });
        }
        else
        {
          this.populateCalendar(res);
        }
      });
  }

  private populateCalendar(availabilityArr:GenericAvailabilityResponseObj[]){
    
    this.days=[];

    let today=moment();

    let daysInMonth = this.currentMonth.daysInMonth();

    let startIndex = this.currentMonth.day();

    let runningIndex = 0;

    for(let index = 0; index < startIndex; index++)
    {
      runningIndex += 1;
      let dayObj:any = moment(this.currentMonth).add(index - startIndex ,'day');

      let arrElem:DayObj={
        dayNumber:dayObj.date(),
        isToday:dayObj.format('YYYY-MM-DD') == today.format('YYYY-MM-DD'),
        isSelected: (this.orderStatusObj && this.orderStatusObj.scheduleDate) && dayObj.format('YYYY-MM-DD') == moment(this.orderStatusObj.scheduleDate).format('YYYY-MM-DD'),
        isOtherMonth:true,
        dayTitle:dayObj.format('DD-MMM-YYYY'),
        day:dayObj
      };
      let curTS = availabilityArr.find(x=>x.availabilityDate == dayObj.format('YYYY-MM-DD'));
      if(curTS)
      {
        arrElem.slotsArr = curTS.lstTS;
      }
      if(arrElem.isSelected)
      {
        this.selectDay(arrElem);
      }
      this.days.push(arrElem);
    }

    for(let index = 0; index < daysInMonth; index++)
    {
      runningIndex += 1;
      let dayObj:any = moment(this.currentMonth).add(index ,'day');

      let arrElem:DayObj={
        dayNumber:dayObj.date(),
        isToday:dayObj.format('YYYY-MM-DD') == today.format('YYYY-MM-DD'),
        isSelected: (this.orderStatusObj && this.orderStatusObj.scheduleDate) && dayObj.format('YYYY-MM-DD') == moment(this.orderStatusObj.scheduleDate).format('YYYY-MM-DD'),
        isOtherMonth:true,
        dayTitle:dayObj.format('DD-MMM-YYYY'),
        day:dayObj
      };
      let curTS = availabilityArr.find(x=>x.availabilityDate == dayObj.format('YYYY-MM-DD'));
      if(curTS)
      {
        arrElem.slotsArr = curTS.lstTS;
      }
      if(arrElem.isSelected)
      {
        this.selectDay(arrElem);
      }
      this.days.push(arrElem);
    }

    let nextMonthDays:number =0;
    for(let index = runningIndex; index < 42; index++)
    {
      let dayObj:any = moment(this.currentMonth).add(index - startIndex ,'day');

      let arrElem:DayObj={
        dayNumber:dayObj.date(),
        isToday:dayObj.format('YYYY-MM-DD') == today.format('YYYY-MM-DD'),
        isSelected: (this.orderStatusObj && this.orderStatusObj.scheduleDate) && dayObj.format('YYYY-MM-DD') == moment(this.orderStatusObj.scheduleDate).format('YYYY-MM-DD'),
        isOtherMonth:true,
        dayTitle:dayObj.format('DD-MMM-YYYY'),
        day:dayObj
      };
      let curTS = availabilityArr.find(x=>x.availabilityDate == dayObj.format('YYYY-MM-DD'));
      if(curTS)
      {
        arrElem.slotsArr = curTS.lstTS;
      }
      if(arrElem.isSelected)
      {
        this.selectDay(arrElem);
      }
      this.days.push(arrElem);
    }
  }

}
