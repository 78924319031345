import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ServiceProviderObj } from 'src/app/shared/models/serviceprovider';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'sp-summary',
  templateUrl: './spsummary.component.html',
  styleUrls: ['./spsummary.component.css']
})
export class SPSummaryComponent implements OnInit {

  @Input()
  public serviceProviderId: number = 0;

  public serviceProviderObj: ServiceProviderObj = null;
  
  constructor(private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
    console.log(this.serviceProviderId);
    this.dataService.getSPById(this.serviceProviderId).subscribe(res=>{
      this.serviceProviderObj = res;
    });
  }

}
