import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationStatus, OrderStatusConstants } from 'src/app/shared/ccc/constants';
import { OrderStatusHistoryObj } from 'src/app/shared/models/orderstatus';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-order-status-history-popup',
  templateUrl: './order-status-history-popup.component.html',
  styleUrls: ['./order-status-history-popup.component.css']
})
export class OrderStatusHistoryPopupComponent implements OnInit {
  
  @Input()
  public orderId: number = null;

  public statusHistoryArr: OrderStatusHistoryObj[] = [];

  constructor(public activeModal: NgbActiveModal, private modelService: ModelService, private dataService: DataService) { }

  ngOnInit() {
  }
  public getName(item: OrderStatusHistoryObj):string{
    let name:string = '';
    switch(item.statusBy)
    {
      case 'CC':
        name=`${item.serviceProviderName} [Customer care]`;
        break;
      case 'SP':
        name=`${item.serviceProviderName} [Service provider]`;
        break;
      case 'TECHNICIAN':
        name=`${item.technicianName} [Service technician]`;
        break;
    }
    return name;

  }

  closePopup(){
    this.activeModal.close(
      {
        status: ModalConfirmationStatus.CLOSED
      }
    );
  }
  public loadRecords()
  {
    this.dataService.getOrderStatusHistory(this.orderId).subscribe(res=>{
      this.statusHistoryArr = res;
    });
  }

}
