<h3>Recommendations & comments</h3>

<div class="wizardformsection" [formGroup]="form">
    <div class="wizardformpartbox">
        <h3>Recommendations</h3>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Repair</label>
                    <input type="text" class="form-control" formControlName="recommendationRepair" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Other</label>
                    <input type="text" class="form-control" formControlName="recommendationOther" />
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformpartbox">
        <h3>Spare parts and materials suggested</h3>
        <h4>Items</h4>
        <div *ngFor="let item of lstSpareParts.controls; let i=index">
            <spare-parts-suggestion (removeRequest)="removeSparePart($event)" [serialNo]="i + 1" [form]="item"></spare-parts-suggestion>
        </div>
        <div class="addLinkBox">
            <a (click)="addSparePart()"><span class="fa fa-plus-circle"></span> Add spare parts or material</a>
        </div>
    </div>
    <div class="wizardformpartbox">
        <h3>Comments</h3>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <textarea type="text" class="form-control" rows="2" cols="20" formControlName="orderComments"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>