<h3>Customer data</h3>
<div class="wizardformsection" [formGroup]="form">
<div class="wizardformpartbox">
    <h4>Visit Address</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Address<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="1" formControlName="visitAddressStreetNo"/>
            </div>
            <div class="form-group">
                <label>Addition</label>
                <input type="text" class="form-control" tabindex="3" formControlName="visitAddressAdditionInfo"/>
            </div>
            <div class="form-group">
                <label>Country<span class="required">*</span></label>
                <select class="form-control" tabindex="5" formControlName="visitAddressCountry">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of lstCountries" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>City<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="7" formControlName="visitAddressCity"/>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Number<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="2" formControlName="visitAddressHouseNo"/>
            </div>
            <div class="form-group">
                <label>Notes</label>
                <input type="text" class="form-control" tabindex="4" formControlName="visitAddressNotes"/>
            </div>
            <div class="form-group">
                <label>Postal code<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="6" formControlName="visitAddressPostalCode"/>
            </div>
            <div class="form-group">
                <label>Elevator</label>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" tabindex="7" id="chkVisitAddressElevatorAvailable" formControlName="visitAddressIsElevator">
                    <label class="form-check-label" for="chkVisitAddressElevatorAvailable">Available</label>
                  </div>
            </div>
        </div>
    </div>
</div>

<div class="wizardformpartbox">
    <h4>Billing Address</h4>
    <div class="form-group">
        <div class="form-check">
            <input type="checkbox" class="form-check-input" tabindex="8" id="chkIsBillingAddressSame" formControlName="isBillingAddressSame">
            <label class="form-check-label" for="chkIsBillingAddressSame">Billing address is the same as the Visit address</label>
          </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Address<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="9" formControlName="billingAddressStreetNo" />
            </div>
            <div class="form-group">
                <label>Addition</label>
                <input type="text" class="form-control" tabindex="11" formControlName="billingAddressAdditionInfo" />
            </div>
            <div class="form-group">
                <label>Country<span class="required">*</span></label>
                <select class="form-control" tabindex="13" formControlName="billingAddressCountry">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of lstCountries" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>City<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="15" formControlName="billingAddressCity" />
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Number<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="10" formControlName="billingAddressHouseNo" />
            </div>
            <div class="form-group">
                <label>Notes</label>
                <input type="text" class="form-control" tabindex="12" formControlName="billingAddressNotes" />
            </div>
            <div class="form-group">
                <label>Postal code<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="14" formControlName="billingAddressPostalCode" />
            </div>
        </div>
    </div>
</div>

</div>