import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { ServiceProviderObj, SPSetPasswordObj } from 'src/app/shared/models/serviceprovider';
import { DataService } from 'src/app/shared/services/data.service';
import { SetSPPasswordPopupComponent } from '../set-sppassword-popup/set-sppassword-popup.component';

@Component({
  selector: 'app-view-service-provider-popup',
  templateUrl: './view-service-provider-popup.component.html',
  styleUrls: ['./view-service-provider-popup.component.css']
})
export class ViewServiceProviderPopupComponent implements OnInit {

  @Input()
  public spId: number = 0;
  public spObj:ServiceProviderObj=null;

  constructor(public router: Router, public activeModal: NgbActiveModal, private ngbModal: NgbModal, private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
  }
  closePopup(){
    this.activeModal.close();
  }
  editSP(){
    this.closePopup();
    this.router.navigate(['home/service-providers/edit/',this.spId]);
  }
  changePassword(){
    const modalRef = this.ngbModal.open(SetSPPasswordPopupComponent,{ backdrop: 'static', centered: true, size: 'sm' });

    modalRef.result.then((data: any) => {
      if (data.status == ModalConfirmationStatus.CONFIRMED) {
        let passwordChangeObj: SPSetPasswordObj=new SPSetPasswordObj();
        passwordChangeObj.serviceProviderId = this.spId;
        passwordChangeObj.password = data.password;

        this.dataService.updateSPPassword(passwordChangeObj).subscribe(res=>{
          this.toastr.successToastr('Password successfully reset', 'Passwort Reset');
        });
      }
    }, (reason) => {
    });
  }
  public loadRecords(){
    this.dataService.getSPById(this.spId).subscribe(res=>{
      this.spObj = res;
    });
  }


}
