import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { TechnicianObj, TechnicianSetPasswordObj } from 'src/app/shared/models/technician';
import { DataService } from 'src/app/shared/services/data.service';
import { SetTechnicianPasswordPopupComponent } from '../set-technician-password-popup/set-technician-password-popup.component';

@Component({
  selector: 'app-view-technician-popup',
  templateUrl: './view-technician-popup.component.html',
  styleUrls: ['./view-technician-popup.component.css']
})
export class ViewTechnicianPopupComponent implements OnInit {

  @Input()
  public technicianId: number = 0;
  public technicianObj:TechnicianObj=null;

  constructor(public router: Router, public activeModal: NgbActiveModal, private ngbModal: NgbModal,  private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
  }

  closePopup(){
    this.activeModal.close();
  }

  changePassword(){
    const modalRef = this.ngbModal.open(SetTechnicianPasswordPopupComponent,{ backdrop: 'static', centered: true, size: 'sm' });

    modalRef.result.then((data: any) => {
      if (data.status == ModalConfirmationStatus.CONFIRMED) {
        let passwordChangeObj: TechnicianSetPasswordObj=new TechnicianSetPasswordObj();
        passwordChangeObj.technicianId = this.technicianId;
        passwordChangeObj.password = data.password;

        this.dataService.updateTechnicianPassword(passwordChangeObj).subscribe(res=>{
          this.toastr.successToastr('Password successfully reset', 'Passwort Reset');
        });
      }
    }, (reason) => {
    });
  }


  editTechnician(){
    this.closePopup();
    this.router.navigate(['home/technicians/edit/',this.technicianId]);
  }

  public loadRecords(){
    this.dataService.getTechnicianById(this.technicianId).subscribe(res=>{
      this.technicianObj = res;
    });
  }

}
