<h3>Appliance & error data</h3>
<div class="wizardformsection" [formGroup]="form">
    <div class="wizardformpartbox">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group form-group-horizontal">
                    <label>Number of appliances<span class="required">*</span></label>
                    <input type="number" class="form-control" tabindex="1"  style="width:70px;" formControlName="noOfAppliances" />
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group form-group-horizontal">
                    <label>Order-ID<span class="required">*</span></label>
                    <input type="text" class="form-control" tabindex="2" style="width:150px;" formControlName="fixFirstOrderID" />
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformpartbox">
        <h4>Appliance data</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Appliance<span class="required">*</span></label>
                    <select class="form-control" tabindex="3" formControlName="applianceType">
                        <option value="">Select appliance</option>
                        <option *ngFor="let item of lstAppliances" [value]="item.value">{{item.text}}</option>
                      </select>
                </div>
                <div class="form-group">
                    <label>Model number</label>
                    <input type="text" class="form-control" tabindex="5" formControlName="applianceModelNumber"/>
                </div>
                <div class="form-group">
                    <label>Index number</label>
                    <input type="text" class="form-control" tabindex="7" formControlName="applianceIndexNumber"/>
                </div>
                <div class="form-group">
                    <label>Contract number</label>
                    <input type="text" class="form-control" tabindex="9" formControlName="applianceContractNumber"/>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Brand<span class="required">*</span></label>
                    <select class="form-control" tabindex="4" formControlName="applianceBrand">
                        <option value="">Select brand</option>
                        <option *ngFor="let item of lstBrands" [value]="item.value">{{item.text}}</option>
                      </select>
                </div>
                <div class="form-group">
                    <label>Serial number</label>
                    <input type="text" class="form-control" tabindex="6" formControlName="applianceSerialNumber"/>
                </div>
                <div class="form-group">
                    <label>Age (years)</label>
                    <input type="number" class="form-control" tabindex="8" formControlName="applianceAge"/>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" tabindex="10" id="chkApplianceIsWarranty" formControlName="applianceIsWarranty">
                        <label class="form-check-label" for="chkApplianceIsWarranty">Warranty Available</label>
                      </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformpartbox" *ngIf="isWarranty">
        <h4>Warranty information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Warranty contract number</label>
                    <input type="text" class="form-control" tabindex="11" formControlName="applianceWarrantyContractNo"/>
                </div>
                <div class="form-group">
                    <label>Notes</label>
                    <input type="text" class="form-control" tabindex="13" formControlName="applianceWarrantyNotes"/>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Details</label>
                    <input type="text" class="form-control" tabindex="12" formControlName="applianceWarrantyDetails"/>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformpartbox">
        <h4>Additional details</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Installation</label>
                    <input type="text" class="form-control" tabindex="14" formControlName="applianceInstallationDetail"/>
                </div>
                <div class="form-group">
                    <label>Purchase date</label>
                    <div class="input-group" style="width:240px;">
                        <input class="form-control" tabindex="16" ngbDatepicker formControlName="appliancePurchaseDate" #appliancePurchaseDate="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary fa fa-calendar" style="font-size:20px;" (click)="appliancePurchaseDate.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Level of usage</label>
                    <select class="form-control" tabindex="15" formControlName="applianceLevelOfUsage">
                        <option value="">Select usage level</option>
                        <option *ngFor="let item of lstUsageLevels" [value]="item.value">{{item.text}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Price new (€)</label>
                    <input type="number"  class="form-control" tabindex="17" formControlName="applianceNewPrice"/>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformpartbox">
        <h4>Error data</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Problem<span class="required">*</span></label>
                    <input type="text" class="form-control" tabindex="18"  formControlName="errorTitle"/>
                </div>
                <div class="form-group">
                    <label>Details<span class="required">*</span></label>
                    <textarea type="text" class="form-control" tabindex="20" rows="2" cols="20" formControlName="errorDetails"></textarea>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Error code</label>
                    <select class="form-control" tabindex="19" formControlName="errorCode">
                        <option value="">Select Error code</option>
                        <option *ngFor="let item of lstErrorCodes" [value]="item.value">{{item.text}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Notes</label>
                    <textarea type="text" class="form-control" tabindex="21" rows="2" cols="20" formControlName="errorNotes"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformpartbox">
        <h3>Appliance insights</h3>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Recall information</label>
                    <input type="text" class="form-control" tabindex="22" formControlName="insightRecallInfo"/>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Other</label>
                    <input type="text" class="form-control" tabindex="23" formControlName="insightOther"/>
                </div>
            </div>
        </div>
    </div>
</div>