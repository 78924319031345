<div class="techselectionbox" [class.selected]="isAvailable"  [@slideInOut]="isExpanded ? 'expanded' : 'normal'" [formGroup]="form">
    <div class="form-check techselectionheadbox">
        <label class="form-check-label">
            <input id="chk1" type="checkbox" formControlName="isAvailable" />
            <span>{{technicianObj.firstName}} {{technicianObj.lastName}}</span>
        </label>
        <span *ngIf="isAvailable" class="expansionico fa" [class.fa-chevron-down]="!isExpanded" [class.fa-chevron-up]="isExpanded" (click)="toggleExpand()"></span>
    </div>
    <div *ngIf="isExpanded">
        <div class="form-check chkselectwholeday">
            <label class="form-check-label">
                <input id="chk2" type="checkbox" formControlName="isWholeDaySelected"/>
                <span>Available whole day</span>
            </label>
        </div>
        <div *ngIf="!isWholeDaySelected">
            <div class="techtimeslotarrbox" *ngFor="let item of lstTS.controls; let i=index" [formGroup]="item">
                <div class="timeslotbox">
                    <select class="form-control-sm" formControlName="tsFrom">
                        <option value="">-</option>
                        <option *ngFor="let item of lstTimeSlots" [value]="item.value">{{item.text}}</option>
                    </select>
                </div>
                <div class="timeslotseparator">-</div>
                <div class="timeslotbox">
                    <select class="form-control-sm" formControlName="tsTo">
                        <option value="">-</option>
                        <option *ngFor="let item of lstTimeSlots" [value]="item.value">{{item.text}}</option>
                    </select>
                </div>
                <div class="deleteicon" (click)="removeTS(i)">
                    <span class="fa fa-trash"></span>
                </div>
            </div>
            <a class="addtslink" (click)="addTS()"><span class="fa fa-plus-circle"></span> Add new timeslot</a>
        </div>
    </div>
</div>