import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CollectionUtil } from 'src/app/shared/ccc/collections';
import { DropDownObj } from 'src/app/shared/models/commonobj';

@Component({
  selector: 'technician-skills-details',
  templateUrl: './technician-skills-details.component.html',
  styleUrls: ['./technician-skills-details.component.css']
})
export class TechnicianSkillsDetailsComponent implements OnInit {

  @Input()
  public form:FormGroup = null;

  readonly lstLanguages: string[] = CollectionUtil.lstLanguages;
  readonly lstCertifications: DropDownObj[] = CollectionUtil.lstTechnicianCertifications;
  readonly lstBrands: DropDownObj[] = CollectionUtil.lstBrands;
  readonly lstAppliances: DropDownObj[] = CollectionUtil.lstAppliances;
  
  constructor() { }

  ngOnInit() {
  }

}
