export module CollectionUtil {
    export let lstCountries = ["Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"];

    export let lstLanguages = ["English", "Spanish", "French", "Dutch", "German"];

    export let lstAppliances = [
        { value: "Washing machine", text: "Washing machine" },
        { value: "Dryer", text: "Dryer" },
        { value: "Dishwasher", text: "Dishwasher" },
        { value: "Oven / Stove", text: "Oven / Stove" },
        { value: "Hob", text: "Hob" },
        { value: "Fridge", text: "Fridge" },
        { value: "Others", text: "Others" }
      ];

    export let lstBrands = [
        { value: "AEG", text: "AEG" },
        { value: "Amica", text: "Amica" },
        { value: "Bauknecht", text: "Bauknecht" },
        { value: "Beko", text: "Beko" },
        { value: "Bomann", text: "Bomann" },
        { value: "Bosch", text: "Bosch" },
        { value: "Candy", text: "Candy" },
        { value: "Constructa", text: "Constructa" },
        { value: "Electrolux", text: "Electrolux" },
        { value: "Gaggenau", text: "Gaggenau" },
        { value: "Gorenje", text: "Gorenje" },
        { value: "Grundig", text: "Grundig" },
        { value: "Haier", text: "Haier" },
        { value: "Hanseatic", text: "Hanseatic" },
        { value: "Ignis", text: "Ignis" },
        { value: "Ikea", text: "Ikea" },
        { value: "Indesit", text: "Indesit" },
        { value: "Krups", text: "Krups" },
        { value: "LG", text: "LG" },
        { value: "Liebherr", text: "Liebherr" },
        { value: "Miele", text: "Miele" },
        { value: "Neff", text: "Neff" },
        { value: "Panasonic", text: "Panasonic" },
        { value: "Privileg", text: "Privileg" },
        { value: "Samsung", text: "Samsung" },
        { value: "Severin", text: "Severin" },
        { value: "Sharp", text: "Sharp" },
        { value: "Siemens", text: "Siemens" },
        { value: "Whirlpool", text: "Whirlpool" },
        { value: "Sonstige", text: "Sonstige" }
      ];

      export let lstErrorCodes = [
        { value: "F10", text: "F10" },
        { value: "E10", text: "E10" },
        { value: "D10", text: "D10" },
        { value: "C10", text: "C10" },
        { value: "B10", text: "B10" },
        { value: "A10", text: "A10" }
      ];

      export let lstUsageLevel = [
        { value: "Daily", text: "Daily" },
        { value: "Weekly", text: "Weekly" },
        { value: "Monthly", text: "Monthly" },
        { value: "Quarterly", text: "Quarterly" },
        { value: "Yearly", text: "Yearly" }
      ];

      export let lstSPRoles = [
        { value: "Owner", text: "Owner" },
        { value: "CEO", text: "CEO" },
        { value: "Manager", text: "Manager" },
      ];

      export let lstTechnicianRoles = [
        { value: "Service Technician", text: "Service Technician" },
        { value: "Senior Technician", text: "Senior Technician" },
        { value: "External Technician", text: "External Technician" }
      ];

      export let lstTechnicianCertifications = [
        { value: "Certification 1", text: "Certification 1" },
        { value: "Certification 2", text: "Certification 2" },
        { value: "Certification 3", text: "Certification 3" }
      ];

      export let lstStatusTypes = [
        { value: "new", text: "new" },
        { value: "schedule", text: "schedule" },
        { value: "reschedule", text: "reschedule" },
        { value: "inprogress", text: "inprogress" },
        { value: "complete", text: "complete" },
        { value: "cancel", text: "cancel" }
      ];


      export let lstTimeSlots = [
        { value: "12:00 am", text: "12:00 am" },
        { value: "12:15 am", text: "12:15 am" },
        { value: "12:30 am", text: "12:30 am" },
        { value: "12:45 am", text: "12:45 am" },

        { value: "01:00 am", text: "01:00 am" },
        { value: "01:15 am", text: "01:15 am" },
        { value: "01:30 am", text: "01:30 am" },
        { value: "01:45 am", text: "01:45 am" },

        { value: "02:00 am", text: "02:00 am" },
        { value: "02:15 am", text: "02:15 am" },
        { value: "02:30 am", text: "02:30 am" },
        { value: "02:45 am", text: "02:45 am" },

        { value: "03:00 am", text: "03:00 am" },
        { value: "03:15 am", text: "03:15 am" },
        { value: "03:30 am", text: "03:30 am" },
        { value: "03:45 am", text: "03:45 am" },

        { value: "04:00 am", text: "04:00 am" },
        { value: "04:15 am", text: "04:15 am" },
        { value: "04:30 am", text: "04:30 am" },
        { value: "04:45 am", text: "04:45 am" },

        { value: "05:00 am", text: "05:00 am" },
        { value: "05:15 am", text: "05:15 am" },
        { value: "05:30 am", text: "05:30 am" },
        { value: "05:45 am", text: "05:45 am" },

        { value: "06:00 am", text: "06:00 am" },
        { value: "06:15 am", text: "06:15 am" },
        { value: "06:30 am", text: "06:30 am" },
        { value: "06:45 am", text: "06:45 am" },

        { value: "07:00 am", text: "07:00 am" },
        { value: "07:15 am", text: "07:15 am" },
        { value: "07:30 am", text: "07:30 am" },
        { value: "07:45 am", text: "07:45 am" },

        { value: "08:00 am", text: "08:00 am" },
        { value: "08:15 am", text: "08:15 am" },
        { value: "08:30 am", text: "08:30 am" },
        { value: "08:45 am", text: "08:45 am" },

        { value: "09:00 am", text: "09:00 am" },
        { value: "09:15 am", text: "09:15 am" },
        { value: "09:30 am", text: "09:30 am" },
        { value: "09:45 am", text: "09:45 am" },

        { value: "10:00 am", text: "10:00 am" },
        { value: "10:15 am", text: "10:15 am" },
        { value: "10:30 am", text: "10:30 am" },
        { value: "10:45 am", text: "10:45 am" },

        { value: "11:00 am", text: "11:00 am" },
        { value: "11:15 am", text: "11:15 am" },
        { value: "11:30 am", text: "11:30 am" },
        { value: "11:45 am", text: "11:45 am" },


        { value: "12:00 pm", text: "12:00 pm" },
        { value: "12:15 pm", text: "12:15 pm" },
        { value: "12:30 pm", text: "12:30 pm" },
        { value: "12:45 pm", text: "12:45 pm" },

        { value: "01:00 pm", text: "01:00 pm" },
        { value: "01:15 pm", text: "01:15 pm" },
        { value: "01:30 pm", text: "01:30 pm" },
        { value: "01:45 pm", text: "01:45 pm" },

        { value: "02:00 pm", text: "02:00 pm" },
        { value: "02:15 pm", text: "02:15 pm" },
        { value: "02:30 pm", text: "02:30 pm" },
        { value: "02:45 pm", text: "02:45 pm" },

        { value: "03:00 pm", text: "03:00 pm" },
        { value: "03:15 pm", text: "03:15 pm" },
        { value: "03:30 pm", text: "03:30 pm" },
        { value: "03:45 pm", text: "03:45 pm" },

        { value: "04:00 pm", text: "04:00 pm" },
        { value: "04:15 pm", text: "04:15 pm" },
        { value: "04:30 pm", text: "04:30 pm" },
        { value: "04:45 pm", text: "04:45 pm" },

        { value: "05:00 pm", text: "05:00 pm" },
        { value: "05:15 pm", text: "05:15 pm" },
        { value: "05:30 pm", text: "05:30 pm" },
        { value: "05:45 pm", text: "05:45 pm" },

        { value: "06:00 pm", text: "06:00 pm" },
        { value: "06:15 pm", text: "06:15 pm" },
        { value: "06:30 pm", text: "06:30 pm" },
        { value: "06:45 pm", text: "06:45 pm" },

        { value: "07:00 pm", text: "07:00 pm" },
        { value: "07:15 pm", text: "07:15 pm" },
        { value: "07:30 pm", text: "07:30 pm" },
        { value: "07:45 pm", text: "07:45 pm" },

        { value: "08:00 pm", text: "08:00 pm" },
        { value: "08:15 pm", text: "08:15 pm" },
        { value: "08:30 pm", text: "08:30 pm" },
        { value: "08:45 pm", text: "08:45 pm" },

        { value: "09:00 pm", text: "09:00 pm" },
        { value: "09:15 pm", text: "09:15 pm" },
        { value: "09:30 pm", text: "09:30 pm" },
        { value: "09:45 pm", text: "09:45 pm" },

        { value: "10:00 pm", text: "10:00 pm" },
        { value: "10:15 pm", text: "10:15 pm" },
        { value: "10:30 pm", text: "10:30 pm" },
        { value: "10:45 pm", text: "10:45 pm" },

        { value: "11:00 pm", text: "11:00 pm" },
        { value: "11:15 pm", text: "11:15 pm" },
        { value: "11:30 pm", text: "11:30 pm" },
        { value: "11:45 pm", text: "11:45 pm" }
      ];
}