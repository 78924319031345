<h3>Skills</h3>
<div class="wizardformsection" [formGroup]="form">
    <div class="wizardformpartbox">
        <h4>Contact information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Languages</label>
                    <ng-select [items]="lstLanguages" tabindex="1" [multiple]="true" [selectableGroup]="true" [closeOnSelect]="false" formControlName="skillLanguages"></ng-select>
                </div>
                <div class="form-group">
                    <label>Brands expertise</label>
                    <ng-select [items]="lstBrands" tabindex="3" [multiple]="true" bindValue="value" bindLabel="text"  [selectableGroup]="true" [closeOnSelect]="false" formControlName="skillBrands"></ng-select>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input type="checkbox" tabindex="5" class="form-check-input" id="chkPhysicalResilience" formControlName="skillPhysicalResilience">
                        <label class="form-check-label" for="chkPhysicalResilience">Physical resilience</label>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Certifications</label>
                    <ng-select [items]="lstCertifications" tabindex="2" [multiple]="true" bindValue="value" bindLabel="text" [selectableGroup]="true" [closeOnSelect]="false" formControlName="skillCertifications"></ng-select>
                </div>
                <div class="form-group">
                    <label>Appliance expertise</label>
                    <ng-select [items]="lstAppliances" tabindex="4" [multiple]="true" bindValue="value" bindLabel="text" [selectableGroup]="true" [closeOnSelect]="false" formControlName="skillAppliances"></ng-select>
                </div>
            </div>
        </div>
    </div>

</div>