import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'sp-company-profile',
  templateUrl: './spcompany-profile.component.html',
  styleUrls: ['./spcompany-profile.component.css']
})
export class SPCompanyProfileComponent implements OnInit {

  @Input()
  public serviceProviderId: number = 0;
  
  @Input()
  public form:FormGroup = null;
  
  constructor(public fb:FormBuilder) { 
  }

  ngOnInit() {
  }

}
