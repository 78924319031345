<div class="wizardpage">
    <div class="wizardhead">
        <h2>Customer Details</h2>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="wizardbodypopup customscrollbar">
        <div class="wizardtopinfobox" *ngIf="customerObj">
            <div class="topinfocol1">
                <h3>{{customerObj.firstName}} {{customerObj.lastName}}</h3>
                <p class="pmain">Private</p>
                <p>{{customerObj.visitAddressHouseNo}} {{customerObj.visitAddressCity}}</p>
            </div>
            <div class="topinfocol2">
                <p class="psmall" *ngIf="customerObj.createdDate">Customer since: {{customerObj.createdDate | customdate: 'DD.MM.YYYY'}}</p>
            </div>
        </div>
        <div class="wizardinfobox" *ngIf="customerObj">
            <h3>Customer information</h3>
            <h4>Contact information</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Customer:</label>
                        <label class="content">{{customerObj.firstName}} {{customerObj.lastName}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Email:</label>
                        <label class="content">{{customerObj.email}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Languages:</label>
                        <label class="content">{{customerObj.languages}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{customerObj.notes}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Mobile:</label>
                        <label class="content">{{customerObj.mobileNumberExtension}} {{customerObj.mobileNumber}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Whatsapp:</label>
                        <label class="content">{{customerObj.whatsAppContactAllowed}}</label>
                    </div>
                </div>
            </div>
            <h4>Visit address</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Address:</label>
                        <label class="content">{{customerObj.visitAddressStreetNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{customerObj.visitAddressNotes}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">City:</label>
                        <label class="content">{{customerObj.visitAddressCity}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Country:</label>
                        <label class="content">{{customerObj.visitAddressCountry}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Number:</label>
                        <label class="content">{{customerObj.visitAddressHouseNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Addition:</label>
                        <label class="content">{{customerObj.visitAddressAdditionInfo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Elevator:</label>
                        <label class="content">{{customerObj.visitAddressIsElevator}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Postal code:</label>
                        <label class="content">{{customerObj.visitAddressPostalCode}}</label>
                    </div>
                </div>
            </div>
            <h4>Billing address</h4>
            <p *ngIf="customerObj.isBillingAddressSame">Billing address is the same as the Visit address </p>
            <div class="row" *ngIf="!customerObj.isBillingAddressSame">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Address:</label>
                        <label class="content">{{customerObj.billingAddressStreetNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Notes:</label>
                        <label class="content">{{customerObj.billingAddressNotes}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">City:</label>
                        <label class="content">{{customerObj.billingAddressCity}}</label>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="caption">Number:</label>
                        <label class="content">{{customerObj.billingAddressHouseNo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Addition:</label>
                        <label class="content">{{customerObj.billingAddressAdditionInfo}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Postal code:</label>
                        <label class="content">{{customerObj.billingAddressPostalCode}}</label>
                    </div>
                    <div class="form-group">
                        <label class="caption">Country:</label>
                        <label class="content">{{customerObj.billingAddressCountry}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="orderinfobox">
            <div class="boxpadding">
                <h3>Requests and orders</h3>
            </div>
            <div class="orderlistingbox" *ngFor="let item of lstOrders">
                <div class="boxpadding">
                    <div class="row1">
                        <div class="icotoolbox">
                            <div class="icotool"></div>
                            <div class="tooltxt">
                                <h3>{{item.visitType}}</h3>
                                <p class="pmain">FixFirst</p>
                            </div>
                        </div>
                        <div>

                            <span class="orderstatusbadge successbadge">New</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="caption">Request date:</label>
                                <label class="content" *ngIf="item.scheduleDate">{{item.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} @ {{item.scheduleTime}}</label>
                            </div>
                            <div class="form-group">
                                <label class="caption">Appliance:</label>
                                <label class="content">{{item.applianceType}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="caption">FixFirst-ID:</label>
                                <label class="content">{{item.fixFirstId}}</label>
                            </div>
                            <div class="form-group">
                                <label class="caption">Provider:</label>
                                <label class="content">{{item.spFirstName}} {{item.spLastName}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardfooter">
        <div></div>
        <div>
            <button class="btnfooter" (click)="editCustomer()"><span class="fa fa-pencil"></span>Edit</button>
        </div>
    </div>
</div>