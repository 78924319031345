<h3>Technician details</h3>
<div class="wizardformsection" [formGroup]="form">
    <div class="wizardformpartbox">
        <h4>Contact information</h4>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label>First name<span class="required">*</span></label>
                    <input type="text" class="form-control" tabindex="1" formControlName="firstName" />
                </div>
                <div class="form-group">
                    <label>Email<span class="required">*</span></label>
                    <input type="text" class="form-control" tabindex="3" formControlName="email" />
                </div>
                <div class="form-group">
                    <label>Organization<span class="required">*</span></label>
                    <input type="text" class="form-control" tabindex="6" formControlName="organization" />
                </div>
                <div class="form-group">
                    <label>Role<span class="required">*</span></label>
                    <select class="form-control" tabindex="8" formControlName="role">
                        <option value="">Select Role</option>
                        <option *ngFor="let item of lstRoles" [value]="item.value">{{item.text}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label>Last name<span class="required">*</span></label>
                    <input type="text" class="form-control" tabindex="2" formControlName="lastName"/>
                </div>
                <div class="form-group">
                    <div class="multicontrolbox">
                        <div>
                            <label>Extension<span class="required">*</span></label>
                            <input type="text" class="form-control" tabindex="4" formControlName="mobileNumberExtension"/>
                        </div>
                        <div>
                            <label>Phone number<span class="required">*</span></label>
                            <input type="text" class="form-control" tabindex="5" formControlName="mobileNumber"/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" tabindex="7" id="exampleCheck1" formControlName="whatsAppContactAllowed">
                        <label class="form-check-label" for="exampleCheck1">Allow contact via Whatsapp</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div class="wizardformpartbox">
    <h4>Address information</h4>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>Address<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="9" formControlName="addressStreet"/>
            </div>
            <div class="form-group">
                <label>Addition</label>
                <input type="text" class="form-control" tabindex="11" formControlName="addressAdditionInfo"/>
            </div>
            <div class="form-group">
                <label>Country<span class="required">*</span></label>
                <select class="form-control" tabindex="13" formControlName="addressCountry">
                    <option value="">Select Country</option>
                    <option *ngFor="let item of lstCountries" [value]="item">{{item}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>City<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="15" formControlName="addressCity"/>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>Number<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="10" formControlName="addressHouseNo"/>
            </div>
            <div class="form-group">
                <label>Notes</label>
                <input type="text" class="form-control" tabindex="12" formControlName="addressNotes"/>
            </div>
            <div class="form-group">
                <label>Postal code<span class="required">*</span></label>
                <input type="text" class="form-control" tabindex="14" formControlName="addressPostalCode"/>
            </div>
        </div>
    </div>
</div>

</div>