<h3>Visit type</h3>
<div class="wizardformsection">
    <div class="wizardformpartbox"  [formGroup]="form">
        <h4>Select visit type <span class="required">*</span></h4>
        <div class="form-group">
            <div class="form-check borderedrbbox" [class.selected]="form.value.visitType == 'On-Site'">
                <label class="form-check-label" for="rdOnSite">On-Site</label>
                <input id="rdOnSite" name="visitType" type="radio" value="On-Site" formControlName="visitType" />
            </div>
        </div>
        <div class="form-group">
            <div class="form-check borderedrbbox" (click)="notImplementedMsg()" [class.selected]="form.value.visitType == 'Phone-Call'">
                <label class="form-check-label" for="rdPhoneCall">Phone-Call</label>
                <input id="rdPhoneCall" name="visitType" type="radio" value="Phone-Call" disabled="true" formControlName="visitType" />
            </div>
        </div>
        <div class="form-group">
            <div class="form-check borderedrbbox" (click)="notImplementedMsg()" [class.selected]="form.value.visitType == 'Video-Call'">
                <label class="form-check-label" for="rdVideoCall">Video-Call</label>
                <input id="rdVideoCall" name="visitType" type="radio" value="Video-Call" disabled="true" formControlName="visitType" />
            </div>
        </div>
    </div>
</div>