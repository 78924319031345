import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
import { CollectionUtil } from 'src/app/shared/ccc/collections';
import { WholeDayConstant } from 'src/app/shared/ccc/constants';
import { DropDownObj } from 'src/app/shared/models/commonobj';
import { TechnicianAvalabilityResponseObj } from 'src/app/shared/models/technician';

@Component({
  selector: 'technician-cell',
  templateUrl: './technician-cell.component.html',
  styleUrls: ['./technician-cell.component.css'],
  animations: [
    trigger('slideInOut', [
      state('normal', style({
        height: 'auto'
      })),
      state('expanded', style({
        overflow: 'auto',
        height: '300px'
      })),
      transition('normal => expanded', animate('400ms ease-out')),
      transition('expanded => normal', animate('400ms ease-out'))
    ])
  ]
})
export class TechnicianCellComponent implements OnInit {
  
  public isExpanded: boolean = false;

  @Input()
  public form:FormGroup=null;

  @Input()
  public technicianObj: TechnicianAvalabilityResponseObj = null;

  readonly lstTimeSlots: DropDownObj[] = CollectionUtil.lstTimeSlots;

  constructor(private fb: FormBuilder, private toastr: ToastrManager) {
  }

  ngOnInit() {

    this.form.controls['isAvailable'].valueChanges.subscribe(isSelected=>{
        this.isExpanded=this.isAvailable;
    });
    this.form.controls['isWholeDaySelected'].valueChanges.subscribe(isSelected=>{
      this.lstTS.clear();
      if(isSelected)
      {
        this.lstTS.push(this.fb.group({
          tsFrom:[WholeDayConstant.TSFrom, [Validators.required]],
          tsTo:[WholeDayConstant.TSTo, [Validators.required]],
        }));        
      }
    });

    console.log(this.form);
  }

  public get lstTS():FormArray {
    return this.form.get('lstTS') as FormArray;
  };

  public get isAvailable():boolean{
    return this.form.controls['isAvailable'].value;
  }

  public get isWholeDaySelected():boolean{
    return this.form.controls['isWholeDaySelected'].value;
  }

  addTS(){
    this.lstTS.push(this.fb.group({
      tsFrom: ['', [Validators.required]],
      tsTo: ['', [Validators.required]]
    }));
  }
  removeTS(index: number)
  {
    this.lstTS.removeAt(index);
  }


  toggleExpand()
  {
    this.isExpanded=!this.isExpanded;
  }

}
