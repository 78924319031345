import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { CustomerObj } from 'src/app/shared/models/customer';
import { OrderListingObj } from 'src/app/shared/models/order';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-view-customer-popup',
  templateUrl: './view-customer-popup.component.html',
  styleUrls: ['./view-customer-popup.component.css']
})
export class ViewCustomerPopupComponent implements OnInit {
  
  @Input()
  public customerId: number = 0;
  public customerObj:CustomerObj=null;
  public lstOrders: OrderListingObj[]=[];

  constructor(public router: Router, public activeModal: NgbActiveModal, private dataService: DataService) { 
  }

  ngOnInit() {
  }

  closePopup(){
    this.activeModal.close();
  }
  editCustomer(){
    this.closePopup();
    this.router.navigate(['home/customers/edit/',this.customerId]);
  }
  public loadRecords(){
    let req1$ = this.dataService.getCustomerById(this.customerId);
    let req2$ = this.dataService.getOrdersByCustomerId(this.customerId);
    forkJoin([req1$, req2$]).subscribe(([customerObj, lstOrders])=>{
      this.customerObj = customerObj;
      this.lstOrders = lstOrders;
    });
  }

}
