import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FirstTimeChangePasswordArg, LoginArg, LoginResponseObj } from '../models/login';
import { GenericResponseObj } from '../models/responseobj';
import { OrderApplianceDataObj, OrderAttachmentObj, OrderCommentObj, OrderCustomerDetailObj, OrderListingArg, OrderListingObj, OrderObj, OrderScheduleSPObj, OrderScheduleTechnicianObj, OrderSparePartSuggestionObj, OrderVisitTypeObj } from '../models/order';
import { GenericAvailabilityResponseObj, TechAvailabilityRequestObj, TechnicianAvailabilityPlainObj, TechnicianAvalabilityResponseObj, TechnicianListingArg, TechnicianListingObj, TechnicianObj, TechnicianPartialUpdateObj, TechnicianSetPasswordObj } from '../models/technician';
import { ServiceProviderObj, SPAvalabilityResponseObj, SPBasicDetailObj, SPCompanyProfileObj, SPDDLObj, SPListingArg, SPListingObj, SPNotesInfoObj, SPProfileObj, SPSetPasswordObj, SPSettingsObj } from '../models/serviceprovider';
import { CustomerListingArg, CustomerListingObj, CustomerObj, CustomerPartialUpdateObj } from '../models/customer';
import { CurrentOrderStatusObj, NewOrderStatusArg, OrderStatusHistoryObj, UnreadOrderStatusObj } from '../models/orderstatus';
import { DasboardDateRangeObj, DashboardDateRangeArg, DashboardFormulaCountArg, DashboardFormulaCountObj, DashboardTotalCountArg, DashboardTotalCountObj } from '../models/dashboard';
import { WorkReportObj } from '../models/workreport';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  public login(arg: LoginArg): Observable<LoginResponseObj>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/login`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
        let authResponse: LoginResponseObj = new LoginResponseObj();
        
        authResponse.responseCode = response.responseCode;

        if(authResponse.responseCode == "00")
        {
            authResponse.userId = response.responseData.userId;
            authResponse.userName = response.responseData.userName;
            authResponse.userType = response.responseData.userType;
            authResponse.isPasswordChanged = response.responseData.isPasswordChanged;
            authResponse.country = response.responseData.country;
            authResponse.brand = response.responseData.brand;
        }

        return authResponse
      }));
  
      return responseMapping$;
  }
  public changePasswordFirstTime(arg: FirstTimeChangePasswordArg): Observable<LoginResponseObj>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/changePasswordFirstTime`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
        let authResponse: LoginResponseObj = new LoginResponseObj();
        
        authResponse.responseCode = response.responseCode;

        if(authResponse.responseCode == "00")
        {
            authResponse.userId = response.responseData.userId;
            authResponse.userName = response.responseData.userName;
            authResponse.userType = response.responseData.userType;
            authResponse.isPasswordChanged = response.responseData.isPasswordChanged;
            authResponse.country = response.responseData.country;
            authResponse.brand = response.responseData.brand;
        }

        return authResponse
      }));
  
      return responseMapping$;
  }

  public dasboardDateRangeGraph(arg: DashboardDateRangeArg): Observable<DasboardDateRangeObj[]>{
    console.log(arg);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/dashboardDateRangeGraph`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].DashboardDateRangeGraph as DasboardDateRangeObj[];
      }));
  
      return responseMapping$;
  }
  public dashboardTotalCountGraph(arg: DashboardTotalCountArg): Observable<DashboardTotalCountObj>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/dashboardTotalCountGraph`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      let totalCountObj= response.responseData[0].DashboardTotalCountGraph[0] as DashboardTotalCountObj;
      return totalCountObj;
      }));
  
      return responseMapping$;
  }
  public dashboardFormulaCount(arg: DashboardFormulaCountArg): Observable<DashboardFormulaCountObj>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/dashboardFormulaCount`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      let totalCountObj= response.responseData[0].DashboardFormulaCount[0] as DashboardFormulaCountObj;
      return totalCountObj;
      }));
  
      return responseMapping$;
  }
  

  public getOrderListing(arg: OrderListingArg): Observable<OrderListingObj[]>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getOrdersListing`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].OrderListing as OrderListingObj[];
      }));
  
      return responseMapping$;
  }

  public getOrderById(orderId: number): Observable<OrderObj>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getOrderById/${orderId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetOrderObj as OrderObj;
      }));
  
      return responseMapping$;
  }
  public createEmptyOrder(customerCareId: number):Observable<number>{
    let arg={
      customerCareId: customerCareId
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/createEmptyOrder`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      let orderId: number = response.responseData[0].CreateEmptyOrder as number;
      return orderId;
      }));
  
      return responseMapping$;
  }

  public updateOrderVisitType(arg: OrderVisitTypeObj):Observable<any>{
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateOrderVisitType`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public updateOrderCustomerDetail(arg: OrderCustomerDetailObj):Observable<any>{
    let arg2 = JSON.stringify(arg,function(key,value){
      if(value === null) {
        if(!(['whatsAppContactAllowed','visitAddressIsElevator', 'isBillingAddressSame'].find(x=>x==key)))
        {
         return "";
        }
      }
      if('whatsAppContactAllowed' == key || 'visitAddressIsElevator' == key || 'isBillingAddressSame' == key)
      {
        return false;
      }
     return value;
   });

    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateOrderCustomer`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public updateOrderApplianceData(arg: OrderApplianceDataObj):Observable<any>{
    
    let arg2 = JSON.stringify(arg,function(key,value){
       if(value === null) {
         if(!(['applianceIsWarranty','applianceAge','applianceNewPrice'].find(x=>x==key)))
         {
          return "";
         }
       }
      return value;
    });
    console.log(arg2);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateOrderAppliance`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }

  public updateOrderAttachment(arg: OrderAttachmentObj):Observable<any>{
    
    let attachmentArg: any=Object.assign({},arg);
    attachmentArg.lstSpareParts=JSON.stringify(arg.lstSpareParts);

    let arg2 = JSON.stringify(attachmentArg,function(key,value){
      if(value === null) {
        if(!([''].find(x=>x==key)))
        {
         return "";
        }
      }
     return value;
   });

    

    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateOrderAttachment`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public updateOrderScheduleSP(arg: OrderScheduleSPObj):Observable<any>
  {
    console.log(arg);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateOrderScheduleSP`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public updateOrderScheduleTechnician(arg: OrderScheduleTechnicianObj):Observable<any>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateOrderScheduleTechnician`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }

  public getOrderCurrentStatusObj(orderId: number): Observable<CurrentOrderStatusObj>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getOrderCurrentStatus/${orderId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetOrderCurrentStatusObj as CurrentOrderStatusObj;
      }));
  
      return responseMapping$;
  }
  public createNewOrderStatus(newOrderStatusArg: NewOrderStatusArg):Observable<GenericResponseObj>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/createNewOrderStatus`,newOrderStatusArg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{return response}));
    return responseMapping$;
  }
  public getUnreadOrderStatuses(userType: string, userId: number):Observable<UnreadOrderStatusObj[]>
  {
    let arg=
    {
      userType:userType,
      userId: userId
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getUnreadOrderStatuses`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetUnReadOrderStatuses as UnreadOrderStatusObj[];
      }));
    return responseMapping$;
  }
  public readOrderStatus(orderStatusId: number):Observable<any>{
    let arg = {
      orderStatusId: orderStatusId
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/readOrderStatus`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public getOrderStatusHistory(orderId: number):Observable<OrderStatusHistoryObj[]>
  {
    let arg=
    {
      orderId:orderId
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getOrderStatuses`,arg,{}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));
    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetOrderStatuses as OrderStatusHistoryObj[];
      }));
    return responseMapping$;
  }


  public getOrderSpareParts(orderId: number): Observable<OrderSparePartSuggestionObj[]>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getOrderSpareParts/${orderId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetOrderSpareParts as OrderSparePartSuggestionObj[];
      }));
  
      return responseMapping$;
  }

  public getOrderComments(orderId: number): Observable<OrderCommentObj[]>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getOrderComments/${orderId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetOrderComments as OrderCommentObj[];
      }));
  
      return responseMapping$;
  }

  

  public getCustomerListing(arg: CustomerListingArg): Observable<CustomerListingObj[]>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getCustomerListing`, arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].CustomerListing as CustomerListingObj[];
      }));
  
      return responseMapping$;
  }
  public getCustomerById(customerId: number): Observable<CustomerObj>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getCustomerById/${customerId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetCustomerObj as CustomerObj;
      }));
  
      return responseMapping$;
  }
  public getOrdersByCustomerId(customerId: number): Observable<OrderListingObj[]>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getOrdersByCustomerId/${customerId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetOrdersByCustomerId as OrderListingObj[];
      }));
  
      return responseMapping$;
  }
  public partialUpdateCustomer(partialUpdateObj: CustomerPartialUpdateObj)
  {
    let arg: any={
      customerId:partialUpdateObj.customerId,
      stepType: partialUpdateObj.stepType
    };

    switch(partialUpdateObj.stepType)
    {
      case 'CONTACT_DETAILS':
        arg.customerObj = JSON.stringify([partialUpdateObj.contactDetailObj],function(key,value){
          if(value === null) {
            if(!(['whatsAppContactAllowed'].find(x=>x==key)))
            {
             return "";
            }
            if('whatsAppContactAllowed' == key)
            {
              return false;
            }
          }
         return value;
        });
        break;
      case 'ADDRESS_DETAILS':
        arg.customerObj = JSON.stringify([partialUpdateObj.addressDetailObj],function(key,value){
          if(value === null) {
            if(!(['visitAddressIsElevator', 'isBillingAddressSame'].find(x=>x==key)))
            {
             return "";
            }
            if('visitAddressIsElevator' == key || 'isBillingAddressSame' == key)
            {
              return false;
            }
          }
         return value;
        });
        break;
    }
    console.log(arg);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/partialUpdateCustomer`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
  
      return responseMapping$;
  }
  






  public getTechnicianListing(arg: TechnicianListingArg): Observable<TechnicianListingObj[]>
  {
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getTechnicianListing`,arg,{} ).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].TechnicianListing as TechnicianListingObj[];
      }));
  
      return responseMapping$;
  }
  public getTechnicianById(technicianId: number): Observable<TechnicianObj>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getTechnicianById/${technicianId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetTechnicianObj as TechnicianObj;
      }));
  
      return responseMapping$;
  }
  public partialUpdateTechncian(partialUpdateObj: TechnicianPartialUpdateObj):Observable<number>
  {
    let arg: any={
      technicianId:partialUpdateObj.technicianId,
      stepType: partialUpdateObj.stepType
    };

    switch(partialUpdateObj.stepType)
    {
      case 'PERSONAL_DETAILS':
        arg.technicianObj = JSON.stringify([partialUpdateObj.personalDetailObj],function(key,value){
          if(value === null) {
            if(!(['whatsAppContactAllowed'].find(x=>x==key)))
            {
             return "";
            }
            if('whatsAppContactAllowed' == key)
            {
              return false;
            }
          }
         return value;
        });
        break;
      case 'SKILL_DETAILS':
        arg.technicianObj = JSON.stringify([partialUpdateObj.skillDetailObj],function(key,value){
          if(value === null) {
            if(!(['skillPhysicalResilience'].find(x=>x==key)))
            {
             return "";
            }
          }
         return value;
        });
        break;
      case 'PROFILE_DETAILS':
        arg.technicianObj = JSON.stringify([partialUpdateObj.profileDetailObj],function(key,value){
          if(value === null) {
            if(!([].find(x=>x==key)))
            {
             return "";
            }
          }
         return value;
        });
        break;
        case 'NOTES_DETAILS':
          arg.technicianObj = JSON.stringify([partialUpdateObj.notesDetailObj],function(key,value){
            if(value === null) {
              if(!([].find(x=>x==key)))
              {
               return "";
              }
            }
           return value;
          });
          break;
    }

    console.log(arg);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/partialUpdateTechnician`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].UpdateTechnicianPartial as number;
    }));
  
      return responseMapping$;
  }
  public updateTechnicianPassword(arg: TechnicianSetPasswordObj):Observable<any>{
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateTechnicianPassword`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public getAllAvailableTechnicians(spId: number, date: string, tsFrom: string, tsTo: string): Observable<TechnicianAvailabilityPlainObj[]>{
    let arg = 
    {
      spId: spId,
      availabilityDate: date,
      tsFrom: tsFrom,
      tsTo: tsTo
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getAllAvailableTechniciansInDateAndTS`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetAllAvailableTechniciansInDateAndTS as TechnicianAvailabilityPlainObj[];
      }));
  
      return responseMapping$;
  }
  public getAllTechniciansInDate(spId: number, date: string): Observable<TechnicianAvalabilityResponseObj[]>{
    console.log(spId);
    console.log(date);
    let arg = 
    {
      spId: spId,
      availabilityDate: date
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getAllTechniciansInDate`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetAllTechniciansInDate as TechnicianAvalabilityResponseObj[];
      }));
  
      return responseMapping$;
  }
  public updateTechnicianAvailability(spId: number, availabilityDate: string, lstTechnicians: TechAvailabilityRequestObj[])
  {
    let arg: any={
      spId:spId,
      availabilityDate: availabilityDate,
      datajson: JSON.stringify(lstTechnicians)
    };
    console.log(arg);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateTechnicianAvailability`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{}));
  
      return responseMapping$;
  }
  public getTechniciansAvailabilityInDateRange(ccId: number, spId: number, dtFrom: string, dtTo: string): Observable<GenericAvailabilityResponseObj[]>{
    let arg = 
    {
      ccId: ccId,
      spId: spId,
      dateFrom: dtFrom,
      dateTo: dtTo
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getTechniciansAvailabilityInDateRange`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      
      let availabilityArr= response.responseData[0].GetTechniciansLinearAvailabilitiesInDateRange as GenericAvailabilityResponseObj[];

      let filteredArr: GenericAvailabilityResponseObj[] = [];

      if(availabilityArr)
      {
        availabilityArr.forEach(elem=>{
          let existingElem = filteredArr.find(x=>x.availabilityDate == elem.availabilityDate);
          if(!existingElem)
          {
            filteredArr.push({
              availabilityDate: elem.availabilityDate,
              lstTS : elem.lstTS
            });
          }
          else
          {
            elem.lstTS.forEach(x=>{
              if(!existingElem.lstTS.find(el=>el.tsFrom == x.tsFrom && el.tsTo == x.tsTo))
              {
                existingElem.lstTS.push({
                  tsFrom: x.tsFrom,
                  tsTo: x.tsTo
                });
              }
            });
          }
  
        });
      }

      return filteredArr;

      }));
  
      return responseMapping$;
  }



  public getSPListing(arg: SPListingArg): Observable<SPListingObj[]>
  {
    console.log(arg);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getSPListing`, arg , {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      console.log(response);
      return response.responseData[0].SPListing as SPListingObj[];
      }));
  
      return responseMapping$;
  }
  public getSPById(serviceProviderId: number): Observable<ServiceProviderObj>
  {
    let response$ = this.httpClient.get<any>(this.baseUrl + `/getSPById/${serviceProviderId}`).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetServiceProviderObj as ServiceProviderObj;
      }));
  
      return responseMapping$;
  }
  public updateSPBasicDetails(arg: SPBasicDetailObj):Observable<number>{
    arg.userType='SP';

    let arg2 = JSON.stringify(arg,function(key,value){
      if(value === null) {
        if(!(['whatsAppContactAllowed'].find(x=>x==key)))
        {
         return "";
        }
        if('whatsAppContactAllowed' == key)
        {
          return false;
        }
      }
     return value;
    });

    console.log(arg2);

    
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateSPBasicDetails`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      let spId:number = response.responseData;

      return spId;
      }));
  
      return responseMapping$;
  }
  public updateSPCompanyProfile(arg: SPCompanyProfileObj):Observable<any>{
    let arg2 = JSON.stringify(arg,function(key,value){
      if(value === null) {
        if(!([].find(x=>x==key)))
        {
         return "";
        }
      }
     return value;
    });

    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateSPCompanyProfile`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public updateSPNotesInfo(arg: SPNotesInfoObj):Observable<any>{
    let arg2 = JSON.stringify(arg,function(key,value){
      if(value === null) {
        if(!([].find(x=>x==key)))
        {
         return "";
        }
      }
     return value;
    });

    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateSPNotesInfo`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public updateSPPassword(arg: SPSetPasswordObj):Observable<any>{
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateSPPassword`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      }));
  
      return responseMapping$;
  }
  public getSPsAvailability(ccId: number, date: string, tsFrom: string, tsTo: string): Observable<SPAvalabilityResponseObj[]>{
    let arg = 
    {
      ccId: ccId,
      availabilityDate: date,
      tsFrom: tsFrom,
      tsTo: tsTo
    };
    console.log('sp arg', arg);
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getAllAvailableSPsInDateAndTS`,arg, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData[0].GetAllAvailableSPsInDateAndTS as SPAvalabilityResponseObj[];
      }));
  
      return responseMapping$;
  }
  public getSPDDL(ccId: number): Observable<SPDDLObj[]>
  {
    let arg={
      ccId:ccId
    };
    let response$ = this.httpClient.post<any>(this.baseUrl + `/getSPDDL`, arg , {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      return response.responseData as SPDDLObj[];
      }));
  
      return responseMapping$;
  }



  public updateSPProfile(arg: SPProfileObj):Observable<number>{

    
    let arg2 = JSON.stringify(arg,function(key,value){
      if(value === null) {
        if(!(['whatsAppContactAllowed'].find(x=>x==key)))
        {
         return "";
        }
        if('whatsAppContactAllowed' == key)
        {
          return false;
        }
      }
     return value;
    });

    
    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateSPProfile`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      let spId:number = response.responseData;

      return spId;
      }));
  
      return responseMapping$;
  }
  public updateSPSettings(arg: SPSettingsObj):Observable<number>{
    
    let arg2 = JSON.stringify(arg,function(key,value){
      if(value === null) {
        if(!([''].find(x=>x==key)))
        {
         return "";
        }
      }
     return value;
    });

    let response$ = this.httpClient.post<any>(this.baseUrl + `/updateSPSettings`,arg2, {}).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response=>{
      let spId:number = response.responseData;

      return spId;
      }));
  
      return responseMapping$;
  }

  public getWorkReport(orderId: number): Observable<WorkReportObj>
  {
    let arg={
      orderId: orderId
    };

    let response$ = this.httpClient.post<any>(this.baseUrl + `/getWorkReport/`,arg).pipe(map(response => response as GenericResponseObj));
    let errorFilter$ = response$.pipe(tap((response)=>{
    }));

    let responseMapping$ = errorFilter$.pipe(map(response => {
      let workReportObj=new WorkReportObj();
      if(response && response.responseData)
      {
        workReportObj = response.responseData[0] as WorkReportObj;
      }
      return workReportObj;
      }));
  
      return responseMapping$;
  }
}
