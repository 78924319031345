import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

export module Util
{
  export function ParseCustomDate(value: string): NgbDate
  {
    if (!value)
    return null;
    let parts=value.split('.');
    return {year:+parts[2],month:+parts[1],day:+parts[0]} as NgbDate
  }

    export function StringToNgbDate(dateString: string):NgbDate{
        let dt = moment(dateString);

        let ngbDate = new NgbDate(dt.year(), dt.month() + 1, dt.date());

        return ngbDate;
      }

      export function NgbDateToString(ngbDate: NgbDate):string{
        let strDt = moment(`${ngbDate.year}-${ngbDate.month}-${ngbDate.day}`).format('YYYY-MM-DD');

        return strDt;
      }

      export function ConvertNullToEmptyString(obj: any):any
      {
        for (const property in obj) {
          console.log(typeof(obj[property]));
          if(typeof(obj[property]) == 'object')
          {
            if(!obj[property])
            {
              obj[property]='';
            }
          }
        }

        return obj;
      }

      export function ToText(arr: string[]):string
      {
        let text:string="";
        if(arr && arr.length > 0)
        {
          text = arr.join(", ");
        }
        return text;
      }

      export function ToStringArr(text: string):string[]{

        if(text)
        {
          let arr= text.split(",");
          if(arr && arr.length > 0)
          {
            arr = arr.map(x=> {
              x = x.trim();
              return x;
            });
          }
          
          console.log(arr);

          return arr;
        }
        return [];
      }
}