<div class="controlsRow" [formGroup]="form">
    <div class="controlItem">
        <p>{{serialNo}}.- Item name</p>
        <input type="text" class="form-control" formControlName="itemName"/>
    </div>
    <div class="controlItem" style="width:120px;">
        <p style="text-align:center;">Code</p>
        <input type="text" class="form-control" formControlName="itemCode"/>
    </div>
    <div class="controlItem" style="width:80px;">
        <p style="text-align:center;">Qty</p>
        <input type="number" class="form-control" formControlName="itemQuantity"/>
    </div>
    <div class="actionItem">
        <a (click)="removeSparePart()"><span class="fa fa-minus-circle"></span></a>
    </div>
</div>