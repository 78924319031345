<div style="position: relative">
  <ngx-spinner></ngx-spinner>
</div>
<mat-drawer-container class="formgroup-sidenavcontainer" [hasBackdrop]="true">
  <mat-drawer #sidenav mode="over" [opened]="isSlidingPanelOpened()" position="end" class="notificationssidenav" [disableClose]="true">
    <div class="matDrawerContent">
        <div *ngIf="showNotifications">
            <notifications-list (sliderClosed)='closeSlider($event, "NOTIFICATIONS")' [lstStatus]="lstStatus"></notifications-list>
        </div>
        <div *ngIf="showHelp">
          <help-content (sliderClosed)='closeSlider($event, "HELP")'></help-content>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <left-menu></left-menu>
    <top-menu (onShowHelp)="showHelpPanel()" (onShowNotifications)="showNotificationPanel()" [statusCount]="getUnReadStatusCount()"></top-menu>
    <div class="pgcontent">
        <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>