import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'sp-notes',
  templateUrl: './spnotes.component.html',
  styleUrls: ['./spnotes.component.css']
})
export class SPNotesComponent implements OnInit {

  @Input()
  public serviceProviderId: number = 0;
  
  @Input()
  public form:FormGroup = null;
  
  constructor() { 
  }

  ngOnInit() {
  }

}
