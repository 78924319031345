<div class="wizardpage">
    <div class="wizardhead">
        <h2>{{pageTitle}}</h2>
    </div>
    <div class="wizardsteps">
        <a class="first" [class.selected]="currentStep == 'contact_information'" (click)="selStep('contact_information')">Contact information</a>
        <a [class.selected]="currentStep == 'address_information'" (click)="selStep('address_information')">Address information</a>
        <!-- <a [class.selected]="currentStep == 'comments'" (click)="selStep('comments')">Comments</a> -->
        <a class="last" [class.selected]="currentStep == 'summary'" (click)="selStep('summary')">Summary</a>
    </div>
    <div class="wizardbody customscrollbar">
        <div *ngIf="currentStep == 'contact_information'">
            <customer-contact-information [form]="contactDetailsForm"></customer-contact-information>
        </div>
        <div *ngIf="currentStep == 'address_information'">
            <customer-address-information [form]="addressDetailForm"></customer-address-information>
        </div>
        <!-- <div *ngIf="currentStep == 'comments'">
            <customer-comments [form]="commentsForm"></customer-comments>
        </div> -->
        <div *ngIf="currentStep == 'summary'">
            <customer-summary [customerId]="customerId"></customer-summary>
        </div>
    </div>
    <div class="wizardfooter">
        <div>
            <button class="btnfooter" *ngIf="currentStep != 'contact_information'" (click)="backStep()"><span class="fa fa-angle-left"></span> Back</button>
        </div>
        <div>
            <button class="btnfooter" *ngIf="currentStep != 'summary'" [disabled]="!isFormActive()" (click)="nextStep()">Next <span class="fa fa-angle-right"></span></button>
            <button class="btnfooter" (click)="finishWizard()" *ngIf="currentStep == 'summary'">Complete</button>
        </div>
    </div>
</div>