<div class="pageheadingbox">
    <h2>Settings</h2>
    <div class="buttoncontainer">
        <button class="btnprimary btnsmall" style="width:70px; margin-right:10px;" (click)="goBack()"><span class="fa fa-angle-left"></span> Back</button>
        <button class="btnprimary btnsmall" [class.btndim]="!spDetailForm.valid" style="width:130px;" [disabled]="!spDetailForm.valid" (click)="updateSPDetail()">Save updated</button>
    </div>
</div>
<div class="wizardbody">
    <div class="wizardformsection" [formGroup]="spDetailForm">
        <div class="wizardformpartbox">
            <h3>Company information</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Organization name</label>
                        <input type="text" class="form-control" formControlName="organization"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Website</label>
                        <input type="text" class="form-control" formControlName="websiteAddress"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Brand<span class="required">*</span></label>
                        <select class="form-control" tabindex="4" formControlName="brand">
                            <option value="">Select brand</option>
                            <option *ngFor="let item of lstBrands" [value]="item.value">{{item.text}}</option>
                          </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="wizardformpartbox">
            <h4>Company address</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Address</label>
                        <input type="text" class="form-control" formControlName="companyAddressStreet"/>
                    </div>
                    <div class="form-group">
                        <label>Addition</label>
                        <input type="text" class="form-control" formControlName="companyAddressAdditionInfo"/>
                    </div>
                    <div class="form-group">
                        <label>Country</label>
                        <select class="form-control" formControlName="companyAddressCountry">
                            <option value="">Select Country</option>
                            <option *ngFor="let item of lstCountries" [value]="item">{{item}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>City</label>
                        <input type="text" class="form-control" formControlName="companyAddressCity"/>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Number</label>
                        <input type="text" class="form-control" formControlName="companyAddressHouseNo"/>
                    </div>
                    <div class="form-group">
                        <label>Notes</label>
                        <input type="text" class="form-control" formControlName="companyAddressNotes"/>
                    </div>
                    <div class="form-group">
                        <label>Postal code</label>
                        <input type="text" class="form-control" formControlName="companyAddressPostalCode"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformsection">
        <div class="wizardformpartbox">
            <h3>Login security</h3>
            <div class="row">
                <div class="col-sm-2">
                    <label>Password</label>
                    <button class="btnoutline2" (click)="resetPassword()">Reset password</button>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformsection">
        <div class="wizardformpartbox">
            <h3>Data history</h3>
            <div class="row">
                <div class="col-sm-4">
                    <button class="btnoutline2" style="width:200px;">Clear search history</button>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformsection">
        <div class="wizardformpartbox">
            <h3>About</h3>
            <div class="row">
                <div class="col-sm-3">
                    <label>Version 1.1</label>
                    <button class="btnoutline2" style="width:160px;">About us</button>
                </div>
            </div>
        </div>
    </div>
    <div class="wizardformsection">
        <div class="wizardformpartbox">
            <h3>Legal</h3>
            <div class="row">
                <div class="col-sm-3">
                    <label>Terms of service</label>
                    <button class="btnoutline2" style="width:180px; color:#000; font-weight: normal;">Terms of service</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <label>Privacy policy</label>
                    <button class="btnoutline2" style="width:180px; color:#000; font-weight: normal;">Privacy policy</button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <label>Cookies policy</label>
                    <button class="btnoutline2" style="width:180px; color:#000; font-weight: normal;">Cookies policy</button>
                </div>
            </div>
        </div>
    </div>
</div>