import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-step1',
  templateUrl: './signup-step1.component.html',
  styleUrls: ['./signup-step1.component.css']
})
export class SignupStep1Component implements OnInit {
  public form:FormGroup = null;
  constructor(private router: Router, public fb:FormBuilder) { 
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      emailAddress: ['', [Validators.required, Validators.maxLength(100)]],
      phoneNumber:[]
    });
  }

  ngOnInit(): void {
  }

  nextStep(){
    
  }
}
