<div class="wizardpage">
    <div class="wizardhead">
        <h2>Reset password</h2>
        <div class="close" (click)="closePopup();"><span class="fa fa-times"></span></div>
    </div>
    <div class="wizardbodypopup customscrollbar">
        <div class="updatestatusbox">
            <div class="btnbox">
                <button class="btnprimary" (click)="resetPassword()">Save password</button>
            </div>
        </div>
    </div>
</div>