<div class="loginpage">
    <h1>Sign up</h1>
    <h2>Welcome to FixFirst</h2>
    <form [formGroup]="form" class="form-horizontal my-4" appIdentityRevealed>
        <div class="form-item">
          <label class="ctrcaption" for="username">Name</label>
          <div class="">
            <input class="ctrlitem" type="text" formControlName="name" />
          </div>
        </div>
        <div class="form-item">
            <label class="ctrcaption" for="username">Email</label>
            <div class="">
              <input class="ctrlitem" type="text" formControlName="emailAddress" />
            </div>
          </div>
        <div class="form-item">
          <label class="ctrcaption" for="userpassword">Phone number</label>
          <div class="">
            <input class="ctrlitem" type="text" formControlName="phoneNumber" />
          </div>
        </div>
        <div class="form-group">

        </div>
        <div class="loginbtn">
            <button type="submit" class="btnprimary btndim" (click)="nextStep()">Next</button>
        </div>
    </form>
</div>