<div class="topmenu">
    <div class="searchbox" *ngIf="showSearchBar()">
        <form [formGroup]="form" (submit)="search()" appIdentityRevealed>
            <input class="topsearchtxt" type="text" [placeholder]="searchPlaceHolderTxt()" formControlName="searchTerm" />
        </form>
    </div>
    <div class="controlbox">
        <div class="controlitemcontainer">
            <a><span class="bdgbox newbdg">{{getUserTypeTxt()}}</span></a>
            <!-- <a (click)="showHelp()"><span class="fa fa-question"></span></a> -->
            <a (click)="showNotifications()">
                <span class="fa fa-bell"></span>
                <span class="badge badge-danger" *ngIf="statusCount > 0">{{statusCount}}</span>
            </a>
            <a routerLink="/home/settings"><span class="fa fa-cog"></span></a>
            <a routerLink="/home/profile" class="profilepic">
                <img src="./../../assets/images/icoprofile.png" />
            </a>
        </div>
    </div>
    <div class="clearfix"></div>
</div>