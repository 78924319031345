<div class="filterbox">
    <div class="filterboxitem">
        <!-- <button class="icobtn"><span class="icofilter"></span></button> -->
    </div>
    <div class="filterboxitem">
        <button class="icobtn" (click)="deleteCustomers()"><span class="fa fa-trash"></span></button>
    </div>
</div>
<table class="table listingtable" width="100%">
    <thead>
        <tr>
            <th>
                <input type="checkbox" />
            </th>
            <th>Name</th>
            <th>Service provider</th>
            <th>Order details</th>
            <th>Request date</th>
            <th>Tags</th>
            <th style="width:150px;">Order status</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of lstCustomers" (click)="showDetailsPopup(item.customerId)">
            <td><input type="checkbox" (click)="rowChkClicked($event)"/></td>
            <td>{{item.customerFirstName}} {{item.customerLastName}}</td>
            <td>{{item.spOrganization}}</td>
            <td>
                {{item.applianceType}}
                <br />
                {{item.visitAddressPostalCode}}, {{item.visitAddressCity}}
            </td>
            <td><div *ngIf="item.scheduleDate">
                {{item.scheduleDate | customdate: 'ddd - DD.MM.YYYY'}} 
                <br />
                {{item.scheduleTime}}</div>
            </td>
            <td>-</td>
            <td><span class="bdgbox" [statusbg]="item.orderStatus">{{item.orderStatus | orderstatus}}</span></td>
            <td>
                <button class="icobtn" (click)="showContactNumber($event, item)"><span class="fa fa-phone"></span></button>&nbsp;
                <button class="icobtn"><span class="fa fa-envelope"></span></button>
            </td>
        </tr>
    </tbody>
</table>