import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CollectionUtil } from 'src/app/shared/ccc/collections';
import { DropDownObj } from 'src/app/shared/models/commonobj';

@Component({
  selector: 'sp-personal-details',
  templateUrl: './sppersonal-details.component.html',
  styleUrls: ['./sppersonal-details.component.css']
})
export class SPPersonalDetailsComponent implements OnInit {

  @Input()
  public serviceProviderId: number = 0;
  
  @Input()
  public form:FormGroup = null;

  readonly lstCountries: string[] = CollectionUtil.lstCountries;
  readonly lstSPRoles: DropDownObj[] = CollectionUtil.lstSPRoles;
  readonly lstBrands: DropDownObj[] = CollectionUtil.lstBrands;
  
  constructor() { 
  }

  ngOnInit() {
  }

}
