import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DayObj } from 'src/app/shared/models/calendarobj';
import { SPCalendarCellVM } from 'src/app/shared/models/serviceprovider';
import { TechnicianCalendarCellVM, TechnicianPlainCalendarCellVM } from 'src/app/shared/models/technician';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';
import * as moment from 'moment';
import { LoginResponseObj } from 'src/app/shared/models/login';

@Component({
  selector: 'calendar-cell',
  templateUrl: './calendar-cell.component.html',
  styleUrls: ['./calendar-cell.component.css']
})
export class CalendarCellComponent implements OnInit {

  @Input()
  public dayObj:DayObj;

  public mode: string;

  // @Output()
  // public onLstTechnicians: EventEmitter<TechnicianPlainCalendarCellVM>=new EventEmitter();
  // @Output()
  // public onLstSPs: EventEmitter<SPCalendarCellVM>=new EventEmitter();
  @Output()
  public onDaySelected: EventEmitter<DayObj> =new EventEmitter();
  
  constructor(private dataService: DataService, private modelService: ModelService) {
   this.mode = this.modelService.getLoginDetail().userType;
  }

  ngOnInit(): void {
  }

  public selecteDay(){
    if(!this.isDayDisabled())
    {
      this.onDaySelected.emit(this.dayObj);
    }
  }

  public isDayDisabled():boolean{
    let isDisabled=true;
    if(this.mode == 'SP')
    {
      if(this.dayObj && this.dayObj.isSelected)
      {
        isDisabled = false;
      }
    }
    else
    {
      isDisabled=false;
    }
    return isDisabled;
  }

}
