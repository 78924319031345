import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'customdate'
})
export class CustomDatePipe implements PipeTransform {

    constructor() { }

    transform(value: any, formatstr:string = null) {
        if(formatstr)
        {
            return moment(value).format(formatstr);
        }
        else{
            return moment(value).format('DD-MM-YYYY hh:mm:ss a');
        }
    }
}
