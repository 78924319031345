import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ModalConfirmationStatus } from 'src/app/shared/ccc/constants';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'app-reset-password-popup',
  templateUrl: './reset-password-popup.component.html',
  styleUrls: ['./reset-password-popup.component.css']
})
export class ResetPasswordPopupComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, public modelService: ModelService, private dataService: DataService, private toastr: ToastrManager) { }

  ngOnInit() {
  }
  closePopup(){
    this.activeModal.close(ModalConfirmationStatus.CLOSED);
  }

  public resetPassword()
  {
    
  }

}
