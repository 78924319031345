import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Util } from 'src/app/shared/ccc/util';
import { CustomerAddressDetailObj, CustomerContactDetailObj, CustomerPartialUpdateObj } from 'src/app/shared/models/customer';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-manage-customers',
  templateUrl: './manage-customers.component.html',
  styleUrls: ['./manage-customers.component.css']
})
export class ManageCustomersComponent implements OnInit {

  public customerId: number = 0;
  public pageTitle: string = "New customer";

  public contactDetailsForm:FormGroup = null;
  public addressDetailForm:FormGroup = null;
  public commentsForm:FormGroup = null;

  public currentStep:string = 'contact_information';

  constructor(public activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, public fb:FormBuilder, private toastr: ToastrManager) { 
    this.customerId = this.activatedRoute.snapshot.params.id;

    this.contactDetailsForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.maxLength(150)]],
      mobileNumberExtension:[, [Validators.required, Validators.maxLength(10)]],
      mobileNumber:[, [Validators.required, Validators.maxLength(50)]],
      whatsAppContactAllowed:[],
      languages:['', [Validators.required, Validators.maxLength(200)]],
      notes:['', [Validators.maxLength(500)]]
    });

    this.addressDetailForm = this.fb.group({
      visitAddressHouseNo:[,[Validators.required, Validators.maxLength(50)]],
      visitAddressStreetNo:[,[Validators.required, Validators.maxLength(100)]],
      visitAddressAdditionInfo:[,[Validators.maxLength(500)]],
      visitAddressNotes:[,[Validators.maxLength(500)]],
      visitAddressCity:[,[Validators.required, Validators.maxLength(100)]],
      visitAddressPostalCode:[,[Validators.required, Validators.maxLength(100)]],
      visitAddressCountry:[,[Validators.required, Validators.maxLength(100)]],
      visitAddressIsElevator:[],

      isBillingAddressSame:[],
      
      billingAddressHouseNo:[,[Validators.required, Validators.maxLength(50)]],
      billingAddressStreetNo:[,[Validators.required, Validators.maxLength(100)]],
      billingAddressAdditionInfo:[,[Validators.maxLength(500)]],
      billingAddressNotes:[,[Validators.maxLength(500)]],
      billingAddressCity:[,[Validators.required, Validators.maxLength(100)]],
      billingAddressPostalCode:[,[Validators.required, Validators.maxLength(100)]],
      billingAddressCountry:[,[Validators.required, Validators.maxLength(100)]]
    });

    this.commentsForm = this.fb.group({});
  }

  ngOnInit() {
    if(this.customerId)
    {
      this.pageTitle="Update customer";
      this.dataService.getCustomerById(this.customerId).subscribe(customer=>{

        this.contactDetailsForm.controls["firstName"].setValue(customer.firstName);
        this.contactDetailsForm.controls["lastName"].setValue(customer.lastName);
        this.contactDetailsForm.controls["email"].setValue(customer.email);
        this.contactDetailsForm.controls["mobileNumberExtension"].setValue(customer.mobileNumberExtension);
        this.contactDetailsForm.controls["mobileNumber"].setValue(customer.mobileNumber);
        this.contactDetailsForm.controls["whatsAppContactAllowed"].setValue(customer.whatsAppContactAllowed);
        this.contactDetailsForm.controls["languages"].setValue(Util.ToStringArr(customer.languages));
        this.contactDetailsForm.controls["notes"].setValue(customer.notes);


        this.addressDetailForm.controls["visitAddressHouseNo"].setValue(customer.visitAddressHouseNo);
        this.addressDetailForm.controls["visitAddressStreetNo"].setValue(customer.visitAddressStreetNo);
        this.addressDetailForm.controls["visitAddressAdditionInfo"].setValue(customer.visitAddressAdditionInfo);
        this.addressDetailForm.controls["visitAddressNotes"].setValue(customer.visitAddressNotes);
        this.addressDetailForm.controls["visitAddressCity"].setValue(customer.visitAddressCity);
        this.addressDetailForm.controls["visitAddressPostalCode"].setValue(customer.visitAddressPostalCode);
        this.addressDetailForm.controls["visitAddressCountry"].setValue(customer.visitAddressCountry);
        this.addressDetailForm.controls["visitAddressIsElevator"].setValue(customer.visitAddressIsElevator);
        this.addressDetailForm.controls["isBillingAddressSame"].setValue(customer.isBillingAddressSame);
        this.addressDetailForm.controls["billingAddressHouseNo"].setValue(customer.billingAddressHouseNo);
        this.addressDetailForm.controls["billingAddressStreetNo"].setValue(customer.billingAddressStreetNo);
        this.addressDetailForm.controls["billingAddressAdditionInfo"].setValue(customer.billingAddressAdditionInfo);
        this.addressDetailForm.controls["billingAddressNotes"].setValue(customer.billingAddressNotes);
        this.addressDetailForm.controls["billingAddressCity"].setValue(customer.billingAddressCity);
        this.addressDetailForm.controls["billingAddressPostalCode"].setValue(customer.billingAddressPostalCode);
        this.addressDetailForm.controls["billingAddressCountry"].setValue(customer.billingAddressCountry);
      });
    }
    else
    {
      this.customerId = 0;
    }
  }
  changeStep(newStep: string)
  {
    this.currentStep = newStep;
  }

  backStep()
  {
    switch(this.currentStep)
    {
      case 'address_information':
        this.currentStep = 'contact_information';
        break;
      case 'comments':
        this.currentStep = 'address_information';
        break;
      case 'summary':
        //this.currentStep = 'comments';
        this.currentStep = 'address_information';
        break;
    }
  }
  nextStep()
  {
    let partialUpdateObj:CustomerPartialUpdateObj=new CustomerPartialUpdateObj();
    partialUpdateObj.customerId = this.customerId;
    switch(this.currentStep)
    {
      case 'contact_information':
        let contactDetailObj: CustomerContactDetailObj = Object.assign({}, this.contactDetailsForm.getRawValue());
        
        contactDetailObj.languages= Util.ToText(this.contactDetailsForm.controls['languages'].value);

        partialUpdateObj.contactDetailObj = contactDetailObj;
        partialUpdateObj.stepType="CONTACT_DETAILS";
        this.dataService.partialUpdateCustomer(partialUpdateObj).subscribe(res=>{
          this.toastr.successToastr('Contact details saved successfully');
          this.currentStep = 'address_information';
        });
        break;
      case 'address_information':
        let addressDetailObj: CustomerAddressDetailObj = Object.assign({}, this.addressDetailForm.getRawValue());
        partialUpdateObj.addressDetailObj = addressDetailObj;
        partialUpdateObj.stepType="ADDRESS_DETAILS";
        this.dataService.partialUpdateCustomer(partialUpdateObj).subscribe(res=>{
          this.toastr.successToastr('Address details saved successfully');
          //this.currentStep = 'comments';
          this.currentStep = 'summary';
        });
        break;
      case 'comments':
        this.currentStep = 'summary';
        break;
    }
  }

  selStep(selectedStep: string)
  {
    this.currentStep = selectedStep;
  }

  isFormActive():boolean
  {
    let isActive: boolean = true;
    switch(this.currentStep)
    {
      case "contact_information":
        isActive = this.contactDetailsForm.valid;
        break;
      case "address_information":
        isActive = this.addressDetailForm.valid;
        break;
      case "comments":
        isActive = this.commentsForm.valid;
        break;
    }
    return isActive;
  }

  finishWizard(){
    this.router.navigate(['home/customers']);
  }

}
