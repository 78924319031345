<div class="wizardpage">
    <div class="wizardhead">
        <h2>{{pageTitle}}</h2>
    </div>
    <div class="wizardsteps">
        <a class="first" [class.selected]="currentStep == 'visit_type'" (click)="selStep('visit_type')">Visit type</a>
        <a [class.selected]="currentStep == 'customer_details'" (click)="selStep('customer_details')">Customer details</a>
        <a [class.selected]="currentStep == 'appliance_data'" (click)="selStep('appliance_data')">Appliance & error data</a>
        <a [class.selected]="currentStep == 'attachments'" (click)="selStep('attachments')">Recommendations</a>
        <a [class.selected]="currentStep == 'booking_details'" (click)="selStep('booking_details')">Booking details</a>
        <a class="last" [class.selected]="currentStep == 'request_summary'" (click)="selStep('request_summary')">Request summary</a>
    </div>
    <div class="wizardbody">
        <div *ngIf="currentStep == 'visit_type'">
            <visit-type-step [orderId]="orderId" [form]="visitTypeForm"></visit-type-step>
        </div>
        <div *ngIf="currentStep == 'customer_details'">
            <customer-details-step [orderId]="orderId" [form]="customerDetailForm"></customer-details-step>
        </div>
        <div *ngIf="currentStep == 'appliance_data'">
            <appliance-data-step [orderId]="orderId" [form]="applianceDetailForm"></appliance-data-step>
        </div>
        <div *ngIf="currentStep == 'attachments'">
            <attachments-step [orderId]="orderId" [form]="attachmentsForm"></attachments-step>
        </div>
        <div *ngIf="currentStep == 'booking_details'">
            <booking-details-step [orderId]="orderId"></booking-details-step>
        </div>
        <div *ngIf="currentStep == 'request_summary'">
            <request-summary-step [orderId]="orderId"></request-summary-step>
        </div>
    </div>
    <div class="wizardfooter">
        <div>
            <button class="btnfooter" (click)="backStep()" *ngIf="currentStep != 'visit_type'"><span class="fa fa-angle-left"></span> Back</button>
        </div>
        <div>
            <button class="btnfooter" (click)="nextStep()" [disabled]="!isFormActive()"  *ngIf="currentStep != 'request_summary'">Next <span class="fa fa-angle-right"></span></button>
            <button class="btnfooter" (click)="finishWizard()" *ngIf="currentStep == 'request_summary'">Finish</button>
        </div>
    </div>
</div>