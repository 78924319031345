import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'help-content',
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.css']
})
export class HelpContentComponent implements OnInit {

  @Output() sliderClosed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close()
  {
    this.sliderClosed.emit(null);
  }

}
