import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CollectionUtil } from 'src/app/shared/ccc/collections';

@Component({
  selector: 'customer-address-information',
  templateUrl: './customer-address-information.component.html',
  styleUrls: ['./customer-address-information.component.css']
})
export class CustomerAddressInformationComponent implements OnInit {
  
  @Input()
  public form:FormGroup = null;

  readonly lstCountries: string[] = CollectionUtil.lstCountries;

  constructor(public fb:FormBuilder) { 
  }

  ngOnInit() {
    this.setBillingAddress(this.form.controls['isBillingAddressSame'].value);
    
    this.form.controls['isBillingAddressSame'].valueChanges.subscribe(isBillingAddressSame=>{
      this.setBillingAddress(isBillingAddressSame);
    });

    this.form.controls['visitAddressHouseNo'].valueChanges.subscribe(houseNo=>{
      if(this.form.controls['isBillingAddressSame'].value)
      {
        this.form.controls['billingAddressHouseNo'].setValue(houseNo);  
      }
    });

    this.form.controls['visitAddressStreetNo'].valueChanges.subscribe(streetNo=>{
      if(this.form.controls['isBillingAddressSame'].value)
      {
        this.form.controls['billingAddressStreetNo'].setValue(streetNo);  
      }
    });

    this.form.controls['visitAddressAdditionInfo'].valueChanges.subscribe(additionInfo=>{
      if(this.form.controls['isBillingAddressSame'].value)
      {
        this.form.controls['billingAddressAdditionInfo'].setValue(additionInfo);  
      }
    });

    this.form.controls['visitAddressNotes'].valueChanges.subscribe(addressNotes=>{
      if(this.form.controls['isBillingAddressSame'].value)
      {
        this.form.controls['billingAddressNotes'].setValue(addressNotes);  
      }
    });

    this.form.controls['visitAddressCountry'].valueChanges.subscribe(country=>{
      if(this.form.controls['isBillingAddressSame'].value)
      {
        this.form.controls['billingAddressCountry'].setValue(country);  
      }
    });

    this.form.controls['visitAddressPostalCode'].valueChanges.subscribe(postalCode=>{
      if(this.form.controls['isBillingAddressSame'].value)
      {
        this.form.controls['billingAddressPostalCode'].setValue(postalCode);  
      }
    });

    this.form.controls['visitAddressCity'].valueChanges.subscribe(city=>{
      if(this.form.controls['isBillingAddressSame'].value)
      {
        this.form.controls['billingAddressCity'].setValue(city);  
      }
    });

  }

  private setBillingAddress(isBillingAddressSame: boolean){
    if(isBillingAddressSame)
    {
      this.form.controls['billingAddressHouseNo'].disable();
      this.form.controls['billingAddressHouseNo'].setValue(this.form.controls['visitAddressHouseNo'].value);
      
      this.form.controls['billingAddressStreetNo'].disable();
      this.form.controls['billingAddressStreetNo'].setValue(this.form.controls['visitAddressStreetNo'].value);
      
      this.form.controls['billingAddressAdditionInfo'].disable();
      this.form.controls['billingAddressAdditionInfo'].setValue(this.form.controls['visitAddressAdditionInfo'].value);
      
      this.form.controls['billingAddressNotes'].disable();
      this.form.controls['billingAddressNotes'].setValue(this.form.controls['visitAddressNotes'].value);
      
      this.form.controls['billingAddressCountry'].disable();
      this.form.controls['billingAddressCountry'].setValue(this.form.controls['visitAddressCountry'].value);
      
      this.form.controls['billingAddressPostalCode'].disable();
      this.form.controls['billingAddressPostalCode'].setValue(this.form.controls['visitAddressPostalCode'].value);

      this.form.controls['billingAddressCity'].disable();
      this.form.controls['billingAddressCity'].setValue(this.form.controls['visitAddressCity'].value);
    }
    else
    {
      this.form.controls['billingAddressHouseNo'].enable();
      this.form.controls['billingAddressStreetNo'].enable();
      this.form.controls['billingAddressAdditionInfo'].enable();
      this.form.controls['billingAddressNotes'].enable();
      this.form.controls['billingAddressCountry'].enable();
      this.form.controls['billingAddressPostalCode'].enable();
      this.form.controls['billingAddressCity'].enable();
    }
  }

}
