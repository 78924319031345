import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'technician-notes-details',
  templateUrl: './technician-notes-details.component.html',
  styleUrls: ['./technician-notes-details.component.css']
})
export class TechnicianNotesDetailsComponent implements OnInit {
  
  @Input()
  public form:FormGroup = null;

  constructor() { }

  ngOnInit() {
  }

}
