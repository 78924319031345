import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SignupLayoutComponent } from './signup/signup-layout.component';
import { LoginComponent } from './signup/login/login.component';
import { SignupStep1Component } from './signup/signup-step1/signup-step1.component';
import { SignupStep2Component } from './signup/signup-step2/signup-step2.component';
import { SignupStep3Component } from './signup/signup-step3/signup-step3.component';
import { SecureComponent } from './secure/secure.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RequestsComponent } from './requests/requests.component';
import { TechniciansComponent } from './technicians/technicians.component';
import { PartnersComponent } from './partners/partners.component';
import { CustomersComponent } from './customers/customers.component';
import { OrdersComponent } from './orders/orders.component';
import { ManageOrderComponent } from './orders/manage-order/manage-order.component';
import { ServiceProvidersComponent } from './service-providers/service-providers.component';
import { ManageServiceProvidersComponent } from './service-providers/manage-service-providers/manage-service-providers.component';
import { ManageCustomersComponent } from './customers/manage-customers/manage-customers.component';
import { ManageTechniciansComponent } from './technicians/manage-technicians/manage-technicians.component';
import { TechnicianCalendarComponent } from './technicians/technician-calendar/technician-calendar.component';
import { ProfileComponent } from './secure/profile/profile.component';
import { SettingsComponent } from './secure/settings/settings.component';
import { LoginChangePasswordComponent } from './signup/login-change-password/login-change-password.component';
import { SPDDLResolver } from './shared/resolvers/spddl-resolver.service';




@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: '', component: SignupLayoutComponent, children: [
          { path: 'login', component: LoginComponent },
          { path: 'signup-step-1', component: SignupStep1Component },
          { path: 'signup-step-2', component: SignupStep2Component },
          { path: 'signup-step-3', component: SignupStep3Component },

          { path: 'change-password/:email', component: LoginChangePasswordComponent },
        ]
      },
      {
        path: 'home', component: SecureComponent, children: [
          { path:'profile', component: ProfileComponent},
          { path: 'settings', component: SettingsComponent},
          { path: 'dashboard', component: DashboardComponent, resolve:
          {
            spddl: SPDDLResolver
          } 
        },
          { path: 'requests', component: RequestsComponent },
          { path: 'orders', component: OrdersComponent },
          { path: 'orders/new', component: ManageOrderComponent },
          { path: 'orders/edit/:id', component: ManageOrderComponent },
          { path: 'customers', component: CustomersComponent },
          { path: 'customers/edit/:id', component: ManageCustomersComponent },
          { path: 'partners', component: PartnersComponent },
          { path: 'technicians', component: TechniciansComponent },
          { path: 'technicians/new', component: ManageTechniciansComponent },
          { path: 'technicians/edit/:id', component: ManageTechniciansComponent },
          { path: 'technicians/calendar', component: TechnicianCalendarComponent },
          { path: 'service-providers', component: ServiceProvidersComponent },
          { path: 'service-providers/new', component: ManageServiceProvidersComponent },
          { path: 'service-providers/edit/:id', component: ManageServiceProvidersComponent },
        ]
      },
      { path: '**', redirectTo: 'login' }
    ]),
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
