import { DayObj } from "./calendarobj";

export class TechnicianListingArg
{
    public spId: number;
    public searchTerm: string;
}



export class TechnicianListingObj{
    public technicianId: number;
    public firstName: string;
    public lastName: string;
    public organization: string;
    public addressCity: string;
}
export class TechnicianObj{
    public technicianId: number;
    public serviceProviderId: number;

    public firstName: string;
    public lastName: string;
    public email: string;
    public password: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
    public organization: string;
    public role: string;

    public addressHouseNo: string;
    public addressStreet: string;
    public addressAdditionInfo: string;
    public addressNotes: string;
    public addressCountry: string;
    public addressPostalCode: string;
    public addressCity: string;

    public skillLanguages: string;
    public skillCertifications: string;
    public skillBrands: string;
    public skillAppliances: string;
    public skillPhysicalResilience: boolean;

    public personalProfile: string;

    public notes: string;
}

export class TechnicianPartialUpdateObj{
    public technicianId: number;
    public stepType: string;
    public personalDetailObj: TechnicianPersonalDetailObj;
    public skillDetailObj: TechnicianSkillsDetailObj;
    public profileDetailObj: TechnicianProfileDetailObj;
    public notesDetailObj: TechnicianNotesDetailObj;
}

export class TechnicianPersonalDetailObj{
    public technicianId: number;
    public serviceProviderId: number;

    public firstName: string;
    public lastName: string;
    public email: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
    public organization: string;
    public role: string;

    public addressHouseNo: string;
    public addressStreet: string;
    public addressAdditionInfo: string;
    public addressNotes: string;
    public addressCountry: string;
    public addressPostalCode: string;
    public addressCity: string;   
}
export class TechnicianSkillsDetailObj
{
    public skillLanguages: string;
    public skillCertifications: string;
    public skillBrands: string;
    public skillAppliances: string;
    public skillPhysicalResilience: boolean;
}
export class TechnicianProfileDetailObj
{
    public personalProfile: string;
}
export class TechnicianNotesDetailObj
{
    public notes: string;
}
export class TechnicianSetPasswordObj{
    public technicianId: number;
    public password: string;
}

export class GenericAvailabilityResponseObj
{
    public availabilityDate: any;
    public lstTS: TechTSObj[] = [];
}
export class TechnicianAvailabilityPlainObj{
    public technicianId: number;
    public firstName: string;
    public lastName: string;
    public isAvailable: boolean;
}

export class TechnicianAvalabilityResponseObj{
    public technicianId: number;
    public firstName: string;
    public lastName: string;
    public isAvailable: boolean;
    public lstTS: TechTSObj[] = [];
}
export class TechnicianCalendarCellVM{
    public dayObj: DayObj;
    public lstTechnicians: TechnicianAvalabilityResponseObj[];
}
export class TechnicianPlainCalendarCellVM
{
    public dayObj: DayObj;
    public lstTechnicians: TechnicianAvailabilityPlainObj[];
}
export class TechAvailabilityRequestObj{
    public technicianId: number;
    public lstTS: TechTSObj[] = [];
}
export class TechTSObj{
    public tsFrom: string;
    public tsTo: string;
}