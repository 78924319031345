import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from 'ng6-toastr-notifications';
import { filter, map } from 'rxjs/operators';
import { ModalConfirmationStatus } from '../shared/ccc/constants';
import { ContactNumberModalComponent } from '../shared/components/contact-number-modal/contact-number-modal.component';
import { DeleteConfirmationModalComponent } from '../shared/components/delete-confirmation-modal/delete-confirmation-modal.component';
import { SPListingArg, SPListingObj } from '../shared/models/serviceprovider';
import { DataService } from '../shared/services/data.service';
import { ModelService } from '../shared/services/model-service';
import { ViewServiceProviderPopupComponent } from './view-service-provider-popup/view-service-provider-popup.component';

@Component({
  selector: 'app-service-providers',
  templateUrl: './service-providers.component.html',
  styleUrls: ['./service-providers.component.css']
})
export class ServiceProvidersComponent implements OnInit {

  public lstSPs: SPListingObj[] = [];

  constructor(private ngbModal: NgbModal, private dataService: DataService, private toastr: ToastrManager, private router: Router, private modelService: ModelService) { 
    this.modelService.searchTerm$.asObservable().pipe(filter(x=> x && (x.page == "service_providers")), map(x=>x.searchTerm)).subscribe(searchTxt=>{
      this.loadRecords(searchTxt);
    });
  }

  ngOnInit() {
    this.loadRecords();
  }

  rowChkClicked(e:any)
  {
    e.stopPropagation();
  }

  updateSP(){
    this.router.navigate(['/home/service-providers/edit/1']);
  }
  newSP(){
    this.router.navigate(['/home/service-providers/new']);
  }
  
  showContactNumber(e:any){
    
    e.stopPropagation();

    const modalRef = this.ngbModal.open(ContactNumberModalComponent,{ backdrop: 'static', centered: true });

    // modalRef.componentInstance.heading = 'Are you sure you want to delete the selected customers?';
    // modalRef.componentInstance.btnConfirmText = 'Delete customers';
  }
  showDetailsPopup(spId: number)
  {
    const modalRef = this.ngbModal.open(ViewServiceProviderPopupComponent,{ backdrop: 'static', centered: true, size:'lg' });
    modalRef.componentInstance.spId = spId;
    modalRef.componentInstance.loadRecords();
  }
  deleteSPs(){
    const modalRef = this.ngbModal.open(DeleteConfirmationModalComponent,{ backdrop: 'static', centered: true });

     modalRef.componentInstance.heading = 'Are you sure you want to delete the selected service providers?';
     modalRef.componentInstance.btnConfirmText = 'Delete service providers';
     
    
    modalRef.result.then((data: ModalConfirmationStatus) => {
      if (data == ModalConfirmationStatus.CONFIRMED) {
        this.toastr.successToastr('Selected service providers deleted successfully');
      }
    }, (reason) => {
    });
  }
  loadRecords(searchTerm: string = ""){

    let arg: SPListingArg=new SPListingArg();
    arg.searchTerm = searchTerm;
    
    this.dataService.getSPListing(arg).subscribe(res=>{
      this.lstSPs = res;
    });
  }
}
