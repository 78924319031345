<div class="pageheadingbox">
    <h2>Profile</h2>
    <div class="buttoncontainer">
        <button class="btnprimary btnsmall" style="width:70px; margin-right:10px;" (click)="goBack()"><span class="fa fa-angle-left"></span> Back</button>
        <button class="btnprimary btnsmall" [class.btndim]="!profileForm.valid" style="width:130px;" [disabled]="!profileForm.valid" (click)="updateProfileInformation()">Save updated</button>
    </div>
</div>
<div class="wizardbody">
    <div class="wizardformsection" [formGroup]="profileForm">
        <div class="wizardformpartbox">
            <h4>Profile information</h4>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>First name</label>
                        <input type="text" class="form-control" formControlName="firstName"/>
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" class="form-control" formControlName="email"/>
                    </div>
                    <div class="form-group">
                        <label>Role</label>
                        <select class="form-control" formControlName="role">
                            <option value="">Select Role</option>
                            <option *ngFor="let item of lstSPRoles" [value]="item.value">{{item.text}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Last name</label>
                        <input type="text" class="form-control" formControlName="lastName"/>
                    </div>
                    <div class="form-group">
                        <div class="multicontrolbox">
                            <div>
                                <label>Extension</label>
                                <input type="text" class="form-control" formControlName="mobileNumberExtension"/>
                            </div>
                            <div>
                                <label>Phone number</label>
                                <input type="text" class="form-control" formControlName="mobileNumber"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="chkAllowWhasAppContact" formControlName="whatsAppContactAllowed">
                            <label class="form-check-label" for="chkAllowWhasAppContact">Allow contact via Whatsapp</label>
                          </div>
                    </div>
                </div>
            </div>
            <br />
            <h4>Log out</h4>
            <div class="row">
                <div class="col-sm-2">
                    <button class="btnoutline" (click)="logout()">Log out <span class="fa fa-sign-out"></span></button>
                </div>
            </div>
        </div>
    </div>
</div>