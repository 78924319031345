import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { WholeDayConstant } from 'src/app/shared/ccc/constants';
import { DayObj } from 'src/app/shared/models/calendarobj';
import { LoginResponseObj } from 'src/app/shared/models/login';
import { TechAvailabilityRequestObj, TechnicianAvalabilityResponseObj, TechnicianCalendarCellVM, TechTSObj } from 'src/app/shared/models/technician';
import { DataService } from 'src/app/shared/services/data.service';
import { ModelService } from 'src/app/shared/services/model-service';

@Component({
  selector: 'technician-calendar',
  templateUrl: './technician-calendar.component.html',
  styleUrls: ['./technician-calendar.component.css']
})
export class TechnicianCalendarComponent implements OnInit {

  public form: FormGroup=null;

  public currentMonth:any;

  public selectedDay: DayObj = null;
  public lstTechnicians: TechnicianAvalabilityResponseObj[] = [];

  public days:DayObj[]=[];

  constructor(public fb:FormBuilder, private dataService: DataService, private modelService: ModelService, private toastr: ToastrManager) { 
    this.currentMonth=moment().startOf('month');
    this.populateCalendar();
    this.form = this.fb.group({
      techniciansList: new FormArray([])
    });
  }

  ngOnInit(): void {
  }

  public getMonthTitle(){
    return `${this.currentMonth.format('MMM-YYYY')}`;
  }
  public nextMonth(){
    this.currentMonth=moment(this.currentMonth).add(1,'month');
    console.log(this.currentMonth);
    this.populateCalendar();
  }
  public prevMonth(){
    this.currentMonth=moment(this.currentMonth).add(-1,'month');
    this.populateCalendar();
  }

  public selectDay(arg: DayObj)
  {
    this.days.forEach(x=>{
      if(x.dayTitle == arg.dayTitle)
      {
        x.isSelected=true;
      }
      else
      {
        x.isSelected=false;
      }
    });
  }

  public get techniciansList(): FormArray
  {
    const formArray: FormArray = this.form.get('techniciansList') as FormArray;
    return formArray;
  }

  populateTechniciansList(arg: TechnicianCalendarCellVM)
  {
    this.lstTechnicians = arg.lstTechnicians;

    const formArray: FormArray = this.techniciansList;
    formArray.clear();
    arg.lstTechnicians.forEach(x=>{
      
      let fg = new FormGroup(
        {
          isAvailable: new FormControl(x.isAvailable),
          isWholeDaySelected: new FormControl(false)
        }
      );
      if(x.lstTS)
      {
        if(x.lstTS.find(x=>x.tsFrom == WholeDayConstant.TSFrom && x.tsTo == WholeDayConstant.TSTo ))
        {
          fg.controls['isWholeDaySelected'].setValue(true);
          fg.addControl('lstTS',new FormArray([]));
        }
        else
        {
          fg.addControl('lstTS',new FormArray(x.lstTS.map(x=>new FormGroup
            ({
              tsFrom:this.fb.control(x.tsFrom),
              tsTo:this.fb.control(x.tsTo)
            })
            )));
        }
      }
      else
      {
        fg.addControl('lstTS',new FormArray([]));
      }
      formArray.push(fg);
    });

    this.selectedDay = arg.dayObj;
  }

  updateAvailability(){
    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();
    const formArray: FormArray = this.techniciansList;

     let reqObjArr: TechAvailabilityRequestObj[] = [];
    formArray.controls.forEach((x:any,index)=>{
      if(x.controls['isAvailable'].value)
      {
        let reqObj=new TechAvailabilityRequestObj();
        reqObj.technicianId = this.lstTechnicians[index].technicianId;
       reqObj.lstTS= x.controls['lstTS'].controls.map(x=>{
         let tsObj:TechTSObj=new TechTSObj();
         tsObj.tsFrom=x.controls['tsFrom'].value;
         tsObj.tsTo=x.controls['tsTo'].value;

         return tsObj;
       });

       //only those technicianIds shall be saved that hase at-least one time-slot selected.
       if(reqObj.lstTS.length > 0)
       {
        reqObjArr.push(reqObj);
       }
      }
    });
    let dayTitle = moment(this.selectedDay.dayTitle).format('YYYY-MM-DD');
    this.dataService.updateTechnicianAvailability(loginDetail.userId, dayTitle, reqObjArr).subscribe(x=>{
      this.toastr.successToastr('Technicians availability saved successfully', 'Availability updated');
      this.populateCalendar();
    });
  }

  public isBoxSelected(index: number):boolean{
    const formArray: FormArray = this.form.get('techniciansList') as FormArray;
    return formArray.controls[index].value;
  }

  private populateCalendar(){

    let loginDetail: LoginResponseObj = this.modelService.getLoginDetail();
    
    let monthStart = this.currentMonth.format('YYYY-MM-DD');
    let monthEnd = moment(monthStart).endOf('month').format('YYYY-MM-DD');

    this.dataService.getTechniciansAvailabilityInDateRange(null, loginDetail.userId,monthStart, monthEnd).subscribe(res=>{
      
      this.days=[];

      let today=moment();
      
  
      let daysInMonth = this.currentMonth.daysInMonth();
  
      let startIndex = this.currentMonth.day();
  
      let runningIndex = 0;
  
      for(let index = 0; index < startIndex; index++)
      {
        runningIndex += 1;

        let dayObj:any = moment(this.currentMonth).add(index - startIndex ,'day');
        let arrElem:DayObj={
          dayNumber:dayObj.date(),
          isToday:dayObj.format('YYYY-MM-DD') == today.format('YYYY-MM-DD'),
          isSelected: false,
          isOtherMonth:true,
          dayTitle:dayObj.format('DD-MMM-YYYY'),
          day:dayObj
        };
        let curTS = res.find(x=>x.availabilityDate == dayObj.format('YYYY-MM-DD'));
        if(curTS)
        {
          arrElem.slotsArr = curTS.lstTS;
        }
        this.days.push(arrElem);
      }
  
      for(let index = 0; index < daysInMonth; index++)
      {
        runningIndex += 1;
        let dayObj:any = moment(this.currentMonth).add(index ,'day');
  
        let arrElem:DayObj = {
          dayNumber:dayObj.date(),
          isToday: dayObj.format('YYYY-MM-DD') == today.format('YYYY-MM-DD'),
          isSelected:false,
          isOtherMonth:false,
          dayTitle:dayObj.format('DD-MMM-YYYY'),
          day:dayObj
        };
        let curTS = res.find(x=>x.availabilityDate == dayObj.format('YYYY-MM-DD'));
        if(curTS)
        {
          arrElem.slotsArr = curTS.lstTS;
        }
        this.days.push(arrElem);
      }
  
      let nextMonthDays:number =0;
      for(let index = runningIndex; index < 42; index++)
      {
        let dayObj:any = moment(this.currentMonth).add(index - startIndex ,'day');
        let arrElem:DayObj = {
          dayNumber:dayObj.date(),
          isToday: dayObj.format('YYYY-MM-DD') == today.format('YYYY-MM-DD'),
          isSelected:false,
          isOtherMonth:false,
          dayTitle:dayObj.format('DD-MMM-YYYY'),
          day:dayObj
        };
        let curTS = res.find(x=>x.availabilityDate == dayObj.format('YYYY-MM-DD'));
        if(curTS)
        {
          arrElem.slotsArr = curTS.lstTS;
        }
        this.days.push(arrElem);
      }

    });
  }

}
