<div class="row signuplayout">
    <div class="col-sm-4 leftcell">
        <router-outlet></router-outlet>
    </div>
    <div class="col-sm-8 rightcell">
        <!-- <div class="toplinkrow">
            <a routerLink=""><h2>Privacy Policy</h2></a>
            <a routerLink=""><h2>About</h2></a>
            <div class="clearfix"></div>
        </div> -->
        <!-- <div class="logocontainer">
            <div class="mainlogo">
                <svg width="319" height="90" viewBox="0 0 319 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.97221 89.7803V41.0121H0V30.3384H7.97221V27.2951C7.97221 13.3744 17.2634 2.51521 35.3953 2.51521C41.2008 2.51521 47.5854 3.97609 50.7701 5.96551L47.8031 16.9655C45.1423 15.5301 40.77 14.0853 35.4878 14.0853C25.4326 14.0853 22.3383 20.2245 22.3383 27.8673V30.3384H55.6371V89.7803H41.1982V41.0121H22.4111V89.7803H7.97221Z" fill="#1F2427"/>
            <path d="M139.689 89.7805V35.3949H130.687V30.585H139.689V27.0084C139.689 10.9762 147.336 9.91821e-05 163.738 9.91821e-05C169.041 9.91821e-05 175.454 1.9734 178.537 4.4399L175.577 9.12657C172.741 6.90693 167.931 5.05679 162.628 5.05679C149.432 5.05679 145.733 15.6628 145.733 27.1321V30.585H182.113V89.7805H176.071V35.3949H145.733V89.7805H139.689Z" fill="#1F2427"/>
            <path d="M203.189 49.6131C203.189 43.1865 203.065 36.883 202.694 30.4569H208.256L208.504 43.3096H208.874C211.84 35.0292 218.267 28.9737 227.042 28.9737C228.03 28.9737 229.019 29.0974 229.884 29.3442V34.9061C228.772 34.6587 227.783 34.6587 226.424 34.6587C218.143 34.6587 211.84 41.5795 209.863 51.8379C209.492 53.568 209.244 55.6686 209.244 57.6466V89.7808H203.189V49.6131Z" fill="#1F2427"/>
            <path d="M240.557 80.7091C243.974 82.83 249.04 84.9504 254.695 84.9504C264.592 84.9504 268.95 79.7669 268.95 73.9941C268.95 67.3965 264.474 64.2149 255.638 61.3877C245.388 58.0892 240.322 52.7877 240.322 46.0721C240.322 38.0609 246.683 30.4026 258.936 30.4026C264.474 30.4026 269.304 31.9337 272.602 34.173L270.364 38.8852C268.479 37.5896 264.356 35.2332 257.876 35.2332C250.1 35.2332 245.977 39.9454 245.977 45.3648C245.977 51.727 250.807 54.2008 259.172 57.029C269.54 60.5629 274.606 65.0396 274.606 73.5222C274.606 82.7121 267.066 89.7805 254.223 89.7805C248.215 89.7805 242.678 88.1315 238.437 85.6572L240.557 80.7091Z" fill="#1F2427"/>
            <path d="M300.671 13.2091V30.4533H319V35.1556H300.671V72.1749C300.671 79.2901 302.841 84.7163 309.956 84.7163C313.453 84.7163 315.864 84.234 317.552 83.6312L318.277 88.2134C316.227 89.0574 313.212 89.7803 309.353 89.7803C304.771 89.7803 301.032 88.3339 298.621 85.4392C295.726 82.1838 294.762 76.8777 294.762 71.4515V35.1556H284.15V30.4533H294.762V15.0182L300.671 13.2091Z" fill="#1F2427"/>
            <path d="M127.643 0.736282L95.3933 50.3849L82.2059 30.353H67.6018L87.9673 60.0669H103.271L127.643 23.2669V0.736282Z" fill="#1F2427"/>
            <path d="M67.6023 89.7805H82.2064L95.3938 69.7492L108.501 89.7805H123.524L103.271 60.0671H87.9678L67.6023 89.7805Z" fill="#1F2427"/>
            </svg>
            </div>
            <h1 class="layoutheading">Achieve more together</h1>
            <h2 class="layoutheading">Order management, customer communication and more - from back office to field service technicians. Specialized for repair, maintenance and service - by FixFirst.</h2>
        </div> -->
    </div>
</div>