import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmationStatus } from '../../ccc/constants';

@Component({
  selector: 'app-contact-number-modal',
  templateUrl: './contact-number-modal.component.html',
  styleUrls: ['./contact-number-modal.component.css']
})
export class ContactNumberModalComponent implements OnInit {

  @Input()
  public mobileNumber: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  close(){
    this.activeModal.close(ModalConfirmationStatus.CLOSED);
  }

}
