<div class="row">
    <div class="col-sm-8">
      <div class="databox">
        <div class="row">
          <div class="col-sm-12">
            <div class="calendarbox">
              <div class="calendarheader">
                <div class="calendarheaderrow1">
                  <div class="monthTitle">{{getMonthTitle()}}</div>
                  <div class="calendarnav">
                    <a class="fa fa-chevron-left" (click)="prevMonth()"></a>
                    <a class="fa fa fa-chevron-right" (click)="nextMonth()"></a>
                  </div>
                </div>
                <div class="calendarheaderrow2">
                  <div class="calendarheadcell">Su</div>
                  <div class="calendarheadcell">Mo</div>
                  <div class="calendarheadcell">Tu</div>
                  <div class="calendarheadcell">We</div>
                  <div class="calendarheadcell">Th</div>
                  <div class="calendarheadcell">Fr</div>
                  <div class="calendarheadcell">Sa</div>
                </div>
              </div>
              <div class="calendarbody">
                <div class="calendarrow">
                  <technician-calendar-cell (onLstTechnicians)="populateTechniciansList($event)" (onDaySelected)="selectDay($event)" *ngFor="let number of [0,1,2,3,4,5,6]" [dayObj]="days[number]"></technician-calendar-cell>
                </div>
                <div class="calendarrow">
                  <technician-calendar-cell (onLstTechnicians)="populateTechniciansList($event)" (onDaySelected)="selectDay($event)" *ngFor="let number of [7,8,9,10,11,12,13]" [dayObj]="days[number]"></technician-calendar-cell>
                </div>
                <div class="calendarrow">
                  <technician-calendar-cell (onLstTechnicians)="populateTechniciansList($event)" (onDaySelected)="selectDay($event)" *ngFor="let number of [14,15,16,17,18,19,20]" [dayObj]="days[number]"></technician-calendar-cell>
                </div>
                <div class="calendarrow">
                  <technician-calendar-cell (onLstTechnicians)="populateTechniciansList($event)" (onDaySelected)="selectDay($event)" *ngFor="let number of [21,22,23,24,25,26,27]" [dayObj]="days[number]"></technician-calendar-cell>
                </div>
                <div class="calendarrow">
                  <technician-calendar-cell (onLstTechnicians)="populateTechniciansList($event)" (onDaySelected)="selectDay($event)" *ngFor="let number of [28,29,30,31,32,33,34]" [dayObj]="days[number]"></technician-calendar-cell>
                </div>
                <div class="calendarrow">
                  <technician-calendar-cell (onLstTechnicians)="populateTechniciansList($event)" (onDaySelected)="selectDay($event)" *ngFor="let number of [35,36,37,38,39,40,41]" [dayObj]="days[number]"></technician-calendar-cell>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="calendarrightselectionpanel" [formGroup]="form" *ngIf="selectedDay">
        <h3>
          <div>Technicians</div>
          <div class="selecteddaycol">{{selectedDay.day | customdate: 'DD.MM.YYYY'}}</div>
        </h3>
        <p>Select the technician for which you wish to update their availability.</p>
        <div formArrayName="techniciansList" *ngFor="let item of lstTechnicians; let i = index;">
          <technician-cell [form]="techniciansList.controls[i]" [technicianObj]="item"></technician-cell>
      </div>
        <div class="btnbox">
          <button class="btnprimary" (click)="updateAvailability()">Update availability</button>
        </div>
      </div>
    </div>
</div>