import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkReportObj } from 'src/app/shared/models/workreport';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-work-report-popup',
  templateUrl: './work-report-popup.component.html',
  styleUrls: ['./work-report-popup.component.css']
})
export class WorkReportPopupComponent implements OnInit {

  @Input()
  public orderId: number = 0;

  public wrObj: WorkReportObj = null;


  constructor(public activeModal: NgbActiveModal, private dataService: DataService, private ngbModal: NgbModal) { }

  ngOnInit() {
  }
  closePopup(){
    this.activeModal.close();
  }

  public loadRecords(){
    console.log(this.orderId);
    this.dataService.getWorkReport(this.orderId).subscribe(res=>{
      console.log(res);
      this.wrObj = res;
    });
  }

  public get isWRComplete():boolean
  {
    let isComplete: boolean = false;
    if(this.wrObj.problemTxt)
    {
      isComplete = true;
    }

    return isComplete;
  }

}
