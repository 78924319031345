<div class="sidemenu">
    <div class="sidemenucontent">
        <a routerLink="/home/dashboard" class="fixfirstlogo"></a>

        <div class="leftmenuitems">
            <a routerLink="/home/dashboard" routerLinkActive="selected"><div class="icobox icohome"></div><div class="icotxt">Home</div></a>
            <!-- <a routerLink="/home/requests" routerLinkActive="selected"><div class="icobox icorequest"></div><div class="icotxt">Requests</div></a> -->
            <a routerLink="/home/orders" routerLinkActive="selected"><div class="icobox icoorder"></div><div class="icotxt">Orders</div></a>
            <a routerLink="/home/customers" routerLinkActive="selected"><div class="icobox icocustomer"></div><div class="icotxt">Customers</div></a>
            <!-- <a routerLink="/home/partners" routerLinkActive="selected"><div class="icobox icopartner"></div><div class="icotxt">Partners</div></a> -->
            <a routerLink="/home/technicians" *ngIf="isSPLogin" routerLinkActive="selected"><div class="icobox icotechnician"></div><div class="icotxt">Technicians</div></a>
            <a routerLink="/home/service-providers" *ngIf="isCCLogin" routerLinkActive="selected"><div class="icobox icopartner"></div><div class="icotxt">Service Providers</div></a>
        </div>
    </div>
</div>