export class CustomerListingArg
{
    public role: string;
    public userId: number;
    public searchTerm: string;
}

export class CustomerListingObj{
    public customerId: number;
    public customerFirstName: string;
    public customerLastName: string;
    public applianceType: string;
    public visitAddressPostalCode: string;
    public visitAddressCity: string;
    public mobileNumber: string;
    public orderStatus: string;
    public spFirstName: string;
    public spLastName: string;
    public spOrganization: string;
    public scheduleDate: any;
    public scheduleTime: string;
}
export class CustomerObj{
    public customerId: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
    public languages: string;
    public notes: string;
    public visitAddressHouseNo: string;
    public visitAddressStreetNo: string;
    public visitAddressAdditionInfo: string;
    public visitAddressNotes: string;
    public visitAddressCity: string;
    public visitAddressPostalCode: string;
    public visitAddressCountry: string;
    public visitAddressIsElevator: boolean;
    public isBillingAddressSame: boolean;
    public billingAddressHouseNo: string;
    public billingAddressStreetNo: string;
    public billingAddressAdditionInfo: string;
    public billingAddressNotes: string;
    public billingAddressCity: string;
    public billingAddressPostalCode: string;
    public billingAddressCountry: string;

    public createdDate: any;
}

export class CustomerPartialUpdateObj{
    public customerId: number;
    public stepType: string;
    public contactDetailObj: CustomerContactDetailObj;
    public addressDetailObj: CustomerAddressDetailObj;
}
export class CustomerContactDetailObj{
    public firstName: string;
    public lastName: string;
    public email: string;
    public mobileNumberExtension: string;
    public mobileNumber: string;
    public whatsAppContactAllowed: boolean;
    public languages: string;
    public notes: string;
}
export class CustomerAddressDetailObj{
    public visitAddressHouseNo: string;
    public visitAddressStreetNo: string;
    public visitAddressAdditionInfo: string;
    public visitAddressNotes: string;
    public visitAddressCity: string;
    public visitAddressPostalCode: string;
    public visitAddressCountry: string;
    public visitAddressIsElevator: boolean;
    public isBillingAddressSame: boolean;
    public billingAddressHouseNo: string;
    public billingAddressStreetNo: string;
    public billingAddressAdditionInfo: string;
    public billingAddressNotes: string;
    public billingAddressCity: string;
    public billingAddressPostalCode: string;
    public billingAddressCountry: string;
}